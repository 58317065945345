import React, { PureComponent } from 'react'
import { Image, View } from 'react-native-web'
import moment from 'moment-timezone'

import { iOSColors } from '@shared/react-native-typography'

import * as c from '@shared/constants'
import * as chelpers from '@shared/cleaning-helpers'
import { occupancyToHuman } from '@shared/get-booking-display'

//TODO - Rewrite to functional TSX component
export default class ActivityRow extends PureComponent {
    constructor(props) {
        super(props)
    }

    render() {
        const activity = this.props.activity

        const timeStyle = {
            fontSize: 12,
            color: iOSColors.midGray
        }

        const DEBUG_BORDER = 0
        const SPACE_VERTICAL = 12
        const createdDay = moment(activity.created).format(c.DATE_FORMAT_WITH_WEEK_DAY)
        const createdTime = moment(activity.created).format('HH:mm')

        return (
            <View
                key={activity.key}
                style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginBottom: SPACE_VERTICAL,
                    borderWidth: DEBUG_BORDER,
                    borderColor: 'red'
                }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <span style={timeStyle}>{createdTime}, </span>
                    <span style={timeStyle}>{createdDay}</span>
                </View>

                {this.renderDescription(activity)}
            </View>
        )
    }

    renderDescription(activity) {
        const DESC_FONT_STYLE = {
            fontSize: 13,
            color: iOSColors.gray
        }
        const IMG_OPACITY = 0.95
        const MARGIN_HORIZONTAL = 4
        const DESC_FONT_STYLE_WITH_RIGHT_MARGIN = { marginRight: MARGIN_HORIZONTAL, ...DESC_FONT_STYLE }

        if (activity.type === 'occupancy') {
            return this.renderOccupancy(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY)
        }
        if (activity.type === 'cleaning-status') {
            return this.renderCleaningStatus(
                activity,
                DESC_FONT_STYLE,
                DESC_FONT_STYLE_WITH_RIGHT_MARGIN,
                MARGIN_HORIZONTAL,
                IMG_OPACITY,
                'changed cleaning status,'
            )
        }

        if (activity.type === 'cleaning-priority') {
            return this.renderPriority(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY)
        }
        if (activity.type === 'daily-comment') {
            return this.renderDailyComment(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY)
        }
        if (activity.type === 'area-note') {
            return this.renderAreaNote(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY)
        }

        if (activity.type === 'assignment') {
            return this.renderAssignment(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY)
        }

        if (activity.type === 'delete-due-date') {
            return this.renderDueDateRemove(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY)
        }

        if (activity.type === 'cleaning-action-start') {
            return this.renderCleaningStatus(
                activity,
                DESC_FONT_STYLE,
                DESC_FONT_STYLE_WITH_RIGHT_MARGIN,
                MARGIN_HORIZONTAL,
                IMG_OPACITY,
                'started housekeeping,'
            )
        }
        if (activity.type === 'cleaning-action-stop') {
            return this.renderCleaningStatus(
                activity,
                DESC_FONT_STYLE,
                DESC_FONT_STYLE_WITH_RIGHT_MARGIN,
                MARGIN_HORIZONTAL,
                IMG_OPACITY,
                'stopped housekeeping,'
            )
        }
        if (activity.type === 'cleaning-action-pause') {
            return this.renderCleaningStatus(
                activity,
                DESC_FONT_STYLE,
                DESC_FONT_STYLE_WITH_RIGHT_MARGIN,
                MARGIN_HORIZONTAL,
                IMG_OPACITY,
                'paused housekeeping,'
            )
        }

        if (activity.type === 'cleaning-action-stop-inspection') {
            return this.renderCleaningStatus(
                activity,
                DESC_FONT_STYLE,
                DESC_FONT_STYLE_WITH_RIGHT_MARGIN,
                MARGIN_HORIZONTAL,
                IMG_OPACITY,
                'finished inspection,'
            )
        }

        if (activity.type === 'guest-checked-in' || activity.type === 'guest-checked-out') {
            return this.renderGuestStatus(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY)
        }

        if (activity.type === 'cleaning-schedule') {
            return this.renderOptionalCleaningChange(
                activity,
                DESC_FONT_STYLE,
                DESC_FONT_STYLE_WITH_RIGHT_MARGIN,
                MARGIN_HORIZONTAL,
                IMG_OPACITY
            )
        }
    }

    renderGuestStatus(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY) {
        const ICON_DIM = activity.type === 'guest-checked-out' ? 15 : 18
        const imgCheckout = chelpers.pickOccupancyButtonImage(c.OCCUPANCY_CHECKOUT)
        const imgCheckIn = chelpers.pickOccupancyButtonImage(c.OCCUPANCY_CHECKIN)
        const img = activity.type === 'guest-checked-out' ? imgCheckout : imgCheckIn
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', borderWidth: 0, alignItems: 'center' }}>
                <span className="d-inline-block">
                    <Image
                        source={img}
                        style={{
                            opacity: IMG_OPACITY,
                            marginHorizontal: MARGIN_HORIZONTAL,
                            width: ICON_DIM,
                            height: ICON_DIM
                        }}
                    />
                </span>
                <span style={DESC_FONT_STYLE}>{'Guest'}</span>
                {activity.type === 'guest-checked-out' && (
                    <span style={{ marginLeft: '4px', ...DESC_FONT_STYLE_WITH_RIGHT_MARGIN }}>{'checked out of room'}</span>
                )}
                {activity.type === 'guest-checked-in' && (
                    <span style={{ marginLeft: '4px', ...DESC_FONT_STYLE_WITH_RIGHT_MARGIN }}>{'checked into room'}</span>
                )}
            </View>
        )
    }
    renderAssignment(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY) {
        const beforeText = activity.change.before
        const afterText = activity.change.after

        const ICON_DIM = 22
        const ICON_MARGIN_BOTTOM = -6
        const img = require('@shared/img/assigned-to-yellow.svg')
        return (
            <span className="dflex row row-paddingless">
                <span style={DESC_FONT_STYLE}>{activity.user.name}</span>
                <span className="d-inline-block">
                    <Image
                        source={img}
                        style={{
                            opacity: IMG_OPACITY,
                            marginBottom: ICON_MARGIN_BOTTOM,
                            marginHorizontal: MARGIN_HORIZONTAL,
                            width: ICON_DIM,
                            height: ICON_DIM
                        }}
                    />
                </span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>{beforeText}</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>user(s)</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>{afterText}</span>
            </span>
        )
    }
    renderDueDateRemove(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY) {
        const beforeText = activity.change.before
        const afterText = activity.change.after

        const ICON_DIM = 22
        const ICON_MARGIN_BOTTOM = -6
        const img = require('@shared/img/assigned-to-yellow.svg')
        return (
            <span className="dflex row row-paddingless">
                <span style={DESC_FONT_STYLE}>{activity.user.name}</span>
                <span className="d-inline-block">
                    <Image
                        source={img}
                        style={{
                            opacity: IMG_OPACITY,
                            marginBottom: ICON_MARGIN_BOTTOM,
                            marginHorizontal: MARGIN_HORIZONTAL,
                            width: ICON_DIM,
                            height: ICON_DIM
                        }}
                    />
                </span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>{beforeText}</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>user(s)</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>{afterText}</span>
            </span>
        )
    }

    renderDailyComment(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY) {
        const beforeText = activity.change.before
        const afterText = activity.change.after
        const img = require('@shared/img/cleaning-status/daily-comment-gray.svg')
        const ICON_DIM = 18
        const ICON_MARGIN_BOTTOM = -3
        return (
            <span>
                <span style={DESC_FONT_STYLE}>
                    {activity.user.name}
                    <span className="d-inline-block">
                        <Image
                            source={img}
                            style={{
                                opacity: IMG_OPACITY,
                                marginBottom: ICON_MARGIN_BOTTOM,
                                marginHorizontal: MARGIN_HORIZONTAL,
                                width: ICON_DIM,
                                height: ICON_DIM
                            }}
                        />
                    </span>
                </span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>changed daily comment from</span>

                <span style={{ fontStyle: 'italic', ...DESC_FONT_STYLE_WITH_RIGHT_MARGIN }}>&quot;{beforeText}&quot;</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>to</span>

                <span style={{ fontStyle: 'italic', ...DESC_FONT_STYLE_WITH_RIGHT_MARGIN }}>&quot;{afterText}&quot;</span>
            </span>
        )
    }

    renderAreaNote(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY) {
        const beforeText = activity.change.before
        const afterText = activity.change.after
        const img = c.IMG_AREA_NOTE_BUTTON
        const ICON_DIM = 20
        const ICON_MARGIN_BOTTOM = -4
        return (
            <div>
                <span style={DESC_FONT_STYLE}>{activity.user.name}</span>
                <span className="d-inline-block">
                    <Image
                        source={img}
                        style={{
                            opacity: IMG_OPACITY,
                            marginBottom: ICON_MARGIN_BOTTOM,
                            marginHorizontal: MARGIN_HORIZONTAL,
                            width: ICON_DIM,
                            height: ICON_DIM
                        }}
                    />
                </span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>changed unit note from</span>

                <span style={{ fontStyle: 'italic', ...DESC_FONT_STYLE_WITH_RIGHT_MARGIN }}>&quot;{beforeText}&quot;</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>to</span>

                <span style={{ fontStyle: 'italic', ...DESC_FONT_STYLE_WITH_RIGHT_MARGIN }}>&quot;{afterText}&quot;</span>
            </div>
        )
    }

    pickPriorityImage(priority) {
        if (priority === true) {
            return require('@shared/img/priority-high-red-bgr.svg')
        } else {
            return require('@shared/img/priority-low.svg')
        }
    }

    pickPriorityText(priority) {
        if (priority === true) {
            return 'high'
        } else {
            return 'normal'
        }
    }

    renderPriority(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY) {
        const beforeImg = this.pickPriorityImage(activity.change.before)
        const beforeText = this.pickPriorityText(activity.change.before)

        const afterImg = this.pickPriorityImage(activity.change.after)
        const afterText = this.pickPriorityText(activity.change.after)

        const ICON_DIM = 17
        const ICON_MARGIN_BOTTOM = -3
        return (
            <span>
                <span style={DESC_FONT_STYLE}>{activity.user.name}</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}> changed priority from</span>
                <span className="d-inline-block">
                    <Image
                        source={beforeImg}
                        style={{
                            opacity: IMG_OPACITY,
                            marginBottom: ICON_MARGIN_BOTTOM,
                            marginRight: MARGIN_HORIZONTAL,
                            width: ICON_DIM,
                            height: ICON_DIM
                        }}
                    />
                </span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>{beforeText}</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>to</span>
                <span className="d-inline-block">
                    <Image
                        source={afterImg}
                        style={{
                            opacity: IMG_OPACITY,
                            marginBottom: ICON_MARGIN_BOTTOM,
                            marginRight: MARGIN_HORIZONTAL,
                            width: ICON_DIM,
                            height: ICON_DIM
                        }}
                    />
                </span>
                <span style={DESC_FONT_STYLE}>{afterText}</span>
            </span>
        )
    }

    renderCleaningStatus(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY, actionText) {
        const beforeColor = chelpers.pickCleaningStatusColor(activity.change.before)
        const afterColor = chelpers.pickCleaningStatusColor(activity.change.after)
        const ICON_DIM = 11
        const ICON_MARGIN_TOP = 6
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', borderWidth: 0 }}>
                <span style={DESC_FONT_STYLE}>{activity.user.name}</span>
                <span style={DESC_FONT_STYLE}>&nbsp;{actionText}</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}> and status changed from</span>

                <View
                    style={{
                        opacity: IMG_OPACITY,
                        backgroundColor: beforeColor,
                        marginTop: ICON_MARGIN_TOP,
                        marginRight: MARGIN_HORIZONTAL,
                        borderRadius: ICON_DIM / 2,
                        width: ICON_DIM,
                        height: ICON_DIM
                    }}
                />

                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>{chelpers.cleaningStatusToHuman(activity.change.before, null, false)}</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>to</span>

                <View
                    style={{
                        opacity: IMG_OPACITY,
                        backgroundColor: afterColor,
                        marginTop: ICON_MARGIN_TOP,
                        marginRight: MARGIN_HORIZONTAL,
                        borderRadius: ICON_DIM / 2,
                        width: ICON_DIM,
                        height: ICON_DIM
                    }}
                />
                <span style={DESC_FONT_STYLE}>{chelpers.cleaningStatusToHuman(activity.change.after, null, false)}</span>
            </View>
        )
    }

    renderOccupancy(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY) {
        const beforeImg = chelpers.pickOccupancyButtonImage(activity.change.before)
        const afterImg = chelpers.pickOccupancyButtonImage(activity.change.after)
        const SHOW_BEFORE_IMG = !(activity.change.before === c.OCCUPANCY_VACANT || activity.change.before === c.OCCUPANCY_TURNOVER)
        const SHOW_AFTER_IMG = !(activity.change.after === c.OCCUPANCY_VACANT || activity.change.after === c.OCCUPANCY_TURNOVER)
        const ICON_DIM = 22
        const ICON_MARGIN_BOTTOM = -6
        return (
            <span>
                <span style={DESC_FONT_STYLE}>{activity.user.name}</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}> changed occupancy from</span>
                {SHOW_BEFORE_IMG && (
                    <span className="d-inline-block">
                        <Image
                            source={beforeImg}
                            style={{
                                opacity: IMG_OPACITY,
                                marginBottom: ICON_MARGIN_BOTTOM,
                                marginRight: MARGIN_HORIZONTAL,
                                width: ICON_DIM,
                                height: ICON_DIM
                            }}
                        />
                    </span>
                )}
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>{occupancyToHuman(activity.change.before, false)}</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>to</span>

                {SHOW_AFTER_IMG && (
                    <span className="d-inline-block">
                        <Image
                            source={afterImg}
                            style={{
                                opacity: IMG_OPACITY,
                                marginBottom: ICON_MARGIN_BOTTOM,
                                marginRight: MARGIN_HORIZONTAL,
                                width: ICON_DIM,
                                height: ICON_DIM
                            }}
                        />
                    </span>
                )}
                <span style={DESC_FONT_STYLE}>{occupancyToHuman(activity.change.after, false)}</span>
            </span>
        )
    }

    renderOptionalCleaningChange(activity, DESC_FONT_STYLE, DESC_FONT_STYLE_WITH_RIGHT_MARGIN, MARGIN_HORIZONTAL, IMG_OPACITY) {
        let actionText = activity.change.before.length > activity.change.after.length ? 'removed' : 'added'
        let firstArr = actionText === 'added' ? activity.change.after : activity.change.before
        let secondArr = actionText === 'added' ? activity.change.before : activity.change.after
        if (this.props.currentOrganization && this.props.currentOrganization.allowOptOut) {
            actionText = activity.change.before.length > activity.change.after.length ? 'added' : 'removed'
            firstArr = actionText === 'added' ? activity.change.before : activity.change.after
            secondArr = actionText === 'added' ? activity.change.after : activity.change.before
        }
        let difference = firstArr.filter(x => !secondArr.includes(x))
        return (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', borderWidth: 0 }}>
                <span style={DESC_FONT_STYLE}>{activity.user.name}</span>
                <span style={DESC_FONT_STYLE}>&nbsp;{actionText}</span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}>
                    {' '}
                    &nbsp;
                    <strong>
                        {difference.length > 14
                            ? `${difference.length} days`
                            : difference.map((date, i) => {
                                  return (
                                      <span key={i}>
                                          {i > 0 && <span>, </span>}
                                          <span>{moment(parseInt(date)).format('ddd DD')}</span>
                                      </span>
                                  )
                              })}
                    </strong>{' '}
                </span>
                <span style={DESC_FONT_STYLE_WITH_RIGHT_MARGIN}> {actionText === 'added' ? 'to' : 'from'} cleaning schedule</span>
            </View>
        )
    }
}

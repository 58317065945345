import React, { useEffect } from 'react'
import LoadingScreen from '../../LoadingScreen'
import SignUpLayout from '../../SignUpLayout'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { guestlineSetup } from '../../cloud-functions'
import { compose } from 'redux'
import withIntegrationWorkflow from '../../../../utils/hocs/withIntegrationWorkflow'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import { errorModalConfigs } from '../../helpers'
import { INTEGRATIONS } from '../../../../utils/constants'
import { getApi } from '../../frontend-api'
import firebase, { asFirebase } from '../../../../utils/firebase'
import { useFeatureToggle } from 'app/features'

const GuestlineCallback = ({
    newOrgKey,
    catchError,
    signupComplete,
    workflowInstance,
    storeWorkflowInstance,
    setComplete,
    currentIntegrationKey,
    newUserKey,
    settings,
    message,
    setStopAuthListener
}) => {
    const { isFeatureOn } = useFeatureToggle()
    const useNewInitialImport = isFeatureOn('new-initial-import-procedure')

    const setupGuestlineOld = async () => {
        console.info('Initial Guestline PMS Sync: Using old method')
        const siteId = workflowInstance.getInput('siteId')
        const operatorCode = workflowInstance.getInput('operatorCode')
        const password = workflowInstance.getInput('password')
        const { value: inspectionCode } = workflowInstance.getInput('inspectionCode')
        const { value: dirtyCode } = workflowInstance.getInput('dirtyCode')
        const setupGuestlineIntegration = guestlineSetup()

        console.log(siteId, operatorCode, password, inspectionCode, dirtyCode, 'CREDS')

        try {
            const { data } = await setupGuestlineIntegration({
                orgId: newOrgKey,
                integration: INTEGRATIONS.GUESTLINE.toLowerCase(),
                signupPayload: { organizationID: newOrgKey, siteId, operatorCode, password, inspectionCode, dirtyCode }
            })

            if (data.reason !== 'all good') {
                catchError(data.message, errorModalConfigs)
                return
            }

            console.log(workflowInstance)
            if ('test' in workflowInstance.values) {
                data.reason = 'test'
            }

            await signupComplete({ orgId: newOrgKey, userId: newUserKey, status: data })

            workflowInstance.completeStep(SIGN_UP.GUESTLINE_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupGuestlineNew = async () => {
        console.info('Initial Guestline PMS Sync: Using new server-side method')
        try {
            const api = await getApi(asFirebase(firebase))
            const siteId = workflowInstance.getInput('siteId')
            const operatorCode = workflowInstance.getInput('operatorCode')
            const password = workflowInstance.getInput('password')
            const { value: inspectionCode } = workflowInstance.getInput('inspectionCode')
            const { value: dirtyCode } = workflowInstance.getInput('dirtyCode')

            await api['setup-org'].$post({
                json: {
                    newAdminUserKey: newUserKey,
                    orgId: newOrgKey,
                    integration: INTEGRATIONS.GUESTLINE.toLowerCase(),
                    isTest: 'test' in workflowInstance.values,
                    signupPayload: {
                        organizationID: newOrgKey,
                        siteId,
                        operatorCode,
                        password,
                        inspectionCode,
                        dirtyCode
                    }
                }
            })

            workflowInstance.completeStep(SIGN_UP.GUESTLINE_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)
            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupGuestline = async () => await (useNewInitialImport ? setupGuestlineNew() : setupGuestlineOld())

    async function setup() {
        setStopAuthListener(true)
        await setupGuestline()
    }

    useEffect(() => {
        setup()
    }, [])

    return (
        <SignUpLayout withModal>
            <LoadingScreen message={message} />
        </SignUpLayout>
    )
}

export default compose(withIntegrationWorkflow, withErrorCatching)(GuestlineCallback)

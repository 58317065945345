import { ConfirmationResult } from '../../../app/utils/firebase'
import { atom } from 'recoil'

export interface SignupData {
    orgName: string
    ownerName: string
    ownerEmail: string
    phoneNumber: string
}

export const signUpDataAtom = atom<SignupData | null>({
    key: 'signUpDataAtom',
    default: null
})

export const confirmationAtom = atom<ConfirmationResult | null>({
    key: 'confirmationAtom',
    default: null
})

export const formIsOpenAtom = atom<boolean>({
    key: 'formIsOpenAtom',
    default: false
})

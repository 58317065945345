import React, { useEffect, useState } from 'react'
import { ModalContainer, ModalOverlay } from '../../../../components/styled-components/modal'
import LoginLayout from '../../../../components/layouts/LoginLayout'
import { containerPadding, containerWidth } from './style'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import GuestLoginForm from './loginFrom'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import { GuestLoginWithLastNameAndRoomNamePayload } from '@shared/guest'
import { guestLoginWithLastNameAndRoomNameRequest } from '../../../../api'
import { toast } from 'react-toastify'
import { toastErrorStyle } from '../../../../utils/styles'

export const GuestLoginWIthLastNameAndRoomName = () => {
    const history = useHistory()
    const searchParams = new URLSearchParams(document.location.search)

    const [formStateData, setFormStateData] = useState<GuestLoginWithLastNameAndRoomNamePayload>()

    const getOrgKeyFromUrl = (url: string | URL) => {
        try {
            const urlObj = new URL(url)
            const pathname = urlObj.pathname
            const parts = pathname.split('/')
            if (parts.length >= 4 && parts[1] === 'guest' && parts[3] === 'login') {
                return parts[2]
            }
        } catch (error) {
            console.error('Invalid URL', error)
        }
        return ''
    }

    const onLogin = async (values: GuestLoginWithLastNameAndRoomNamePayload) => {
        try {
            const orgKey = getOrgKeyFromUrl(window.location.href)

            const response = await guestLoginWithLastNameAndRoomNameRequest({ ...values, orgKey })

            if (response.status === 200) {
                history.push('/guest/data')
            }
        } catch (error: any) {
            if (error.response.data.message) {
                toast(error.response.data.message, toastErrorStyle)
            } else {
                toast(error.response.data, toastErrorStyle)
            }
        }
    }

    useEffect(() => {
        const lastName = searchParams.get('lastName')
        const roomName = searchParams.get('roomName')
        const checkInDate = searchParams.get('checkInDate')

        setFormStateData({
            lastName: lastName ? lastName : '',
            roomName: roomName ? roomName : '',
            checkInDate: checkInDate ? checkInDate : moment().format('YYYY-MM-DD')
        })
    }, [])

    if (!formStateData) {
        return null
    }

    return (
        <ModalOverlay>
            <ModalContainer width={containerWidth} height={'718px'} padding={containerPadding} overflowY="none">
                <LoginLayout>
                    <GuestLoginForm onLogin={onLogin} stateData={formStateData} />
                </LoginLayout>
            </ModalContainer>
            <div id="recaptcha-container" />
        </ModalOverlay>
    )
}

export default withErrorCatching(GuestLoginWIthLastNameAndRoomName)

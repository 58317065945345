export const nullArea = {
    updates: { spectaflow: 0 },
    key: 'null-area',
    updated: 0,
    creator: 'spectaflow',
    description: 'Used when no area is selected',
    name: 'The null area'
}

export const planningInfo = [
    { name: 'Ready', colorClass: 'sweeply-green' },
    { name: 'Clean', colorClass: 'sweeply-rose' },
    { name: 'Skip', colorClass: 'sweeply-teal' },
    { name: 'Check out', colorClass: 'sweeply-purple' }
]

export const CAMERA_TAB = 'camera_tab'
export const TASKS_TAB = 'tasks_tab'
export const ISSUES_TAB = 'issues_tab'
export const HOUSEKEEPING_TAB = 'housekeeping_tab'

export const ISSUE_MY = 'my'
export const ISSUE_ANYSTATUS = 'any'
export const ISSUE_OPEN = 'open'
export const ISSUE_ASSIGNED = 'assigned'
export const ISSUE_COMPLETE = 'completed'
export const ISSUE_DELETED = 'deleted'

export const TEXT_ISSUE = 'text-issue'

export const ISSUE_ALLTYPES = 'all'
export const ISSUE_MAINTENANCE = 'maintenance'
export const ISSUE_PURCHASE = 'purchase'
export const ISSUE_LOST_FOUND = 'lost-found'
export const ISSUE_DAMAGE = 'damage'
export const ISSUE_INSPECTION = 'inspection'
export const ISSUE_PROJECT = 'project'

export const CLEANING_STATUS_DIRTY = 'dirty'
export const CLEANING_STATUS_PREPARING = 'preparing'
export const CLEANING_STATUS_PREPARING_PAUSE = 'preparing-pause'
export const CLEANING_STATUS_INSPECTION = 'inspection'
export const CLEANING_STATUS_CLEAN = 'clean'
export const CLEANING_STATUS_DO_NOT_DISTURB = 'do-not-disturb'
export const CLEANING_STATUS_NO_SERVICE = 'no-cleaning-service'
export const CLEANING_STATUS_OUT_OF_SERVICE = 'no-service'
export const CLEANING_STATUS_WAITING_FOR_CHECKOUT = 'waiting-for-checkout'
export const CLEANING_STATUS_ALL = 'all'
export const CLEANING_STATUS_OTHER = 'other'

export const OCCUPANCY_CHECKOUT = 'checkout'
export const OCCUPANCY_TURNOVER = 'turnover'
export const OCCUPANCY_CHECKIN = 'checkin'
export const OCCUPANCY_VACANT = 'vacant'
export const OCCUPANCY_STAYOVER = 'stayover'
export const OCCUPANCY_STAYOVER_80 = 'stayover-80'
export const OCCUPANCY_ALL = 'all'

export const CLEANING_RULES_DAILY = 'daily'
export const CLEANING_RULES_CHECKOUT = 'checkout'

export const AREA_GROUPS_ALL = 'All'

export const TASK_ANYSTATUS = 'any'
export const TASK_OPEN = 'open'
export const TASK_ASSIGNED = 'assigned'
export const TASK_COMPLETE = 'completed'
export const TASK_DELETED = 'deleted'

export const TASK_TYPE_HOUSEKEEPING = 'cleaning'
export const TASK_TYPE_ISSUE = 'issue'
export const TASK_TYPE_GENERAL = 'general'

export const TASK_HOUSEKEEPING_DEFAULT_NAME = 'Housekeeping'
export const TASK_CLEANING_DEFAULT_NAME = 'Clean & prepare'

export const ORGANIZATION_STATUS_ACTIVE = 'active'
export const ORGANIZATION_STATUS_READY = 'ready'
export const ORGANIZATION_STATUS_SUSPENDED = 'suspended'
export const ORGANIZATION_STATUS_TRIAL = 'trial'

export const CONTEXT_APP_TASKS = 'context-app-tasks'
export const CONTEXT_APP_CLEANING = 'context-app-cleaning'
export const CONTEXT_WEB_APP_TASKS = 'context-web-app-tasks'
export const CONTEXT_WEB_APP_CLEANING = 'context-web-app-cleaning'

export const DATE_FORMAT_BASIC = 'MMM D, YYYY'
export const DATE_FORMAT_WITH_WEEK_DAY = 'ddd / MMM D / YYYY'
export const DATE_FORMAT_NO_YEAR = 'ddd, MMM D'

export const ASSIGN_MODE_FILTER_ALL = 'all'
export const ASSIGN_MODE_FILTER_UN_ASSIGNED = 'un-assigned'
export const ASSIGN_MODE_FILTER_ASSIGNED = 'assigned'

export const ICON_TIDY_ROOM = { width: 16, height: 16 }
export const ICON_PREPARE_ROOM = { width: 16, height: 13.7 }
export const ICON_CHECKOUT_CLEANING = { width: 16, height: 14.3 }
export const ICON_PEOPLE = { width: 16, height: 12.8 }
export const ICON_DND = { width: 16, height: 16.6 }
export const ICON_CHECKIN = { width: 16, height: 16 }
export const ICON_OUT_OF_SERVICE = { width: 16, height: 16 }
export const ICON_NO_SERVICE = { width: 18, height: 18 }
export const ICON_INSPECTION = { width: 13.15, height: 18 }

export const CLEANING_ACTION_STOP = 'stop'
export const CLEANING_ACTION_PLAY = 'play'
export const CLEANING_ACTION_PAUSE = 'pause'
export const CLEANING_ACTION_STOP_INSPECTION = 'stop-inspection'

export const HASHTAG_ALL = 'All'
export const HASHTAG_WITHOUT_HASHTAGS = 'Without hashtags'
export const HASHTAGS_DEFAULT = ['#housekeeping🧹', '#improvement⭐️', '#lost&found😢', '#maintenance🛠', '#minibar🍷']

export const ACTIVITY_TYPE_OCCUPANCY = 'occupancy'
export const ACTIVITY_TYPE_CLEANING_STATUS = 'cleaning-status'
export const ACTIVITY_TYPE_CLEANING_PRIORITY = 'cleaning-priority'
export const ACTIVITY_TYPE_DAILY_COMMENT = 'daily-comment'
export const ACTIVITY_TYPE_AREA_NOTE = 'area-note'
export const ACTIVITY_TYPE_ASSIGNMENT = 'assignment'
export const ACTIVITY_TYPE_CLEANING_ACTION_START = 'cleaning-action-start'
export const ACTIVITY_TYPE_CLEANING_ACTION_STOP = 'cleaning-action-stop'
export const ACTIVITY_TYPE_CLEANING_ACTION_PAUSE = 'cleaning-action-pause'
export const ACTIVITY_TYPE_CLEANING_ACTION_STOP_INSPECTION = 'cleaning-action-stop-inspection'
export const ACTIVITY_TYPE_GUEST_CHECKED_IN = 'guest-checked-in'
export const ACTIVITY_TYPE_GUEST_CHECKED_OUT = 'guest-checked-out'
export const ACTIVITY_TYPE_CLEANING_SCHEDULE = 'cleaning-schedule'
export const ACTIVITY_TYPE_DELETE_DUE_DATE = 'delete-due-date'

export const BOOKING_STATUS_CANCELLED = 'Cancelled'
export const BOOKING_STATUS_BOOKED = 'Booked'
export const BOOKING_STATUS_REQUEST = 'Request'
export const BOOKING_STATUS_BLOCKED = 'Blocked'

export const BOOKING_NOTE = 'booking-note'
export const NON_EXIST = 'non-exist'

export const SWEEPLY_TASKBOARD_SEARCH_PARAMS = '__SWEEPLY_TASKBOARD_SEARCH_PARAMS__'

export const DEFAULT_DIAL_CODE = '+354'

export const IMG_STATUS_ANY = require('./img/cleaning-status/all-button.svg')
export const IMG_STATUS_OPEN = require('./img/status-open.svg')
export const IMG_STATUS_ASSIGNED = require('./img/assigned-to-yellow.svg')
export const IMG_STATUS_COMPLETE = require('./img/status-complete-inverse.svg')
export const IMG_STATUS_DELETED = require('./img/status-deleted.svg')

export const IMG_BRAND_SWEEPLY_LOGO = require('./img/brand/sweeply/logo.svg')
export const IMG_BRAND_SWEEPLY_NAVBAR_LOGO = require('./img/brand/sweeply/icon.svg')
export const IMG_BRAND_SWEEPLY_OPEN_NAVBAR_LOGO = require('./img/brand/sweeply/logo-long.svg')

export const IMG_BRAND_SPECTAFLOW_LOGO = require('./img/brand/spectaflow/logo.svg')
export const IMG_BRAND_SPECTAFLOW_NAVBAR_ICON = require('./img/brand/spectaflow/icon.svg')

export const IMG_BRAND_SPECTATEST_LOGO = require('./img/brand/spectatest/logo.svg')
export const IMG_BRAND_SPECTATEST_NAVBAR_ICON = require('./img/brand/spectatest/icon.svg')

export const IMG_CLEANING_STATUS_DO_NOT_DISTURB_WHITE = require('./img/cleaning-status/cv-do-not-disturb-white.svg')
export const IMG_CLEANING_STATUS_DO_NOT_DISTURB_BLUE = require('./img/cleaning-status/cv-do-not-disturb-blue.svg')
export const IMG_CLEANING_TASK_DO_NOT_DISTURB = require('./img/cleaning-status/ct-dnd.svg')
export const IMG_CLEANING_TASK_DO_NOT_DISTURB_WHITE = require('./img/cleaning-status/ct-dnd-white.svg')

export const IMG_CLEANING_TASK_OUT_OF_SERVICE = require('./img/cleaning-status/ct-out-of-service.svg')
export const IMG_CLEANING_TASK_OUT_OF_SERVICE_WHITE = require('./img/cleaning-status/ct-out-of-service-white.svg')
export const IMG_CLEANING_STATUS_OUT_OF_SERVICE_WHITE = require('./img/cleaning-status/cv-out-of-service-white.svg')
export const IMG_CLEANING_STATUS_OUT_OF_SERVICE_GRAY = require('./img/cleaning-status/cv-out-of-service-gray.svg')

export const IMG_CLEANING_STATUS_NO_SERVICE_WHITE = require('./img/cleaning-status/ct-no-service-white.svg')
export const IMG_CLEANING_STATUS_NO_SERVICE = require('./img/cleaning-status/ct-no-service.svg')

export const IMG_CLEANING_TASK_TIDY_ROOM_RED = require('./img/cleaning-status/ct-tidy-room-red.svg')
export const IMG_CLEANING_TASK_TIDY_ROOM_YELLOW = require('./img/cleaning-status/ct-tidy-room-yellow.svg')
export const IMG_CLEANING_TASK_TIDY_ROOM_GREEN = require('./img/cleaning-status/ct-tidy-room-green.svg')
export const IMG_CLEANING_TASK_TIDY_ROOM_PURPLE = require('./img/cleaning-status/ct-tidy-room-purple.svg')
export const IMG_CLEANING_TASK_TIDY_ROOM_WHITE = require('./img/cleaning-status/ct-tidy-room-white.svg')
export const IMG_CLEANING_TASK_TIDY_ROOM_BLUE = require('./img/cleaning-status/ct-tidy-room-blue.svg')
export const IMG_CLEANING_TASK_TIDY_ROOM_GRAY = require('./img/cleaning-status/ct-tidy-room-gray.svg')
export const IMG_CLEANING_TASK_TIDY_ROOM_BLACK = require('./img/cleaning-status/ct-tidy-room-black.svg')
export const IMG_CLEANING_TASK_TIDY_ROOM_CURRY = require('./img/cleaning-status/ct-tidy-room-curry.svg')
export const IMG_CLEANING_TASK_TIDY_ROOM_ROSE = require('./img/cleaning-status/ct-tidy-room-rose.svg')

export const IMG_CLEANING_TASK_PREPARE_ROOM_RED = require('./img/cleaning-status/ct-prepare-room-red.svg')
export const IMG_CLEANING_TASK_PREPARE_ROOM_YELLOW = require('./img/cleaning-status/ct-prepare-room-yellow.svg')
export const IMG_CLEANING_TASK_PREPARE_ROOM_GREEN = require('./img/cleaning-status/ct-prepare-room-green.svg')
export const IMG_CLEANING_TASK_PREPARE_ROOM_PURPLE = require('./img/cleaning-status/ct-prepare-room-purple.svg')
export const IMG_CLEANING_TASK_PREPARE_ROOM_WHITE = require('./img/cleaning-status/ct-prepare-room-white.svg')
export const IMG_CLEANING_TASK_PREPARE_ROOM_BLUE = require('./img/cleaning-status/ct-prepare-room-blue.svg')
export const IMG_CLEANING_TASK_PREPARE_ROOM_GRAY = require('./img/cleaning-status/ct-prepare-room-gray.svg')
export const IMG_CLEANING_TASK_PREPARE_ROOM_BLACK = require('./img/cleaning-status/ct-prepare-room-black.svg')
export const IMG_CLEANING_TASK_PREPARE_ROOM_CURRY = require('./img/cleaning-status/ct-prepare-room-curry.svg')
export const IMG_CLEANING_TASK_PREPARE_ROOM_ROSE = require('./img/cleaning-status/ct-prepare-room-rose.svg')

export const IMG_CLEANING_TASK_CHECKOUT_CLEANING_RED = require('./img/cleaning-status/checkout-cleaning-red.svg')
export const IMG_CLEANING_TASK_CHECKOUT_CLEANING_YELLOW = require('./img/cleaning-status/checkout-cleaning-yellow.svg')
export const IMG_CLEANING_TASK_CHECKOUT_CLEANING_GREEN = require('./img/cleaning-status/checkout-cleaning-green.svg')
export const IMG_CLEANING_TASK_CHECKOUT_CLEANING_PURPLE = require('./img/cleaning-status/checkout-cleaning-purple.svg')
export const IMG_CLEANING_TASK_CHECKOUT_CLEANING_WHITE = require('./img/cleaning-status/checkout-cleaning-white.svg')
export const IMG_CLEANING_TASK_CHECKOUT_CLEANING_BLUE = require('./img/cleaning-status/checkout-cleaning-blue.svg')
export const IMG_CLEANING_TASK_CHECKOUT_CLEANING_GRAY = require('./img/cleaning-status/checkout-cleaning-gray.svg')
export const IMG_CLEANING_TASK_CHECKOUT_CLEANING_BLACK = require('./img/cleaning-status/checkout-cleaning-black.svg')
export const IMG_CLEANING_TASK_CHECKOUT_CLEANING_CURRY = require('./img/cleaning-status/checkout-cleaning-curry.svg')
export const IMG_CLEANING_TASK_CHECKOUT_CLEANING_ROSE = require('./img/cleaning-status/checkout-cleaning-rose.svg')

export const IMG_CLEANING_TASK_CHECK_IN_RED = require('./img/cleaning-status/ct-check-in-red.svg')
export const IMG_CLEANING_TASK_CHECK_IN_YELLOW = require('./img/cleaning-status/ct-check-in-yellow.svg')
export const IMG_CLEANING_TASK_CHECK_IN_GREEN = require('./img/cleaning-status/ct-check-in-green.svg')
export const IMG_CLEANING_TASK_CHECK_IN_PURPLE = require('./img/cleaning-status/ct-check-in-purple.svg')
export const IMG_CLEANING_TASK_CHECK_IN_WHITE = require('./img/cleaning-status/ct-check-in-white.svg')
export const IMG_CLEANING_TASK_CHECK_IN_BLUE = require('./img/cleaning-status/ct-check-in-blue.svg')
export const IMG_CLEANING_TASK_CHECK_IN_GRAY = require('./img/cleaning-status/ct-check-in-gray.svg')
export const IMG_CLEANING_TASK_CHECK_IN_BLACK = require('./img/cleaning-status/ct-check-in-black.svg')
export const IMG_CLEANING_TASK_CHECK_IN_CURRY = require('./img/cleaning-status/ct-check-in-curry.svg')
export const IMG_CLEANING_TASK_CHECK_IN_ROSE = require('./img/cleaning-status/ct-check-in-rose.svg')

export const IMG_CLEANING_TASK_PEOPLE_RED = require('./img/cleaning-status/ct-people-red.svg')
export const IMG_CLEANING_TASK_PEOPLE_YELLOW = require('./img/cleaning-status/ct-people-yellow.svg')
export const IMG_CLEANING_TASK_PEOPLE_GREEN = require('./img/cleaning-status/ct-people-green.svg')
export const IMG_CLEANING_TASK_PEOPLE_PURPLE = require('./img/cleaning-status/ct-people-purple.svg')
export const IMG_CLEANING_TASK_PEOPLE_WHITE = require('./img/cleaning-status/ct-people-white.svg')
export const IMG_CLEANING_TASK_PEOPLE_BLUE = require('./img/cleaning-status/ct-people-blue.svg')
export const IMG_CLEANING_TASK_PEOPLE_GRAY = require('./img/cleaning-status/ct-people-gray.svg')
export const IMG_CLEANING_TASK_PEOPLE_BLACK = require('./img/cleaning-status/ct-people-black.svg')
export const IMG_CLEANING_TASK_PEOPLE_CURRY = require('./img/cleaning-status/ct-people-curry.svg')
export const IMG_CLEANING_TASK_PEOPLE_ROSE = require('./img/cleaning-status/ct-people-rose.svg')

export const IMG_CLEANING_TASK_PEOPLE_TEAL = require('./img/cleaning-status/ct-people-teal.svg')

export const IMG_CLEANING_TASK_PAUSE_RED = require('./img/cleaning-status/pause-red.svg')
export const IMG_CLEANING_TASK_PAUSE_YELLOW = require('./img/cleaning-status/pause-yellow.svg')
export const IMG_CLEANING_TASK_PAUSE_GREEN = require('./img/cleaning-status/pause-green.svg')
export const IMG_CLEANING_TASK_PAUSE_PURPLE = require('./img/cleaning-status/pause-purple.svg')
export const IMG_CLEANING_TASK_PAUSE_WHITE = require('./img/cleaning-status/pause-white.svg')
export const IMG_CLEANING_TASK_PAUSE_BLUE = require('./img/cleaning-status/pause-blue.svg')
export const IMG_CLEANING_TASK_PAUSE_GRAY = require('./img/cleaning-status/pause-gray.svg')
export const IMG_CLEANING_TASK_PAUSE_BLACK = require('./img/cleaning-status/pause-black.svg')
export const IMG_CLEANING_TASK_PAUSE_CURRY = require('./img/cleaning-status/pause-curry.svg')
export const IMG_CLEANING_TASK_PAUSE_ROSE = require('./img/cleaning-status/pause-rose.svg')

export const IMG_CLEANING_TASK_INSPECTION_RED = require('./img/cleaning-status/inspection-red.svg')
export const IMG_CLEANING_TASK_INSPECTION_YELLOW = require('./img/cleaning-status/inspection-yellow.svg')
export const IMG_CLEANING_TASK_INSPECTION_GREEN = require('./img/cleaning-status/inspection-green.svg')
export const IMG_CLEANING_TASK_INSPECTION_PURPLE = require('./img/cleaning-status/inspection-purple.svg')
export const IMG_CLEANING_TASK_INSPECTION_WHITE = require('./img/cleaning-status/inspection-white.svg')
export const IMG_CLEANING_TASK_INSPECTION_BLUE = require('./img/cleaning-status/inspection-blue.svg')
export const IMG_CLEANING_TASK_INSPECTION_GRAY = require('./img/cleaning-status/inspection-gray.svg')
export const IMG_CLEANING_TASK_INSPECTION_BLACK = require('./img/cleaning-status/inspection-black.svg')
export const IMG_CLEANING_TASK_INSPECTION_CURRY = require('./img/cleaning-status/inspection-curry.svg')
export const IMG_CLEANING_TASK_INSPECTION_ROSE = require('./img/cleaning-status/inspection-rose.svg')

export const IMG_CLEANING_TASK_AREA_NOTE_RED = require('./img/cleaning-status/area-note-red.svg')
export const IMG_CLEANING_TASK_AREA_NOTE_YELLOW = require('./img/cleaning-status/area-note-yellow.svg')
export const IMG_CLEANING_TASK_AREA_NOTE_GREEN = require('./img/cleaning-status/area-note-green.svg')
export const IMG_CLEANING_TASK_AREA_NOTE_PURPLE = require('./img/cleaning-status/area-note-purple.svg')
export const IMG_CLEANING_TASK_AREA_NOTE_WHITE = require('./img/cleaning-status/area-note-white.svg')
export const IMG_CLEANING_TASK_AREA_NOTE_BLUE = require('./img/cleaning-status/area-note-blue.svg')
export const IMG_CLEANING_TASK_AREA_NOTE_GRAY = require('./img/cleaning-status/area-note-gray.svg')
export const IMG_CLEANING_TASK_AREA_NOTE_BLACK = require('./img/cleaning-status/area-note-black.svg')
export const IMG_CLEANING_TASK_AREA_NOTE_CURRY = require('./img/cleaning-status/area-note-curry.svg')
export const IMG_CLEANING_TASK_AREA_NOTE_ROSE = require('./img/cleaning-status/area-note-rose.svg')

export const IMG_CLEANING_TASK_AREA_NOTE_TEAL = require('./img/cleaning-status/area-note-teal.svg')

export const IMG_CLEANING_TASK_BOOKING_NOTE_RED = require('./img/cleaning-status/booking-note-red.svg')
export const IMG_CLEANING_TASK_BOOKING_NOTE_YELLOW = require('./img/cleaning-status/booking-note-yellow.svg')
export const IMG_CLEANING_TASK_BOOKING_NOTE_GREEN = require('./img/cleaning-status/booking-note-green.svg')
export const IMG_CLEANING_TASK_BOOKING_NOTE_PURPLE = require('./img/cleaning-status/booking-note-purple.svg')
export const IMG_CLEANING_TASK_BOOKING_NOTE_WHITE = require('./img/cleaning-status/booking-note-white.svg')
export const IMG_CLEANING_TASK_BOOKING_NOTE_BLUE = require('./img/cleaning-status/booking-note-blue.svg')
export const IMG_CLEANING_TASK_BOOKING_NOTE_GRAY = require('./img/cleaning-status/booking-note-gray.svg')
export const IMG_CLEANING_TASK_BOOKING_NOTE_BLACK = require('./img/cleaning-status/booking-note-black.svg')
export const IMG_CLEANING_TASK_BOOKING_NOTE_CURRY = require('./img/cleaning-status/booking-note-curry.svg')
export const IMG_CLEANING_TASK_BOOKING_NOTE_ROSE = require('./img/cleaning-status/booking-note-rose.svg')

export const IMG_CLEANING_TASK_DAILY_COMMENT_RED = require('./img/cleaning-status/daily-comment-red.svg')
export const IMG_CLEANING_TASK_DAILY_COMMENT_YELLOW = require('./img/cleaning-status/daily-comment-yellow.svg')
export const IMG_CLEANING_TASK_DAILY_COMMENT_GREEN = require('./img/cleaning-status/daily-comment-green.svg')
export const IMG_CLEANING_TASK_DAILY_COMMENT_PURPLE = require('./img/cleaning-status/daily-comment-purple.svg')
export const IMG_CLEANING_TASK_DAILY_COMMENT_WHITE = require('./img/cleaning-status/daily-comment-white.svg')
export const IMG_CLEANING_TASK_DAILY_COMMENT_BLUE = require('./img/cleaning-status/daily-comment-blue.svg')
export const IMG_CLEANING_TASK_DAILY_COMMENT_GRAY = require('./img/cleaning-status/daily-comment-gray.svg')
export const IMG_CLEANING_TASK_DAILY_COMMENT_BLACK = require('./img/cleaning-status/daily-comment-black.svg')
export const IMG_CLEANING_TASK_DAILY_COMMENT_CURRY = require('./img/cleaning-status/daily-comment-curry.svg')
export const IMG_CLEANING_TASK_DAILY_COMMENT_ROSE = require('./img/cleaning-status/daily-comment-rose.svg')

export const IMG_CLEANING_TASK_DAILY_COMMENT_TEAL = require('./img/cleaning-status/daily-comment-teal.svg')

export const IMG_CLEANING_TASK_EXTRA_CLEANING_RED = require('./img/cleaning-status/extra-cleaning-red.svg')
export const IMG_CLEANING_TASK_EXTRA_CLEANING_YELLOW = require('./img/cleaning-status/extra-cleaning-yellow.svg')
export const IMG_CLEANING_TASK_EXTRA_CLEANING_GREEN = require('./img/cleaning-status/extra-cleaning-green.svg')
export const IMG_CLEANING_TASK_EXTRA_CLEANING_PURPLE = require('./img/cleaning-status/extra-cleaning-purple.svg')
export const IMG_CLEANING_TASK_EXTRA_CLEANING_BLUE = require('./img/cleaning-status/extra-cleaning-blue.svg')
export const IMG_CLEANING_TASK_EXTRA_CLEANING_BLACK = require('./img/cleaning-status/extra-cleaning-black.svg')
export const IMG_CLEANING_TASK_EXTRA_CLEANING_GRAY = require('./img/cleaning-status/extra-cleaning-gray.svg')

export const IMG_CLEANING_STATUS_PRIORITY_WHITE_BACKGROUND = require('./img/cleaning-status/ct-priority-high-white-background.svg')
export const IMG_CLEANING_STATUS_PRIORITY_GRAY_BACKGROUND = require('./img/cleaning-status/ct-priority-high-gray-background.svg')
export const IMG_CLEANING_STATUS_PRIORITY_RED_BACKGROUND = require('./img/cleaning-status/ct-priority-high-red-background.svg')
export const IMG_CLEANING_STATUS_PRIORITY_RED_CIRCLE = require('./img/cleaning-status/ct-priority-high-red-circle.svg')

export const IMG_OCCUPANCY_ALL_BUTTON = require('./img/cleaning-status/all-button.svg')
export const IMG_OCCUPANCY_VACANT_BUTTON = require('./img/cleaning-status/occupancy-vacant-button.svg')
export const IMG_OCCUPANCY_STAYOVER_50_BUTTON = require('./img/cleaning-status/tidy-room-button.svg')
export const IMG_OCCUPANCY_STAYOVER_80_BUTTON = require('./img/cleaning-status/prepare-room-button.svg')
export const IMG_OCCUPANCY_CHECKOUT_BUTTON = require('./img/cleaning-status/checkout-cleaning-button.svg')
export const IMG_OCCUPANCY_CHECKIN_BUTTON = require('./img/cleaning-status/priority-check-in-button.svg')
export const IMG_OCCUPANCY_TURNOVER_BUTTON = require('./img/cleaning-status/turnover-button.svg')

export const IMG_AREA_NOTE_BUTTON = require('./img/area-notes-button.svg')
export const IMG_DAILY_COMMENT_BUTTON = require('./img/daily-comment-button.svg')

export const IMG_WAS_FUR_EIN = require('./img/cleaning-status/was-fur-ein.svg')

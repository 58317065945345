import React from 'react'
import { LoadingScreenWrap } from './style'
import ModalLayout from '../../../components/modals/ModalLayout'
import { LinearProgress } from '@material-ui/core'

export type LoadingScreenProps = { message: string }

const LoadingScreen = ({ message }: LoadingScreenProps) => {
    return (
        <ModalLayout>
            <LoadingScreenWrap>
                <h1>Setting up your account</h1>
                <LinearProgress />
                <span>{message}</span>
            </LoadingScreenWrap>
        </ModalLayout>
    )
}

export default LoadingScreen

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GuestsCard } from './GuestCard'
import { ActivityIndicator, Image } from 'react-native-web'
import { getBrand } from '@shared/brand'
import { Header } from './Header'
import { AreaStruct, BookingStruct, OrgStruct, RuleStruct } from '@shared/firestore-structs'
import styled from 'styled-components'
import { InfoCard } from './InfoCard'
import { RequestCard } from './RequestCard'
import { useIntercom } from 'react-use-intercom'

export interface GuestData {
    organization: OrgStruct
    area: AreaStruct
    booking: BookingStruct
    rules: RuleStruct[]
}
export const BookingInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`
export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 60px;
`
export const ActivityConatiner = styled.div`
    position: absolute;
    right: 44%;
    top: 40%;
    height: 50;
    width: 50;
    z-index: 10000;
`

const BookingInfo = () => {
    const history = useHistory()
    const [data, setData] = useState<GuestData | null>(null)
    const [error, setError] = useState<string>('')

    const { shutdown } = useIntercom()
    shutdown()

    const fetchData = async () => {
        try {
            const response = await axios.get(`/api/guests/booking-info`, { withCredentials: true })
            setData(response.data)
        } catch (error) {
            console.log('Error fetching data:', error)
            history.push('/guest/login')
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return !data ? (
        <ActivityConatiner>
            <ActivityIndicator size="large" color={getBrand().navBarColor} style={{ right: '50%', top: '50%' }} />
        </ActivityConatiner>
    ) : (
        <BookingInfoContainer>
            <Header guest={data} />

            {error && (
                <div className="row justify-content-center">
                    <div className="px-15 mt-40">
                        <div className="d-flex flex-center">
                            <Image style={{ width: '20rem', height: '20rem' }} source={require('../../../img/important-event.svg')} />
                        </div>
                        <div className="font-size-lg text-center">
                            <>
                                <div className="font-size-h2 text-center">{error}.</div>
                            </>
                        </div>
                    </div>
                </div>
            )}
            <div id="kt_content" className={`content d-flex flex-column flex-column-fluid`}>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        {!error && (
                            <div className="row">
                                <GuestsCard guest={data} />
                                <InfoCard guest={data} />
                                {/*<RequestCard guest={data} />*/}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </BookingInfoContainer>
    )
}

export default BookingInfo

import React, { useEffect, JSX } from 'react'
import { compose } from 'redux'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import withIntegrationWorkflow from '../../../../utils/hocs/withIntegrationWorkflow'
import SignUpLayout from '../../SignUpLayout/index'
import LoadingScreen from '../../LoadingScreen/index'
import { beds24setup } from '../../cloud-functions'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { errorModalConfigs } from '../../helpers'
import { INTEGRATIONS, PMS_NAMES } from '../../../../utils/constants'
import { getApi } from '../../frontend-api'
import firebase, { asFirebase, asFirestore, db } from '../../../../utils/firebase'
import { useFeatureToggle } from 'app/features'
import { ConfigParams } from '../../types'

const getPartner = (bus: any) => {
    switch (bus) {
        case PMS_NAMES.GODO:
            return 'godo'
        case 'BookingAutomation':
            return 'booking-automation'
        default:
            return 'beds24'
    }
}

const Beds24Config = ({
    newOrgKey,
    catchError,
    signupComplete,
    workflowInstance,
    storeWorkflowInstance,
    setComplete,
    currentIntegrationKey,
    newUserKey,
    settings,
    message,
    setStopAuthListener
}: ConfigParams) => {
    const { isFeatureOn } = useFeatureToggle()
    const useNewInitialImport = isFeatureOn('new-initial-import-procedure')

    const setupBeds24Old = async () => {
        console.info('Initial Beds24 PMS Sync: Using old method')
        const setupBeds24Integration = beds24setup()
        const bed24AccountIDs = workflowInstance.getInput('propownerId') as string | string[]

        try {
            const { data } = await setupBeds24Integration({
                orgId: '' + newOrgKey,
                integration: INTEGRATIONS.BEDS_24,
                isTest: false,
                signupPayload: { bed24AccountIDs, options: {}, partner: getPartner(workflowInstance.getInput('bus')) }
            })

            if (data.reason !== 'all good') {
                catchError(data.message, errorModalConfigs)
                return
            }

            if ('test' in workflowInstance.values) {
                //@ts-ignore: not valid according to the type.
                data.reason = 'test'
            }

            await signupComplete({ orgId: newOrgKey, userId: newUserKey, status: data })

            workflowInstance.completeStep(SIGN_UP.BEDS_24_CONFIG, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupBeds24New = async () => {
        console.info('Initial Beds24 PMS Sync: Using new server-side method')
        try {
            const api = await getApi(asFirebase(firebase))

            await api['setup-org'].$post({
                json: {
                    newAdminUserKey: newUserKey,
                    orgId: newOrgKey,
                    version: 'stable',
                    integration: 'beds24',
                    isTest: false,
                    signupPayload: {
                        bed24AccountIDs: workflowInstance.getInput('propownerId'),
                        options: {},
                        partner: getPartner(workflowInstance.getInput('bus'))
                    }
                }
            })

            workflowInstance.completeStep(SIGN_UP.BEDS_24_CONFIG, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)
            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupBeds24 = async () => (useNewInitialImport ? await setupBeds24New() : await setupBeds24Old())

    async function setup() {
        setStopAuthListener(true)
        await setupBeds24()
    }

    useEffect(() => {
        setup()
    }, [])

    return (
        <SignUpLayout withModal>
            <LoadingScreen message={message} />
        </SignUpLayout>
    )
}

// The explicit generic is necessary for tsc to resolve the
// proper compose function.
const wrapper = compose<any, any, () => JSX.Element>(withIntegrationWorkflow, withErrorCatching)
const WrappedBeds24Config = wrapper(Beds24Config)
export default WrappedBeds24Config

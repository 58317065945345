import React from 'react'
import moment from 'moment-timezone'
import { getPlural } from '@shared/helpers'
import { GuestData } from './index'
import styled from 'styled-components'
import * as colors from '@shared/colors'

export const Card = styled.div`
    @media (min-width: 992px) {
        box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
    }
`
export const CardBody = styled.div`
    padding: 20px 30px;
    @media (max-width: 992px) {
        padding: 1px;
    }
`

function BookingInfo({ guest }: { guest: GuestData }) {
    const { booking } = guest
    const guestCount = booking.adults + booking.children
    return (
        <div className="py-0">
            <div className="d-flex justify-content-between align-items-center font-weight-bolder">
                <h3 className="font-weight-bolder font-size-h2">{booking.guestName}</h3>
                <div className="text-muted font-size-h6" style={{ minWidth: 60 }}>
                    {guestCount} {getPlural('guest', guestCount)}{' '}
                </div>
            </div>
            {/*<div className="  font-size-lg">*/}
            {/*    <span className="text-muted font-weight-bolder">Booking number</span> {booking.bookingId}*/}
            {/*</div>*/}
            <div className="pt-5 pb-13">
                <div className="row justify-content-center font-size-h6 mb-1">
                    <div style={{ position: 'relative', bottom: -6 }}>{booking.bookingDates.length - 1} nights</div>
                </div>
                <div className="row font-size-sm font-weight-bolder">
                    <div className="col-6 text-center partial-border-right">
                        <div className="row justify-content-center align-items-center">
                            <i
                                className="specta-check-in"
                                style={{
                                    height: 20,
                                    transform: 'translateY(-3px)',
                                    marginRight: 5,
                                    fontSize: 17,
                                    color: colors.black_sweeply
                                }}
                            />
                            <span className="font-size-lg ">Check in</span>
                        </div>
                        <h1 className="display2 my-0">{moment(booking.checkinDate).format('DD')}</h1>
                        <div className="font-size-lg font-weight-bold ">{moment(booking.checkinDate).format('MMM YYYY')}</div>
                    </div>
                    <div className="col-6 text-center">
                        <div className="row justify-content-center align-items-center">
                            <i
                                className="specta-suitcase"
                                style={{
                                    height: 20,
                                    transform: 'translateY(-1px)',
                                    marginRight: 5,
                                    fontSize: 15,
                                    color: colors.black_sweeply
                                }}
                            />
                            <span className="font-size-lg ">Check out</span>
                        </div>

                        <h1 className="display2 my-0">{moment(booking.checkoutDate).format('DD')}</h1>
                        <div className="font-size-lg font-weight-bold ">{moment(booking.checkoutDate).format('MMM YYYY')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const GuestsCard = ({ guest }: { guest: GuestData }) => {
    return (
        <div className="col-12 col-lg-4" id="kt_profile_aside">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Card>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <CardBody className="pt-4">
                    <BookingInfo guest={guest} />
                </CardBody>
            </Card>
        </div>
    )
}

import React, { useState } from 'react'
import { CleaningDateProps } from '../GuestHome/pages/CleaningSchedule/CleaningScheduleLocal'
import { GuestData } from '.'
import { PlanningInfo } from '../GuestHome/pages/CleaningSchedule/PlanningInfo'
import { DateBubble } from '../GuestHome/pages/CleaningSchedule/DateBubble'
import {
    CLEANING_STATUS_CLEAN,
    CLEANING_STATUS_DIRTY,
    CLEANING_STATUS_NO_SERVICE,
    CLEANING_STATUS_WAITING_FOR_CHECKOUT,
    OCCUPANCY_CHECKIN,
    OCCUPANCY_CHECKOUT,
    OCCUPANCY_STAYOVER,
    OCCUPANCY_TURNOVER,
    OCCUPANCY_VACANT
} from '@shared/txt-constants'
// import { sectionMonths } from '@shared/date-helpers'
import moment from 'moment-timezone'
import axios from 'axios'
import { RedButton } from 'app/components/buttons/RedButton'
import { Card, CardBody } from './GuestCard'
import * as colors from '@shared/colors'
import { calculateCleaningStatus, calculateCleaningStatusSync } from '@shared/calculator/cleaning-calculator'
import firebase from '../../../utils/firebase'
import { isToday } from '@shared/cleaning-helpers'

export const InfoCard = ({ guest }: { guest: GuestData }) => {
    const [optOutDates, setOptOutDates] = useState(guest.booking.optOutDates || [])
    const [optInDates, setOptInDates] = useState(guest.booking.optInDates || [])
    const [toggleAll, setToggleAll] = useState(false)
    const { infoTextTitle, infoText, cutoffTime } = guest.organization.guestPagePreferences || {}
    // const months: any = sectionMonths(guest.booking.bookingDates.map(x => parseInt(x)))

    const postData = async (data: any) => {
        try {
            const url = `/api/guests/set-cleaning-dates`
            const response = await axios.post(url, data, { withCredentials: true })
            setOptInDates(response.data.optInDates)
            setOptOutDates(response.data.optOutDates)
        } catch (error) {
            console.error('Request error:', error)
        }
    }
    // const resolveCleaningStatus = (date: string) =>
    //     optInDates?.includes(date)
    //         ? CLEANING_STATUS_DIRTY
    //         : date === guest.booking.checkinDate.toString()
    //         ? CLEANING_STATUS_CLEAN
    //         : date === guest.booking.checkoutDate.toString()
    //         ? CLEANING_STATUS_WAITING_FOR_CHECKOUT
    //         : CLEANING_STATUS_NO_SERVICE
    //
    // const resolveOccupancy = (date: string) =>
    //     optInDates?.includes(date)
    //         ? OCCUPANCY_STAYOVER
    //         : date === guest.booking.checkinDate.toString()
    //         ? OCCUPANCY_CHECKIN
    //         : date == guest.booking.checkoutDate.toString()
    //         ? OCCUPANCY_CHECKOUT
    //         : OCCUPANCY_VACANT

    // const onToggleClick = () => {
    //     const datesToChange = guest.booking.bookingDates.filter(
    //         date =>
    //             date !== guest.booking.checkinDate.toString() &&
    //             date !== guest.booking.checkoutDate.toString() &&
    //             !moment(parseInt(date)).isBefore(moment().startOf('day').valueOf())
    //     )
    //     const allSelected = datesToChange.length === optInDates.length
    //
    //     const data = {
    //         bookingId: guest.booking.bookingId,
    //         optInDates: allSelected ? [] : datesToChange,
    //         optOutDates: allSelected ? datesToChange : []
    //     }
    //     postData(data)
    //     setToggleAll(!toggleAll)
    // }
    return (
        <div className="col-12 col-lg-8">
            <Card>
                <CardBody>
                    {infoTextTitle && <h1 className="font-size-h1 mb-5">{infoTextTitle}</h1>}
                    {infoText && <p className="font-size-h4 mb-5">{infoText}</p>}
                    <div className="align-items-start flex-column mb-5">
                        <div className="d-flex mb-4">
                            <i className="specta-rules font-size-h3 line-height-xl text-dark mr-4" />
                            <span className="font-weight-bolder font-size-h2">Housekeeping plan</span>
                        </div>
                        <span className="text-muted font-weight-bolder font-size-h6">Press a day to either clean or skip cleaning.</span>
                    </div>
                    <form className="">
                        <div className="form">
                            <div className="d-flex justify-content-between flex-wrap">
                                <PlanningInfo />
                            </div>
                            {/* <div className="d-flex justify-content-end flex-wrap">
                                <RedButton onClick={onToggleClick}>Toggle all</RedButton>
                            </div> */}
                            <div className="row row-paddingless mt-8">
                                {guest.booking.bookingDates.map((date, i) => {
                                    const b = { ...guest.booking, optInDates }
                                    const calcArea: any = calculateCleaningStatusSync(
                                        [Object.assign({}, guest.area)],
                                        parseInt(date),
                                        guest.organization,
                                        [b],
                                        [],
                                        guest.rules
                                    )
                                    // const occupancy = resolveOccupancy(date)
                                    // const cleaningStatus = calcArea[0].cleaningStatus
                                    const onBubbleClick = (date: number) => {
                                        const dates = guest.organization.allowOptIn
                                            ? guest.booking?.optInDates ?? []
                                            : guest.booking?.optOutDates ?? []
                                        const index = dates.indexOf(date.toString())
                                        const updatedDates =
                                            index > -1
                                                ? dates.filter(existingDate => existingDate !== date.toString())
                                                : [...dates, date.toString()]

                                        const data = {
                                            bookingId: guest.booking.bookingId,
                                            optInDates: updatedDates
                                        }
                                        guest.booking.optInDates = updatedDates
                                        setOptInDates(updatedDates)

                                        postData(data)
                                    }

                                    function isBeforeCutoff(date: number, cutoffTime: string): boolean {
                                        const currentTime = Date.now()
                                        const combinedDateTime = moment.tz(currentTime, guest.organization.timezone)
                                        const cutoffDateTime = moment
                                            .tz(date, guest.organization.timezone)
                                            .startOf('day')
                                            .set({ hour: parseInt(cutoffTime.split(':')[0]), minute: parseInt(cutoffTime.split(':')[1]) })
                                        return combinedDateTime.isBefore(cutoffDateTime)
                                    }

                                    let shouldEnableBubble = true

                                    if (isToday(parseInt(date))) {
                                        shouldEnableBubble = cutoffTime ? isBeforeCutoff(parseInt(date), cutoffTime) : false
                                    }

                                    const bubbleData = {
                                        date: parseInt(date),
                                        cleaningStatus: calcArea[0].cleaningStatus,
                                        occupancy: calcArea[0].occupancy,
                                        onClick: onBubbleClick,
                                        disabled: calcArea[0].activeRule || !shouldEnableBubble
                                    } as CleaningDateProps
                                    const subheader = () => {
                                        return bubbleData.occupancy === OCCUPANCY_CHECKIN ? (
                                            <div className="row align-items-center justify-content-start" style={{ width: '84px' }}>
                                                <i
                                                    className="specta-check-in"
                                                    style={{
                                                        transform: 'translateY(-3px)',
                                                        height: 20,
                                                        marginRight: 5,
                                                        color: colors.black_sweeply,
                                                        fontSize: 17
                                                    }}
                                                />
                                                {'Check in'}
                                            </div>
                                        ) : [OCCUPANCY_CHECKOUT, OCCUPANCY_TURNOVER].includes(bubbleData.occupancy) ? (
                                            <div className="row align-items-center justify-content-start" style={{ width: '84px' }}>
                                                <i
                                                    className="specta-suitcase "
                                                    style={{
                                                        height: 20,
                                                        transform: 'translateY(-2px)',
                                                        marginRight: 5,
                                                        fontSize: 15,
                                                        color: colors.black_sweeply
                                                    }}
                                                />
                                                {'Check out'}
                                            </div>
                                        ) : (
                                            ''
                                        )
                                    }

                                    return (
                                        <div key={i} className="col-4 col-md-2 col-lg-3 text-center">
                                            <div
                                                className="font-size-sm font-weight-bolder mb-2 d-flex justify-content-center"
                                                style={{ minHeight: 18 }}>
                                                {subheader()}
                                            </div>
                                            <span className="flex-center d-flex">
                                                <DateBubble {...bubbleData} />
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    )
}

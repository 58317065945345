import {
    AreaStruct,
    AreaSummaryProjectionStruct,
    BookingStruct,
    DailyCommentStruct,
    HousekeepingOverviewProjectionStruct,
    TaskStruct
} from '../../firestore-structs'
import moment from 'moment-timezone'

export const MissingGroup = 'default-group'
export function areaOverview({
    area,
    leadBooking,
    updatedArea,
    currentTask,
    isPriority,
    areaSummary,
    dailyComments
}: {
    area: AreaStruct
    leadBooking: BookingStruct | null
    updatedArea: AreaStruct[]
    currentTask?: TaskStruct
    isPriority: boolean
    areaSummary: AreaSummaryProjectionStruct
    dailyComments: DailyCommentStruct[]
}) {
    const housekeepingOverview: HousekeepingOverviewProjectionStruct = {
        [area.group || MissingGroup]: {
            [area.key]: {
                name: area.name,
                note: area.note ?? '',
                extras: leadBooking?.extras ?? null,
                activeRule: {
                    customChar: updatedArea[0].activeRule?.customChar ?? null,
                    name: updatedArea[0].activeRule?.name ?? null,
                    description: updatedArea[0].activeRule?.description ?? null,
                    checklistTasks: updatedArea[0].activeRule?.checklistTasks ?? null
                },
                currentTask: currentTask ?? null,
                bookingNotes: leadBooking?.notes ?? null,
                guestCheckedIn: updatedArea[0].guestCheckedIn!,
                priority: isPriority,
                guestCheckedOut: updatedArea[0].guestCheckedOut!,
                cleaningStatus: areaSummary.area.cleaningStatus,
                occupancy: areaSummary.area.occupancy,
                assignedTo: currentTask ? currentTask.assignedTo ?? [] : [],
                dailyComments: (dailyComments || []).map(d => {
                    return { comment: d.comment, date: d.date, key: d.key }
                }),
                leadBooking: leadBooking
                    ? {
                          nrOfGuests: leadBooking.adults + leadBooking.children,
                          checkinDate: moment(leadBooking.checkinDate).valueOf(),
                          checkoutDate: moment(leadBooking.checkoutDate).valueOf(),
                          status: leadBooking.status,
                          guestName: leadBooking.guestName,
                          notes: leadBooking.notes ?? null,
                          bedSetup: leadBooking.bedSetup ?? null,
                          areaKey: leadBooking.areaKey,
                          key: leadBooking.key,
                          optInDates: leadBooking.optInDates ?? null,
                          optOutDates: leadBooking.optOutDates ?? null,
                          guestCheckedIn: leadBooking.guestCheckedIn,
                          guestCheckedOut: leadBooking.guestCheckedOut
                      }
                    : null,
                synced: area.synced
            }
        }
    }
    return housekeepingOverview
}

import moment from 'moment-timezone'
import { ActivityStruct, AreaCleaningStatus, AreaStruct, BookingStruct, Occupancy, OrgStruct, RuleStruct } from './firestore-structs'
import { sortTimeStampAscending } from './helpers'
import * as c from './txt-constants'
import { resolveBookingRule, resolveRepeatDates, resolveRule, RuleResolveResult } from './rule-resolver'
import { findLeadBooking } from './booking-service'
import { structuredClone } from './polyfills'
import { isFeatureOn } from './feature-toggles'

export interface CleaningStatus {
    cleaningStatus: AreaCleaningStatus
    occupancy: Occupancy
}

interface CalculateRuleAndCleaningStatus {
    ruleName: string | null
    activeRule: RuleStruct | null
    cleaningStatus: AreaCleaningStatus
    occupancy: Occupancy
}

interface GuestStatus {
    guestCheckedIn: boolean
    guestCheckedOut: boolean
}

export interface CalculateCleaningStatusReturn {
    cleaningStatus: AreaCleaningStatus
    occupancy: Occupancy
    guestCheckedIn: boolean
    guestCheckedOut: boolean
}

export function findBookingsForArea(bookings: BookingStruct[], areaKey: string): BookingStruct[] {
    return bookings
        .filter(booking => booking.areaKey === areaKey && [c.BOOKING_STATUS_BOOKED, c.BOOKING_STATUS_BLOCKED].includes(booking.status))
        .sort((a: BookingStruct, b: BookingStruct) => sortTimeStampAscending(a.checkinDate, b.checkinDate))
}

export function findRulesForArea(rules: RuleStruct[], areaKey: string): RuleStruct[] {
    return rules.filter(rule => rule.areas.includes(areaKey)).sort((a, b) => a.priority - b.priority)
}

export function findActivitiesForArea(activities: ActivityStruct[], areaKey: string): ActivityStruct[] {
    return activities.filter(activity => activity.areaKey === areaKey).sort((a: ActivityStruct, b: ActivityStruct) => a.created - b.created)
}

export function getOccupancyFromBooking(bookings: BookingStruct[], date: number): Occupancy {
    const checkinBookings = bookings.filter(b => b.checkinDate === date)
    const checkoutBookings = bookings.filter(b => b.checkoutDate === date)
    const stayOverBookings = bookings.filter(
        b => b.checkinDate !== date && b.checkoutDate !== date && b.bookingDates.includes(date.toString())
    )

    if (stayOverBookings.length > 0) {
        return c.OCCUPANCY_STAYOVER
    } else if (checkinBookings.length > 0 && checkoutBookings.length > 0) {
        return c.OCCUPANCY_TURNOVER
    } else if (checkinBookings.length > 0) {
        return c.OCCUPANCY_CHECKIN
    } else if (checkoutBookings.length > 0) {
        return c.OCCUPANCY_CHECKOUT
    } else {
        return c.OCCUPANCY_VACANT
    }
}

//TODO - review and rewrite this function
// export function getActiveRule(rules: RuleStruct[], bookings: BookingStruct[], date: number, org: Pick<OrgStruct, 'timezone'>) {
//     const results = rules
//         .map(r => {
//             const ruleResolveResult = resolveRule(r, bookings, date, org, { cleanUntilCheckin: isSMARTmentsOrg(r.organizationKey) })
//             return { ...ruleResolveResult, priority: r.priority }
//         })
//         .filter(result => result && Object.keys(result).length > 0)
//
//     const sortedResults = results.sort((a, b) => b.priority - a.priority)
//     const activeResult = sortedResults.length > 0 ? sortedResults[0] : rules[0]
//
//     return activeResult
// }

export function calculateCleaningStatusFromBookingsAndOccupancy(
    bookings: BookingStruct[],
    occupancy: Occupancy,
    cleaningStatus: AreaCleaningStatus
): CleaningStatus {
    if (bookings.length > 0 && bookings[0].status && bookings[0].status === c.BOOKING_STATUS_BLOCKED) {
        return { cleaningStatus: c.CLEANING_STATUS_OUT_OF_SERVICE, occupancy: c.OCCUPANCY_VACANT }
    }
    return { cleaningStatus, occupancy }
}

export function calculateCleaningStatusFromActivities(
    cleaningStatus: AreaCleaningStatus,
    occupancy: Occupancy,
    activities: ActivityStruct[]
): CleaningStatus {
    const guestCheckedOut = activities.filter(
        a => a.change.field && ['guestCheckedOut', 'guestCheckedIn'].includes(a.change.field) && a.change.after === true
    )
    const findActivities = (field: string) =>
        activities.filter(a => a.change.field && a.change.field === field).sort((a, b) => b.created - a.created)

    const cleaningStatusActivities = findActivities('cleaningStatus')
    const occupancyActivities = findActivities('occupancy')

    const latestGuestCheckedOutActivity =
        guestCheckedOut.length > 0 && guestCheckedOut[0].change.field === 'guestCheckedOut' ? guestCheckedOut[0] : null
    const latestCleaningStatusActivity = cleaningStatusActivities.length > 0 ? cleaningStatusActivities[0] : null

    let updatedCleaningStatus = structuredClone(cleaningStatus)
    const updatedOccupancy = occupancyActivities.length > 0 ? occupancyActivities[0].change.after : occupancy

    if (!latestCleaningStatusActivity && latestGuestCheckedOutActivity) {
        updatedCleaningStatus = c.CLEANING_STATUS_DIRTY
    } else if (latestCleaningStatusActivity) {
        updatedCleaningStatus = latestCleaningStatusActivity.change.after as AreaCleaningStatus
    }

    return { cleaningStatus: updatedCleaningStatus, occupancy: updatedOccupancy as Occupancy }
}

export function wasLastExtraCleaningSkipped(
    rules: RuleStruct[],
    booking: BookingStruct,
    date: number,
    org: Pick<OrgStruct, 'timezone' | 'featuresDisabled' | 'featuresEnabled'>,
    area: Pick<AreaStruct, 'daysSinceLastCleaning' | 'daysSinceLastCheckout' | 'cleaningStatus'>
) {
    const extraCleaningRule = rules.find(rule => rule.repeatType === 'custom' && rule.repeatInterval > 1)
    const options = { cleanUntilCheckin: isFeatureOn(org, 'clean-until-checkin') }

    if (!extraCleaningRule) {
        return false
    }

    const occupancy = resolveBookingRule(extraCleaningRule, [booking], date, org, area, options).occupancy

    if (occupancy === c.OCCUPANCY_STAYOVER_80) {
        return false
    }

    const extraCleaningDays = resolveRepeatDates(extraCleaningRule, booking.checkinDate, booking.checkoutDate, org)
    const extraCleaningDaysPrior = extraCleaningDays.filter(day => moment(day).isSameOrBefore(date))

    if (extraCleaningDaysPrior.includes(date)) {
        return false
    }

    const extraCleaningDaysOptedInto =
        booking?.optInDates
            ?.filter(day => extraCleaningDaysPrior.includes(parseInt(day)))
            .sort((a, b) => sortTimeStampAscending(parseInt(a), parseInt(b))) || []

    if (extraCleaningDaysPrior.length !== extraCleaningDaysOptedInto.length) {
        if (extraCleaningDaysOptedInto.length === 0) {
            return true
        }

        const lastExtraCleaningDayOptedInto = extraCleaningDaysOptedInto[extraCleaningDaysOptedInto.length - 1]
        const daysSinceLastExtraCleaning = moment(date).diff(moment(parseInt(lastExtraCleaningDayOptedInto)), 'days')

        if (lastExtraCleaningDayOptedInto && daysSinceLastExtraCleaning >= extraCleaningRule.repeatInterval) {
            return true
        }
    }

    return false
}

export function calculateRuleAndCleaningStatus(
    cleaningStatus: AreaCleaningStatus,
    occupancy: Occupancy,
    bookings: BookingStruct[],
    date: number,
    rules: RuleStruct[],
    org: Pick<OrgStruct, 'timezone' | 'featuresEnabled' | 'featuresDisabled'>,
    area: Pick<AreaStruct, 'daysSinceLastCleaning' | 'daysSinceLastCheckout' | 'cleaningStatus'>
): CalculateRuleAndCleaningStatus {
    const options = { cleanUntilCheckin: isFeatureOn(org, 'clean-until-checkin') }
    const results = rules
        .map((rule: RuleStruct) => {
            const resolveResult: RuleResolveResult = resolveRule(rule, bookings, date, org, area, options)
            return { rule, resolveResult }
        })
        .filter(result => result && Object.keys(result.resolveResult).length > 0)
        .sort((a, b) => b.rule.priority - a.rule.priority)

    const activeResult = results[0] || {}

    if (activeResult) {
        let result: CalculateRuleAndCleaningStatus = { ruleName: null, activeRule: null, cleaningStatus, occupancy }

        if (activeResult.resolveResult?.occupancy) {
            result = {
                ...result,
                ruleName: activeResult.rule.name,
                activeRule: activeResult.rule,
                occupancy: activeResult.resolveResult.occupancy
            }
        }

        if (activeResult.resolveResult?.cleaningStatus) {
            result = {
                ...result,
                ruleName: activeResult.rule.name,
                activeRule: activeResult.rule,
                cleaningStatus: activeResult.resolveResult.cleaningStatus as AreaCleaningStatus
            }
        }

        if (activeResult.resolveResult?.inspection && result.cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
            result = {
                ...result,
                ruleName: activeResult.rule.name,
                activeRule: activeResult.rule
            }
        }

        return result
    }

    return { cleaningStatus, occupancy, ruleName: null, activeRule: null }
}

export function calculateOptIns(
    cleaningStatus: AreaCleaningStatus,
    occupancy: Occupancy,
    bookings: BookingStruct[],
    date: number,
    rules: RuleStruct[],
    org: Pick<OrgStruct, 'timezone' | 'featuresEnabled' | 'featuresDisabled'>,
    area: Pick<AreaStruct, 'daysSinceLastCleaning' | 'daysSinceLastCheckout' | 'cleaningStatus'>
): CalculateRuleAndCleaningStatus | CleaningStatus {
    const leadBooking = findLeadBooking({ occupancy }, bookings, date)
    const optIn = leadBooking?.optInDates?.includes(date.toString())

    if (optIn && leadBooking && wasLastExtraCleaningSkipped(rules, leadBooking, date, org, area)) {
        return { cleaningStatus: c.CLEANING_STATUS_DIRTY, occupancy: c.OCCUPANCY_STAYOVER_80 }
    }

    const ruleAndCleaningStatus = calculateRuleAndCleaningStatus(cleaningStatus, occupancy, bookings, date, rules, org, area)

    if (!optIn) {
        return { cleaningStatus: c.CLEANING_STATUS_NO_SERVICE, occupancy }
    }

    return ruleAndCleaningStatus
}

export function calculateOptOuts(
    cleaningStatus: AreaCleaningStatus,
    occupancy: Occupancy,
    bookings: BookingStruct[],
    date: number,
    rules: RuleStruct[],
    org: Pick<OrgStruct, 'timezone'>,
    area: Pick<AreaStruct, 'daysSinceLastCleaning' | 'daysSinceLastCheckout' | 'cleaningStatus'>
): CalculateRuleAndCleaningStatus | CleaningStatus {
    const leadBooking = findLeadBooking({ occupancy }, bookings, date)
    const optOut = leadBooking?.optOutDates?.includes(date.toString())

    if (optOut) {
        return { cleaningStatus: c.CLEANING_STATUS_OUT_OF_SERVICE, occupancy }
    }

    const ruleAndCleaningStatus = calculateRuleAndCleaningStatus(cleaningStatus, occupancy, bookings, date, rules, org, area)

    return ruleAndCleaningStatus
}

export function calculateCleaningStatusFromRule(
    cleaningStatus: AreaCleaningStatus,
    occupancy: Occupancy,
    rules: RuleStruct[],
    bookings: BookingStruct[],
    currentOrganization: OrgStruct,
    date: number,
    area: Pick<AreaStruct, 'daysSinceLastCleaning' | 'daysSinceLastCheckout' | 'cleaningStatus'>
): CalculateRuleAndCleaningStatus {
    const hasRules = rules && rules.length > 0
    const allowOptIn = currentOrganization?.allowOptIn
    const allowOptOut = currentOrganization?.allowOptOut
    const ruleEmpty = { activeRule: null, ruleName: null }

    if (hasRules) {
        if (occupancy === c.OCCUPANCY_STAYOVER && allowOptIn) {
            return { ...calculateOptIns(cleaningStatus, occupancy, bookings, date, rules, currentOrganization, area), ...ruleEmpty }
        } else if (occupancy === c.OCCUPANCY_STAYOVER && allowOptOut) {
            return { ...calculateOptOuts(cleaningStatus, occupancy, bookings, date, rules, currentOrganization, area), ...ruleEmpty }
        } else {
            if (bookings[0]?.status !== c.BOOKING_STATUS_BLOCKED) {
                return calculateRuleAndCleaningStatus(cleaningStatus, occupancy, bookings, date, rules, currentOrganization, area)
            }
        }
    }

    return { cleaningStatus, occupancy, ...ruleEmpty }
}

export function calculateGuestStatus(occupancy: string, bookings: BookingStruct[], date: number): GuestStatus {
    const checkinBookings = bookings.filter(booking => booking.checkinDate === date)
    const checkoutBookings = bookings.filter(booking => booking.checkoutDate === date)
    const guestCheckedIn = occupancy === c.OCCUPANCY_STAYOVER || checkinBookings.some(b => b.guestCheckedIn)
    const guestCheckedOut = checkoutBookings.some(b => b.guestCheckedOut)

    return {
        guestCheckedIn,
        guestCheckedOut
    }
}

export function calculateCleaningStatus(
    cleaningStatus: AreaCleaningStatus,
    selectedDate: number,
    currentOrganization: OrgStruct,
    bookings: BookingStruct[] = [],
    activities: ActivityStruct[] = [],
    rules: RuleStruct[],
    area: AreaStruct
): CalculateRuleAndCleaningStatus {
    const date = moment(selectedDate).startOf('day')
    const updatedCleaningStatus = date.isSame(moment().startOf('day')) ? cleaningStatus : c.CLEANING_STATUS_CLEAN
    const updatedOccupancy = getOccupancyFromBooking(bookings, date.valueOf())
    const bookingsAndOccupancyResult = calculateCleaningStatusFromBookingsAndOccupancy(bookings, updatedOccupancy, updatedCleaningStatus)
    const rulesResult = calculateCleaningStatusFromRule(
        bookingsAndOccupancyResult.cleaningStatus,
        bookingsAndOccupancyResult.occupancy,
        rules,
        bookings,
        currentOrganization,
        date.valueOf(),
        area
    )

    const result = calculateCleaningStatusFromActivities(rulesResult.cleaningStatus, rulesResult.occupancy, activities)

    return {
        cleaningStatus: result.cleaningStatus,
        occupancy: result.occupancy,
        activeRule: rulesResult.activeRule,
        ruleName: rulesResult.ruleName
    }
}

import React from 'react'
import { IconImg } from './style'

interface HousekeepingIconProps {
    icon: { source: string | null | undefined; width?: number | null | undefined; height?: number | null | undefined } | null | undefined
    scale?: number
}

const HousekeepingIcon: React.FC<HousekeepingIconProps> = ({ icon, scale = 0 }) => {
    if (!icon || !icon.source) return null
    const iconWidth = icon.width ? icon.width * scale : null
    const iconHeight = icon.height ? icon.height * scale : null
    return <IconImg src={icon.source} iconWidth={iconWidth} iconHeight={iconHeight} />
}

export default HousekeepingIcon

import React, { useContext, useState } from 'react'
import { Field } from 'formik'
import { Input, Select, MegaRadio } from '../../../../../../_metronic/_partials/controls'
import { RuleTriggers, BookingRuleRepeateTypes, DataRuleRepeatTypes, AVAILABLE_COLORS } from '../../../RulesUIHelpers'
import DateRepeatForm from './DateRepeatForm'
import BookingRepeatForm from './BookingRepeatForm'
import { AuthContext } from '../../../../Auth/AuthContext'
import { NumberInput } from '../../../../../../_metronic/_partials/controls/forms/Input.js'

export default function RuleForm(props) {
    const {
        formField: { name, description },
        values,
        setFieldValue,
        handleChange
    } = props
    const repeatTypes = values.trigger === 'booking' ? BookingRuleRepeateTypes : DataRuleRepeatTypes

    const { currentUser } = useContext(AuthContext)
    if (currentUser.authSuper) {
        if (!repeatTypes.find(r => r.type === 'optin')) {
            repeatTypes.push({ type: 'optin', text: 'Opt in' })
        }
    }

    const onRepeatTypeSelect = e => {
        const value = e.target.value
        const repeat = repeatTypes.find(r => r.type === value)

        setFieldValue('repeatType', value)

        if (repeat.type !== 'custom' && repeat.frequency) {
            setFieldValue('repeatInterval', repeat.frequency)
        }
    }

    return (
        <div className="pb-5">
            <div className="form-group">
                <label className="font-weight-bold">Rule name:</label>
                <Field name="name" component={Input} placeholder="Rule name" customFeedbackLabel={' '} />
            </div>
            <div className="form-group">
                <label className="font-weight-bold">Description:</label>
                <Field name="description" as="textarea" placeholder="A descriptive text about your rule" className="form-control" />
            </div>
            <div className="form-group m-0">
                <label className="font-weight-bold">Choose trigger:</label>
                <div className="row">
                    {RuleTriggers.map((trigger, index) => (
                        <Field
                            key={index}
                            component={MegaRadio}
                            name="trigger"
                            value={trigger.type}
                            options={trigger}
                            onChange={e => {
                                values.repeatType =
                                    trigger.type === 'booking' ? BookingRuleRepeateTypes[0].type : DataRuleRepeatTypes[0].type
                                values.inspection = trigger.type === 'booking' ? values.inspection : false
                            }}
                        />
                    ))}
                </div>
            </div>
            {values.trigger === 'booking' && (
                <div className="form-group mt-5">
                    <label className="font-weight-bold">Repeat on:</label>
                    <Select value={values.repeatType} onChange={onRepeatTypeSelect} className="custom-select" name="repeatType">
                        {repeatTypes.map(repeat => {
                            return (
                                <option key={repeat.type} value={repeat.type}>
                                    {repeat.text}
                                </option>
                            )
                        })}
                    </Select>
                </div>
            )}
            {values.repeatType === 'checkout' && (
                <div className="form-group mt-5">
                    <label className="checkbox checkbox-outline checkbox-outline-2x checkbox-specta-blue">
                        <Field type="checkbox" name="inspection" />
                        <span className="mr-2"></span>
                        Inspection
                        {values.inspection}
                    </label>
                    <span className="form-text text-warning">
                        Selecting this will set unit status to &apos;inspection&apos; after cleaning anytime after checkout
                    </span>
                </div>
            )}

            <div className="form-group mt-5">
                <label className="checkbox checkbox-outline checkbox-outline-2x checkbox-specta-blue">
                    <Field type="checkbox" name="isDefaultRule" />
                    <span className="mr-2"></span>
                    Default rule
                    {values.isDefaultRule}
                </label>
                <span className="form-text text-warning">Selecting this will automatically add this rule to all new units</span>
            </div>
            {values.trigger === 'booking' && <BookingRepeatForm />}
            {values.trigger === 'date' && <DateRepeatForm />}
            {currentUser.authSuper && (
                <>
                    <div className="form-group mt-5">
                        <label className="font-weight-bold">Minimum stay:</label>
                        <Field name="minStay" component={NumberInput} placeholder="Minimum stay" customFeedbackLabel={' '} />
                    </div>
                    <div className="form-group mt-5">
                        <label className="font-weight-bold">Maximum stay:</label>
                        <Field name="maxStay" component={NumberInput} placeholder="Maximum stay" customFeedbackLabel={' '} />
                    </div>
                    <div className="form-group mt-5">
                        <label className="font-weight-bold">Custom character:</label>
                        <Field
                            name="customChar"
                            component={CustomMaxLengthInput}
                            placeholder="Custom character"
                            customFeedbackLabel={' '}
                        />
                    </div>
                    <div className="form-group mt-5">
                        <label className="font-weight-bold">Priority:</label>
                        <Field name="priority" component={NumberInput} placeholder="Priority" customFeedbackLabel={' '} />
                    </div>
                </>
            )}
        </div>
    )
}

const CustomMaxLengthInput = props => {
    return <Input maxLength={1} {...props} />
}

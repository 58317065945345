import { iOSColors } from '@shared/react-native-typography'
import * as chelpers from '@shared/cleaning-helpers'
import { ImageSourcePropType, View } from 'react-native-web'
import PriorityButton from './priority-button'
import { GuestQrButton } from '../../guest-qr-button'
import { SubscriptionCheck } from '../../../components/upgrade-signals'
import React, { useContext } from 'react'
import { TaskScreenContext } from './TaskScreenContext'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import BoxIcon from '../../housekeeping-box/box-icon'
import CircleChar from '../../../components/CircleChar'
import {
    BUTTON_DIM,
    HOVER_OPACITY,
    MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE,
    MODAL_TOP_TO_BUTTONS_BAR_VERTICAL_SPACE
} from 'app/utils/constants'
import { HoverableImageButton } from '../../../components/buttons/hoverable-image-button'
import DatePicker from '../../../components/hoverable-date-picker'
import { AreaBredCrumbs } from '../../../components/area-bread-crumbs'
import moment from 'moment-timezone'
import { DueDateButton } from '../../../components/buttons/DueDateButton'
import * as areaData from '@shared/area-data'
import firebase, { asFirebase } from '../../../utils/firebase'
import * as c from '@shared/constants'
import * as taskData from '@shared/task-data'

const Header = (props: any) => {
    const { area, selectedDateNumber, setSelectedDateNumber, currentTask, setShowUsersModal, openUpgradeMessage } = useContext(
        TaskScreenContext
    )
    const { currentUser } = useContext(AuthContext)
    const headerBackgroundColor = !props.dataIsReady
        ? iOSColors.black
        : chelpers.pickCleaningStatusColor(area.cleaningStatus, area.occupancy)
    const cleaningActionIcon = chelpers.pickCleaningActionIcon(
        area.cleaningStatus,
        area.occupancy,
        area.cleaningFrequency,
        area.rules,
        false
    )

    const cleaningStatusIcon = chelpers.pickCleaningStatusIcon(area.cleaningStatus, false)
    const checkinIcon = chelpers.pickCheckinIcon(area.cleaningStatus, area.occupancy, false)
    const guestsIcon = chelpers.pickPeopleIcon(area.cleaningStatus, area.occupancy, area.guestCheckedIn, false)
    const pauseIcon = chelpers.pickPauseIcon(area.cleaningStatus, false)
    const inspectionIcon = chelpers.pickInspectionIcon(area.cleaningStatus, false)

    const ruleNumber = area.activeRule && area.activeRule.customChar ? area.activeRule.customChar : null

    const ICON_MARGIN_LEFT = 20

    const TOP_ROW_HEIGHT = 40
    const ICON_SCALE = 1.5

    const IS_TODAY = chelpers.isToday(selectedDateNumber)
    const DEBUG_BORDER = 0
    const isCheckotCheckinDay = false

    const SHOW_QR_BUTTON = isCheckotCheckinDay || (area.bookings && area.bookings[0])

    const renderDailyCommentIcon = area.dailyComments && area.dailyComments.length > 0

    const onDateChange = (date: number) => {
        setSelectedDateNumber(moment(date).startOf('date').valueOf())
    }

    const deleteDueDate = async () => {
        const task = currentTask

        const changeObj = {
            before: 'unassigned'
        }
        await areaData.logActivity(
            asFirebase(firebase),
            currentUser!,
            area.key,
            c.ACTIVITY_TYPE_DELETE_DUE_DATE,
            selectedDateNumber,
            changeObj
        )
        await taskData.deleteTask(asFirebase(firebase), task.key, currentUser!)
    }

    const bottomIcons = [
        ...(inspectionIcon
            ? [
                  <BoxIcon
                      key={'inspection-icon'}
                      source={inspectionIcon.source! as ImageSourcePropType}
                      width={inspectionIcon.width!}
                      height={inspectionIcon.height!}
                      iconScale={ICON_SCALE}
                  />
              ]
            : []),
        ...(pauseIcon
            ? [
                  <BoxIcon
                      key={'pause-icon'}
                      source={pauseIcon.source! as ImageSourcePropType}
                      width={pauseIcon.width!}
                      height={pauseIcon.height!}
                      iconScale={ICON_SCALE}
                  />
              ]
            : []),
        ...(renderDailyCommentIcon
            ? [
                  <BoxIcon
                      key={'daily-comment-icon'}
                      source={require('@shared/img/cleaning-status/daily-comment-white.png')}
                      width={17}
                      height={17}
                      iconScale={ICON_SCALE}
                  />
              ]
            : []),
        ...(checkinIcon
            ? [
                  <BoxIcon
                      key={'checkin-icon'}
                      source={checkinIcon.source! as ImageSourcePropType}
                      width={checkinIcon.width!}
                      height={checkinIcon.height!}
                      iconScale={ICON_SCALE}
                  />
              ]
            : []),
        ...(guestsIcon
            ? [
                  <BoxIcon
                      key={'guests-icon'}
                      source={guestsIcon.source!}
                      width={guestsIcon.width}
                      height={guestsIcon.height}
                      iconScale={ICON_SCALE}
                  />
              ]
            : []),
        ...(cleaningActionIcon
            ? [
                  <BoxIcon
                      key={'cleaning-action-icon'}
                      source={cleaningActionIcon.source! as ImageSourcePropType}
                      height={cleaningActionIcon.height!}
                      width={cleaningActionIcon.width!}
                      iconScale={ICON_SCALE}
                  />
              ]
            : []),
        ...(cleaningStatusIcon
            ? [
                  <BoxIcon
                      key={'cleaning-status-icon'}
                      source={cleaningStatusIcon.source!}
                      width={cleaningStatusIcon.width}
                      height={cleaningStatusIcon.height}
                      iconScale={ICON_SCALE}
                  />
              ]
            : []),
        ...(ruleNumber ? [<CircleChar key={'custom-icon'} char={ruleNumber} size={ICON_SCALE ? ICON_SCALE * 20 : 20} />] : [])
    ]

    return (
        <View
            style={{
                backgroundColor: headerBackgroundColor,
                opacity: props.dataIsReady ? 1 : 0.1,
                borderWidth: 0,
                borderColor: 'blue',

                paddingVertical: MODAL_TOP_TO_BUTTONS_BAR_VERTICAL_SPACE,
                paddingHorizontal: MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE
            }}>
            <View
                style={{
                    zIndex: 99,
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderWidth: DEBUG_BORDER,
                    borderColor: 'white',
                    flexDirection: 'row',
                    height: 40
                }}>
                <View
                    // @ts-ignore
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        columnGap: '25%',
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'white',
                        width: '30%',
                        height: TOP_ROW_HEIGHT,
                        marginBottom: 0
                    }}>
                    {IS_TODAY && <PriorityButton />}

                    {SHOW_QR_BUTTON && (
                        <GuestQrButton bookingId={isCheckotCheckinDay ? area.bookings[1].bookingId : area.bookings[0].bookingId} />
                    )}

                    <SubscriptionCheck
                        featureId="assign-housekeeping"
                        permissionId="assign-housekeeping-assign"
                        onAvailable={() => (
                            <HoverableImageButton
                                dimension={BUTTON_DIM}
                                hoverOpacity={HOVER_OPACITY}
                                imageSource={require('../../../img/assign-task-button-white-bkgr.svg')}
                                onPress={() => {
                                    setShowUsersModal(true)
                                }}
                                tooltipText={'Assign'}
                            />
                        )}
                        onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                            return (
                                <HoverableImageButton
                                    dimension={BUTTON_DIM}
                                    hoverOpacity={HOVER_OPACITY}
                                    imageSource={require('../../../img/assign-task-button-white-bkgr.svg')}
                                    onPress={() => {
                                        openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                    }}
                                    tooltipText={'Assign'}
                                />
                            )
                        }}
                    />
                </View>

                <View
                    style={{
                        borderWidth: DEBUG_BORDER,
                        width: '40%',
                        height: TOP_ROW_HEIGHT,
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row'
                    }}>
                    <View style={{ borderWidth: 0, display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <SubscriptionCheck
                            featureId="browse-history"
                            onAvailable={() => (
                                <DatePicker
                                    isWhite
                                    hasTodayButton
                                    selectedDateNumber={moment(selectedDateNumber).valueOf()}
                                    onDateChange={onDateChange}
                                />
                            )}
                            onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                                return (
                                    <DatePicker
                                        isWhite
                                        hasTodayButton
                                        selectedDateNumber={moment(selectedDateNumber).valueOf()}
                                        onDateChange={() => {
                                            openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                        }}
                                    />
                                )
                            }}
                        />
                    </View>
                </View>
                <View style={{ width: '30%', flexDirection: 'row', borderWidth: DEBUG_BORDER, justifyContent: 'flex-end' }} />
            </View>

            <View style={{ flexDirection: 'column', borderWidth: 0, borderColor: 'white', width: '100%' }}>
                <View
                    style={{
                        width: '100%',
                        borderWidth: 0,
                        borderColor: 'white',
                        justifyContent: 'space-between',
                        marginTop: 12
                    }}>
                    <AreaBredCrumbs
                        // @ts-ignore
                        spaceBetween={9}
                        area={area}
                        fontSize={19}
                        fontWeight={'500'}
                        // @ts-ignore
                        fontColor={iOSColors.white}
                        // @ts-ignore
                        arrowColor={iOSColors.white}
                    />
                </View>

                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',

                        borderWidth: 0,
                        marginTop: 12,
                        height: 28
                    }}>
                    <View>
                        {currentTask.key && currentTask.startDate && (
                            <>
                                <SubscriptionCheck
                                    featureId="assign-housekeeping"
                                    permissionId="assign-housekeeping-assign"
                                    onAvailable={() => (
                                        <DueDateButton
                                            dueDate={currentTask.startDate}
                                            inverse={true}
                                            onClick={deleteDueDate}
                                            selectedDate={selectedDateNumber}
                                        />
                                    )}
                                    onNotAvailable={() => {
                                        return null
                                    }}
                                />
                            </>
                        )}
                    </View>
                    {props.dataIsReady && (
                        <View
                            style={{
                                alignItems: 'center',
                                borderWidth: 0,
                                // height: LINE_HEIGHT,
                                borderColor: 'white',
                                flexDirection: 'row'

                                // justifyContent: 'flex-end'
                            }}>
                            {bottomIcons.map((icon, idx) => {
                                if (idx < bottomIcons.length - 1) {
                                    return (
                                        <div
                                            key={idx}
                                            style={{
                                                marginRight: ICON_MARGIN_LEFT
                                            }}>
                                            {icon}
                                        </div>
                                    )
                                }
                                return icon
                            })}
                        </View>
                    )}
                </View>
            </View>
        </View>
    )
}

export default Header

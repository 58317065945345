import React, { useEffect } from 'react'
import LoadingScreen from '../../LoadingScreen'
import SignUpLayout from '../../SignUpLayout'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { hostSetup } from '../../cloud-functions'
import { compose } from 'redux'
import withIntegrationWorkflow from '../../../../utils/hocs/withIntegrationWorkflow'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import { errorModalConfigs } from '../../helpers'
import { INTEGRATIONS } from '../../../../utils/constants'
import { getApi } from '../../frontend-api'
import firebase, { asFirebase } from '../../../../utils/firebase'
import { useFeatureToggle } from 'app/features'

const HostCallback = ({
    newOrgKey,
    catchError,
    signupComplete,
    workflowInstance,
    storeWorkflowInstance,
    setComplete,
    currentIntegrationKey,
    newUserKey,
    settings,
    message,
    setStopAuthListener
}) => {
    const { isFeatureOn } = useFeatureToggle()
    const useNewInitialImport = isFeatureOn('new-initial-import-procedure')

    const setupHostOld = async () => {
	console.info('Initial Host PMS Sync: Using old method')
        const apiKey = workflowInstance.getInput('apiKey')
        const setupHostIntegration = hostSetup()

        try {
            const { data } = await setupHostIntegration({
                orgId: newOrgKey,
                integration: INTEGRATIONS.HOST.toLowerCase(),
                signupPayload: {
                    organizationID: newOrgKey,
                    token: apiKey
                }
            })

            if (data.reason !== 'all good') {
                catchError(data.message, errorModalConfigs)
                return
            }

            console.log(workflowInstance)
            if ('test' in workflowInstance.values) {
                data.reason = 'test'
            }

            await signupComplete({ orgId: newOrgKey, userId: newUserKey, status: data })

            workflowInstance.completeStep(SIGN_UP.HOST_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupHostNew = async () => {
        console.info('Initial Host PMS Sync: Using new server-side method')
        try {
            const api = await getApi(asFirebase(firebase))
            const apiKey = workflowInstance.getInput('apiKey')

            await api['setup-org'].$post({
                json: {
                    newAdminUserKey: newUserKey,
                    orgId: newOrgKey,
                    integration: INTEGRATIONS.HOST.toLowerCase(),
                    isTest: 'test' in workflowInstance.values,
                    signupPayload: {
                        organizationID: newOrgKey,
                        token: apiKey
                    }
                }
            })

            workflowInstance.completeStep(SIGN_UP.HOST_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)
            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupHost = async () => await (useNewInitialImport ? setupHostNew() : setupHostOld())

    async function setup() {
        setStopAuthListener(true)
        await setupHost()
    }

    useEffect(() => {
        setup()
    }, [])

    return (
        <SignUpLayout withModal>
            <LoadingScreen message={message} />
        </SignUpLayout>
    )
}

export default compose(withIntegrationWorkflow, withErrorCatching)(HostCallback)

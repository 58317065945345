import React from 'react'
import * as c from '@shared/constants'
import { AreaStruct } from '@shared/dataObjects'
import { pickMinimizedWorkloadIcon } from '../../../../../utils/housekeeping-helpers'
import { pickCleaningStatusColor } from '@shared/cleaning-helpers'
import { GeneralWrap, HousekeepingWrap } from './style'
import IssueVisual from '../IssueVisual'

interface IMinimizedVisual {
    type: string
    area: Partial<AreaStruct>
    priority: boolean
    lastItemKey?: string | null
    lastThumbUrl?: string | null
    minimized: boolean
}

function MinimizedVisual({ type, area, priority, lastItemKey, lastThumbUrl, minimized }: IMinimizedVisual) {
    switch (type) {
        case c.TASK_TYPE_HOUSEKEEPING: {
            const { cleaningStatus, occupancy, cleaningFrequency } = area
            const iconObj = pickMinimizedWorkloadIcon(cleaningStatus, occupancy, cleaningFrequency, false)
            const fontSize = occupancy === c.OCCUPANCY_STAYOVER ? '11px' : '9px'
            const backgroundColor = pickCleaningStatusColor(cleaningStatus!, occupancy!)

            return (
                <HousekeepingWrap
                    marginTop={iconObj?.marginTop ?? 0}
                    marginBottom={iconObj?.marginBottom ?? 0}
                    marginLeft={iconObj?.marginLeft ?? 0}
                    // FIXME define types
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    marginRight={iconObj?.marginRight ?? 0}
                    extraFontSize={iconObj?.extraFontSize ?? 0}
                    color={iconObj?.color ?? 'white'}
                    fontSize={fontSize}
                    backgroundColor={backgroundColor}>
                    {iconObj && <i className={iconObj.name ?? ''} />}
                </HousekeepingWrap>
            )
        }

        case c.TASK_TYPE_GENERAL: {
            return (
                <GeneralWrap>
                    <i className="specta-checkmark text-dark" />
                </GeneralWrap>
            )
        }

        case c.TASK_TYPE_ISSUE: {
            return <IssueVisual minimized={minimized} priority={priority} lastItemKey={lastItemKey} lastThumbUrl={lastThumbUrl} />
        }

        default:
            return null
    }
}

export default MinimizedVisual

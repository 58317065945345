import { View, Image } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import React, { useContext } from 'react'
import { UNIT_HEADER_FONT_SIZE } from './consts'
import { TaskScreenContext } from './TaskScreenContext'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import { markdownToHtml } from '@shared/markdown-helper'

const BookingNotes = () => {
    const DEBUG_BORDER = 0
    const { bookingDisplay } = useContext(TaskScreenContext)
    const { currentUser, currentOrganization } = useContext(AuthContext)

    const showBookingNotes = () => {
        return !!(
            currentUser &&
            currentUser.showCleaningTaskNote &&
            bookingDisplay &&
            bookingDisplay.notes !== undefined &&
            bookingDisplay.notes !== null &&
            bookingDisplay.notes !== ''
        )
    }
    const isBookingHtml = bookingDisplay && /<[^>]+>/.test(markdownToHtml(bookingDisplay.notes)) && currentOrganization?._notesExpression

    return (
        <>
            {showBookingNotes() && (
                <View
                    style={{
                        display: 'flex',
                        flex: 1
                    }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',

                            borderWidth: DEBUG_BORDER,
                            borderColor: 'red',
                            marginBottom: 6
                        }}>
                        <View
                            style={{
                                width: 26,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderWidth: DEBUG_BORDER,
                                borderColor: 'red'
                            }}>
                            <Image
                                style={{ width: 17, height: 17.49 }}
                                source={require('@shared/img/cleaning-status/booking-note-black.svg')}
                            />
                        </View>
                        <span
                            style={{
                                marginTop: 2,
                                marginLeft: 4,
                                fontWeight: '600',
                                color: iOSColors.black,
                                fontSize: UNIT_HEADER_FONT_SIZE
                            }}>
                            Booking notes
                        </span>
                    </View>
                    <View style={{}}>
                        {isBookingHtml ? (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: markdownToHtml(bookingDisplay.notes)
                                }}
                                style={{
                                    // @ts-ignore
                                    paddingHorizontal: 32,
                                    fontSize: 14,
                                    color: iOSColors.gray,
                                    whiteSpace: 'pre-line'
                                }}
                            />
                        ) : (
                            <span
                                style={{
                                    // @ts-ignore
                                    paddingHorizontal: 32,
                                    fontSize: 14,
                                    color: iOSColors.gray,
                                    whiteSpace: 'pre-line'
                                }}>
                                {bookingDisplay.notes}
                            </span>
                        )}
                    </View>
                </View>
            )}
        </>
    )
}

export default BookingNotes

import {
    getWorkflowInstance,
    integrationStartUrl,
    storeWorkflowInstance,
    removeWorkflowInstance,
    safeGetWorkflowInstance
} from '../../utils/signup-flow-template/signup-workflow-instance'
import { currentIntegrationKey } from './signup-worflow-templates'
import { StartWorkflowParams } from './types'

export const errorModalConfigs = {
    button: {
        text: 'Try again',
        onClick: () => {
            const URL = integrationStartUrl?.()?.url
            removeWorkflowInstance(currentIntegrationKey)
            integrationStartUrl?.()?.remove()

            if (URL) {
                window.location.href = URL
            } else {
                console.warn('Did not find a URL for try again modal')
            }
        }
    }
}

export const startWorkflow = (
    { bus, imageUrl, workflowTemplate, currentStep, history }: StartWorkflowParams,
    brand = null,
    reconnect = false
) => {
    removeWorkflowInstance(currentIntegrationKey)

    const params = new URLSearchParams(history.location.search)
    let workflowInstance = safeGetWorkflowInstance(currentIntegrationKey)

    const settings: Record<string, string | boolean> = {}
    params.forEach((value, key) => (settings[key] = value))

    if (workflowInstance) {
        const reconnecting = workflowInstance.getInput('reconnect') === true
        if (!reconnecting) {
            // cleanup unrelated pre-existing instance
            removeWorkflowInstance(currentIntegrationKey)
            workflowInstance = workflowTemplate.start()
        }
    } else {
        workflowInstance = workflowTemplate.start()
    }

    settings.bus = bus
    settings.imageUrl = imageUrl
    reconnect && (settings.reconnect = reconnect)
    brand && (settings.brand = brand)

    workflowInstance.completeStep(currentStep, settings)
    storeWorkflowInstance(currentIntegrationKey, workflowInstance)
    integrationStartUrl(history.location.pathname + history.location.search)

    const nextStep = workflowInstance.nextStep()
    if (nextStep) {
        history.push(nextStep)
    } else {
        throw new Error('Did not push next step to history because workflow complete')
    }
}

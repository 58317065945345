import React from 'react'
import { Image, TouchableOpacity, View } from 'react-native-web'
import Select from 'react-select'

import { iOSColors, iOSUIKit } from '@shared/react-native-typography'

import { Toast } from '../utils/toast'
import * as colors from '@shared/colors'
import * as c from '@shared/constants'
import * as chelpers from '@shared/cleaning-helpers'

import { notify } from 'react-notify-toast'
import { occupancyToHuman } from '@shared/get-booking-display'

const FONT_SIZE = 15

function pickOccupancyImage(occupancy) {
    if (occupancy === c.OCCUPANCY_CHECKIN) {
        return { width: c.ICON_CHECKIN.width, height: c.ICON_CHECKIN.height, source: c.IMG_CLEANING_TASK_CHECK_IN_BLACK }
    } else if (occupancy === c.OCCUPANCY_STAYOVER) {
        return { width: c.ICON_TIDY_ROOM.width, height: c.ICON_TIDY_ROOM.height, source: c.IMG_CLEANING_TASK_TIDY_ROOM_BLACK }
    } else if (occupancy === c.OCCUPANCY_STAYOVER_80) {
        return { width: c.ICON_PREPARE_ROOM.width, height: 14, source: c.IMG_CLEANING_TASK_PREPARE_ROOM_BLACK }
    } else if (occupancy === c.OCCUPANCY_CHECKOUT) {
        return {
            width: c.ICON_CHECKOUT_CLEANING.width,
            height: c.ICON_CHECKOUT_CLEANING.height,
            source: c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_BLACK
        }
    } else if (occupancy === c.OCCUPANCY_TURNOVER) {
        return { width: 13, height: 18, source: require('../img/turnover.svg') }
    } else {
        return { width: 16, height: 16, source: c.IMG_WAS_FUR_EIN }
    }
}

const Option = props => {
    props.data.contex = 'option'
    return (
        <TouchableOpacity style={{ fontFamily: 'Inter' }} onPress={() => props.selectOption(props.data)}>
            <Label data={props.data} context={'option'} />
        </TouchableOpacity>
    )
}

const Label = ({ data, context }) => {
    if (data.data) {
        data = data.data
    }
    let MARGIN_HORIZONTAL = 8
    let PADDING_HORIZONTAL = 10
    let BORDER_COLOR = 'green'
    let BORDER_BOTTOM_WIDTH = 1
    let ICON_MARGIN_TOP = 1
    let MARGIN_TOP = 0
    if (context === 'label') {
        MARGIN_HORIZONTAL = 0
        PADDING_HORIZONTAL = 0
        BORDER_COLOR = 'yellow'
        BORDER_BOTTOM_WIDTH = 0
        ICON_MARGIN_TOP = 1
        MARGIN_TOP = -4
    }

    const img = pickOccupancyImage(data.value)

    let TURNOVER_MARGIN_BOTTOM = 0
    if (data.value === c.OCCUPANCY_TURNOVER) {
        TURNOVER_MARGIN_BOTTOM = 6
    }
    return (
        <View
            key={data.value}
            style={{ flexDirection: 'row', alignItems: 'center', marginLeft: MARGIN_HORIZONTAL, marginRight: MARGIN_HORIZONTAL }}>
            <View style={{ width: '100%', borderBottomWidth: BORDER_BOTTOM_WIDTH, borderBottomColor: '#F4F5F6' }}>
                <View
                    style={{
                        marginTop: MARGIN_TOP,
                        height: 40,
                        borderColor: BORDER_COLOR,
                        borderWidth: 0,
                        justifyContent: 'center'
                    }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            borderWidth: 0,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingHorizontal: PADDING_HORIZONTAL
                        }}>
                        <span
                            style={{
                                fontFamily: 'Circular Std',
                                borderWidth: 0
                            }}>
                            {data.label}
                        </span>
                        <View
                            style={{
                                marginLeft: 9,
                                marginTop: ICON_MARGIN_TOP,
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                            <Image
                                source={img.source}
                                style={{
                                    width: img.width,
                                    height: img.height,
                                    marginBottom: TURNOVER_MARGIN_BOTTOM
                                }}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default class OccupancySelect extends React.Component {
    constructor(props) {
        super(props)

        this.selectStyles = {
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            control: styles => ({
                ...styles,
                width: this.props.width ?? 240,
                height: this.props.height ?? 40,

                borderWidth: 1,
                borderColor: colors.textInputBorder,
                borderRadius: 6,
                boxShadow: 'none',
                ':hover': {
                    borderWidth: 1,
                    borderColor: colors.green_spectaflow,
                    borderRadius: 4
                }
            }),

            valueContainer: styles => ({
                ...styles,
                display: 'flex'
            }),

            placeholder: () => [
                iOSUIKit.subhead,
                {
                    paddingLeft: 8,
                    color: iOSColors.midGray,
                    fontSize: FONT_SIZE
                }
            ],

            singleValue: () => [
                {
                    paddingLeft: 6,
                    color: iOSColors.black,
                    fontSize: FONT_SIZE
                }
            ],
            indicatorSeparator: styles => ({
                ...styles,
                width: 0
            })
        }

        this.occupancyList = [
            { label: occupancyToHuman(c.OCCUPANCY_VACANT), value: c.OCCUPANCY_VACANT },
            { label: occupancyToHuman(c.OCCUPANCY_CHECKIN), value: c.OCCUPANCY_CHECKIN },
            { label: occupancyToHuman(c.OCCUPANCY_STAYOVER), value: c.OCCUPANCY_STAYOVER },
            { label: occupancyToHuman(c.OCCUPANCY_STAYOVER), value: c.OCCUPANCY_STAYOVER_80 },
            { label: occupancyToHuman(c.OCCUPANCY_CHECKOUT), value: c.OCCUPANCY_CHECKOUT },
            { label: occupancyToHuman(c.OCCUPANCY_TURNOVER), value: c.OCCUPANCY_TURNOVER }
        ]
    }

    getOccupancyItem(occupancy) {
        const result = this.occupancyList.filter(el => el.value === occupancy)
        return result[0]
    }

    getSelectedLabel(option) {
        return <Label data={option} context={'label'} />
    }

    render() {
        const selectedItem = this.getOccupancyItem(this.props.occupancy)

        const ICON_MARGIN_TOP = 1
        if (
            chelpers.isToday(this.props.selectedDateNumber || (!chelpers.isToday(this.props.selectedDateNumber) && !this.props.area.synced))
        ) {
            return (
                <Select
                    getOptionLabel={this.getSelectedLabel}
                    value={selectedItem}
                    placeholder="Select  status..."
                    maxMenuHeight={180}
                    isSearchable={false}
                    components={{ Option }}
                    options={this.occupancyList}
                    styles={this.selectStyles}
                    onChange={this.props.onChange}
                    menuPortalTarget={document.body}
                />
            )
        } else {
            const img = pickOccupancyImage(this.props.occupancy)
            return (
                <TouchableOpacity
                    onPress={() => {
                        notify.show(
                            <Toast
                                width={180}
                                backgroundColor={colors.yello_spectaflow}
                                message="You can not change occupancy for future or past dates!"
                            />,
                            'custom',
                            3000,
                            {}
                        )
                    }}
                    style={{
                        flexDirection: 'row',
                        width: this.props.width,
                        height: this.props.height,
                        borderRadius: 4,
                        borderWidth: 1,
                        borderColor: colors.gentle_gray_spectaflow,
                        alignItems: 'center'
                    }}>
                    <span
                        style={{
                            paddingLeft: 12,
                            fontSize: 15
                        }}>
                        {occupancyToHuman(this.props.occupancy)}
                    </span>
                    {(this.props.occupancy == c.OCCUPANCY_CHECKIN ||
                        this.props.occupancy == c.OCCUPANCY_STAYOVER ||
                        this.props.occupancy == c.OCCUPANCY_STAYOVER_80 ||
                        this.props.occupancy == c.OCCUPANCY_CHECKOUT) && (
                        <View
                            style={{
                                marginLeft: 9,
                                marginTop: ICON_MARGIN_TOP,
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                            <Image
                                source={img.source}
                                style={{
                                    width: img.width,
                                    height: img.height
                                }}
                            />
                        </View>
                    )}
                </TouchableOpacity>
            )
        }
    }
}

import React, { useMemo } from 'react'
import { Circle, Day, Date } from './style'
import { CleaningScheduledDate } from 'app/modules/HousekeepingDashboard/types'
import { pickGuestCleaningActionIcon } from '@shared/cleaning-helpers'
import moment from 'moment-timezone'
import * as c from '@shared/constants'
import { Occupancy, AreaCleaningStatus } from '@shared/firestore-structs'
import { iOSColors } from '@shared/react-native-typography'
import * as colors from '@shared/colors'

interface Props {
    scheduledDate: CleaningScheduledDate
    onClick: (date: number) => void
}

function DateCircle({ scheduledDate: { date, cleaningStatus, occupancy }, onClick }: Props) {
    const backgroundColor = getBubbleColor(occupancy, cleaningStatus)
    const { DAY, DATE } = useMemo(() => {
        return {
            DAY: moment(date).format('ddd'),
            DATE: moment(date).format('DD')
        }
    }, [date])
    const FIRST_OR_LAST = useMemo(() => [c.OCCUPANCY_TURNOVER, c.OCCUPANCY_CHECKIN, c.OCCUPANCY_CHECKOUT].includes(occupancy), [occupancy])
    const IS_PAST = useMemo(() => date < moment().startOf('day').valueOf(), [date])
    const DISABLED = useMemo(() => IS_PAST || FIRST_OR_LAST, [IS_PAST, FIRST_OR_LAST])

    const cleaningActionIcon = useMemo(() => {
        const ICON_SCALER = 1
        const cleaningActionIcon = pickGuestCleaningActionIcon(occupancy)
        if (!cleaningActionIcon?.source || FIRST_OR_LAST) return null
        return (
            <img
                style={{
                    width: (cleaningActionIcon?.width ?? 16) * ICON_SCALER,
                    height: (cleaningActionIcon?.height ?? 16) * ICON_SCALER,
                    alignSelf: 'center'
                }}
                src={cleaningActionIcon.source}
            />
        )
    }, [cleaningStatus, occupancy])

    return (
        <Circle role="button" onClick={() => !DISABLED && onClick(date)} disabled={DISABLED} backgroundColor={backgroundColor}>
            <Day>{DAY}</Day>
            <Date>{DATE}</Date>
            {cleaningActionIcon}
        </Circle>
    )
}

export function getBubbleColor(occupancy: Occupancy, cleaningStatus: AreaCleaningStatus) {
    if (occupancy === c.OCCUPANCY_CHECKIN) {
        return iOSColors.green
    }
    switch (cleaningStatus) {
        case c.CLEANING_STATUS_CLEAN:
            return iOSColors.green
        case c.CLEANING_STATUS_DIRTY:
        case c.CLEANING_STATUS_PREPARING:
        case c.CLEANING_STATUS_PREPARING_PAUSE:
        case c.CLEANING_STATUS_INSPECTION:
        case c.CLEANING_STATUS_DO_NOT_DISTURB:
        case c.CLEANING_STATUS_OUT_OF_SERVICE:
            return colors.pink_spectaflow
        case c.CLEANING_STATUS_NO_SERVICE:
            return colors.teal_sweeply
        case c.CLEANING_STATUS_WAITING_FOR_CHECKOUT:
            return colors.purple_spectaflow
        default:
            return colors.bs_gray_500
    }
}

export default DateCircle

import React, { useEffect } from 'react'
import SignUpLayout from '../../SignUpLayout/index'
import LoadingScreen from '../../LoadingScreen/index'
import { SIGN_UP, SITE_URL } from '../../../../navigation/url-constants'
import { cloudbedsSetup } from '../../cloud-functions'
import { errorModalConfigs } from '../../helpers'
import { compose } from 'redux'
import withIntegrationWorkflow from '../../../../utils/hocs/withIntegrationWorkflow'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import { INTEGRATIONS } from '../../../../utils/constants'
import firebase, { asFirebase } from '../../../../utils/firebase'
import { useFeatureToggle } from '../../../../features'
import { getApi } from '../../frontend-api'

const CloudbedsCallback = ({
    newOrgKey,
    catchError,
    signupComplete,
    workflowInstance,
    storeWorkflowInstance,
    setComplete,
    currentIntegrationKey,
    newUserKey,
    settings,
    message,
    setStopAuthListener
}) => {
    const { isFeatureOn } = useFeatureToggle()
    const useNewInitialImport = isFeatureOn('new-initial-import-procedure')
    const redirectUrl = SITE_URL + SIGN_UP.CLOUDBEDS_CALLBACK

    const setupCloudbedsOld = async () => {
        console.info('Initial Cloudbeds PMS Sync: Using old method')
        try {
            const setupCloudbedsIntegration = cloudbedsSetup()

            const { data } = await setupCloudbedsIntegration({
                orgId: newOrgKey,
                integration: INTEGRATIONS.CLOUDBEDS,
                version: 'v2',
                signupPayload: {
                    code: settings.code,
                    organizationID: newOrgKey,
                    redirect: redirectUrl
                }
            })

            if (data.reason !== 'all good') {
                catchError(data.message, errorModalConfigs)
                return
            }

            if ('test' in workflowInstance.values) {
                data.reason = 'test'
            }

            await signupComplete({ orgId: newOrgKey, userId: newUserKey, status: data })

            workflowInstance.completeStep(SIGN_UP.CLOUDBEDS_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupCloudbedsNew = async () => {
        console.info('Initial Cloudbeds PMS Sync: Using new server-side method')
        try {
            const api = await getApi(asFirebase(firebase))

            await api['setup-org'].$post({
                json: {
                    newAdminUserKey: newUserKey,
                    orgId: newOrgKey,
                    integration: INTEGRATIONS.CLOUDBEDS,
                    version: 'v2',
                    signupPayload: {
                        code: settings.code,
                        organizationID: newOrgKey,
                        redirect: redirectUrl
                    }
                }
            })

            workflowInstance.completeStep(SIGN_UP.CLOUDBEDS_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)
            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupCloudbeds = async () => (useNewInitialImport ? await setupCloudbedsNew() : await setupCloudbedsOld())

    async function setup() {
        setStopAuthListener(true)
        await setupCloudbeds()
    }

    useEffect(() => {
        setup()
    }, [])

    return (
        <SignUpLayout withModal>
            <LoadingScreen message={message} />
        </SignUpLayout>
    )
}

export default compose(withIntegrationWorkflow, withErrorCatching)(CloudbedsCallback)

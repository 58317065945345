import React, { useContext, useState, useEffect } from 'react'
import clsx from 'clsx'
import { Dropdown } from 'react-bootstrap'
import { ActivityIndicator } from 'react-native-web'
import { DropdownTopbarItemToggler } from '../../../../_metronic/_partials/dropdowns'
import { AuthContext } from '../../Auth/AuthContext'
import firebase from '../../../utils/firebase'
import * as brand from '@shared/brand'
import { Tooltip } from '../../../components/misc/Tooltip'

import { sortByName } from '@shared/helpers'
import { getCurrentUserOrganizations, changeOrganizationSetup } from '../../OrganizationSignUp/cloud-functions'

export function OrgSelectorDropdown() {
    const { organization, setLoadingAuthState } = useContext(AuthContext)
    const [organizations, setOrganizations] = useState([organization])
    const [changeOrgLoading, setChangeOrgLoading] = useState(false)

    useEffect(() => {
        const getOrgs = async () => {
            const getUserOrganizations = getCurrentUserOrganizations()
            let { data: orgs } = getUserOrganizations({ filter: ['active', 'suspended'] })

            orgs = orgs.sort((a, b) => sortByName(a.organizationName, b.organizationName))
            setOrganizations(orgs)
        }

        getOrgs()
    }, [])

    return (
        <>
            {changeOrgLoading && <ActivityIndicator size="small" color={brand.getBrand().navBarColor} style={{}} />}
            <Dropdown drop="down" alignRight data-testid="org-selector">
                <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-my-cart">
                    <Tooltip text="Switch Account" position="bottom">
                        <div className="btn btn-icon text-hover-specta-main btn-dropdown btn-lg mr-1">
                            <span className="symbol symbol-circle symbol-30 mr-3">
                                <span className="symbol-label font-size-base font-weight-bolder text-white">
                                    <i className={`menu-icon specta-org-group icon-16px`} />
                                </span>
                            </span>
                        </div>
                    </Tooltip>
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
                    <ul className="navi navi-hover py-4" style={{ width: '15rem', maxHeight: '75vh', overflowY: 'scroll' }}>
                        {organizations.map(org => (
                            <li
                                key={org.key + org.organizationName}
                                data-testid="my-org"
                                className={clsx('navi-item', {
                                    active: org.organizationKey === organization.key
                                })}>
                                <a
                                    href="#"
                                    onClick={async () => {
                                        try {
                                            const changeOrgCloudFunc = changeOrganizationSetup()
                                            setLoadingAuthState(true)
                                            setChangeOrgLoading(true)
                                            document.body.click()

                                            const result = await changeOrgCloudFunc({ organizationKey: org.organizationKey })
                                            if (result.data !== null) {
                                                document.location.reload()
                                            }
                                        } catch (error) {
                                            console.error('error', error)
                                        }
                                    }}
                                    className={clsx('navi-link', {
                                        active: org.organizationKey === organization.key
                                    })}>
                                    {org.status === 'active' && <span className="navi-text">{org.organizationName}</span>}
                                    {org.status === 'suspended' && <span className="navi-text">{org.organizationName} [SUSPENDED]</span>}
                                </a>
                            </li>
                        ))}
                    </ul>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

import React, { useEffect } from 'react'
import LoadingScreen from '../../LoadingScreen'
import SignUpLayout from '../../SignUpLayout'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { oracleSetup } from '../../cloud-functions'
import { compose } from 'redux'
import withIntegrationWorkflow from '../../../../utils/hocs/withIntegrationWorkflow'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import { errorModalConfigs } from '../../helpers'
import { INTEGRATIONS } from '../../../../utils/constants'
import { getApi } from '../../frontend-api'
import firebase, { asFirebase, asFirestore, db } from '../../../../utils/firebase'
import { useFeatureToggle } from 'app/features'

const OracleCallback = ({
    newOrgKey,
    catchError,
    signupComplete,
    workflowInstance,
    storeWorkflowInstance,
    setComplete,
    currentIntegrationKey,
    newUserKey,
    settings,
    message,
    setStopAuthListener
}) => {
    const { isFeatureOn } = useFeatureToggle()
    const useNewInitialImport = isFeatureOn('new-initial-import-procedure')

    const setupOracleOld = async () => {
        console.info('Initial Oracle PMS Sync: Using old method')
        const hostName = workflowInstance.getInput('hostName')
        const clientId = workflowInstance.getInput('clientId')
        const clientSecret = workflowInstance.getInput('clientSecret')
        const username = workflowInstance.getInput('username')
        const password = workflowInstance.getInput('password')
        const hotelId = workflowInstance.getInput('hotelId')
        const appKey = workflowInstance.getInput('appKey')
        const externalSystemCode = workflowInstance.getInput('externalSystemCode')

        const setupOracleIntegration = oracleSetup()

        try {
            const { data } = await setupOracleIntegration({
                orgId: newOrgKey,
                integration: INTEGRATIONS.ORACLE.toLowerCase(),
                signupPayload: {
                    organizationID: newOrgKey,
                    hostName,
                    clientId,
                    clientSecret,
                    username,
                    password,
                    hotelId,
                    applicationKey: appKey,
                    externalSystemCode
                }
            })

            if (data.reason !== 'all good') {
                catchError(data.message, errorModalConfigs)
                return
            }

            console.log(workflowInstance)
            if ('test' in workflowInstance.values) {
                data.reason = 'test'
            }

            await signupComplete({ orgId: newOrgKey, userId: newUserKey, status: data })

            workflowInstance.completeStep(SIGN_UP.ORACLE_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupOracleNew = async () => {
        console.info('Initial Oracle PMS Sync: Using new server-side method')
        try {
            const hostName = workflowInstance.getInput('hostName')
            const clientId = workflowInstance.getInput('clientId')
            const clientSecret = workflowInstance.getInput('clientSecret')
            const username = workflowInstance.getInput('username')
            const password = workflowInstance.getInput('password')
            const hotelId = workflowInstance.getInput('hotelId')
            const appKey = workflowInstance.getInput('appKey')
            const externalSystemCode = workflowInstance.getInput('externalSystemCode')
            const api = await getApi(asFirebase(firebase))

            await api['setup-org'].$post({
                json: {
                    newAdminUserKey: newUserKey,
                    orgId: newOrgKey,
                    integration: INTEGRATIONS.ORACLE.toLowerCase(),
                    isTest: 'test' in workflowInstance.values,
                    signupPayload: {
                        organizationID: newOrgKey,
                        hostName,
                        clientId,
                        clientSecret,
                        username,
                        password,
                        hotelId,
                        applicationKey: appKey,
                        externalSystemCode
                    }
                }
            })

            workflowInstance.completeStep(SIGN_UP.ORACLE_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)
            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupOracle = async () => (useNewInitialImport ? await setupOracleNew() : await setupOracleOld())

    async function setup() {
        setStopAuthListener(true)
        await setupOracle()
    }

    useEffect(() => {
        setup()
    }, [])

    return (
        <SignUpLayout withModal>
            <LoadingScreen message={message} />
        </SignUpLayout>
    )
}

export default compose(withIntegrationWorkflow, withErrorCatching)(OracleCallback)

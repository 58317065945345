import { getBrand } from '@shared/brand'

export const SETTINGS = {
    INDEX: '/settings',

    ACCOUNT: {
        INDEX: '/settings/account',
        INFORMATION: '/settings/account/information',
        SUBSCRIPTION: '/settings/account/subscription',
        BILLING: '/settings/account/billing',
        ISSUES: '/settings/account/issues'
    },

    UNITS: {
        INDEX: '/settings/units',
        NEW: '/settings/units/new',
        EDIT: {
            INDEX: '/settings/units/:id/edit',
            GO_TO: (id: string) => `/settings/units/${id}/edit`
        },
        DELETE: {
            INDEX: '/settings/units/:id/delete',
            GO_TO: (id: string) => `/settings/units/${id}/delete`
        },
        DELETE_AREAS: '/settings/units/deleteAreas'
    }
}

export const SIGN_UP = {
    ORG: '/signup/org',
    NO_PMS_START: '/signup/start',
    NO_PMS_CALLBACK: '/signup/callback',
    WELCOME: '/signup/welcome',
    APALEO_START: '/signup/apaleo/start',
    APALEO_AUTHORIZE: '/signup/apaleo/authorize',
    APALEO_CALLBACK: '/signup/apaleo/callback',
    APALEO_RECONNECT: '/signup/apaleo/reconnect',
    RENTLIO_START: '/signup/rentlio/start',
    RENTLIO_AUTHORIZE: '/signup/rentlio/authorize',
    RENTLIO_CALLBACK: '/signup/rentlio/callback',
    BEDS_24_CALLBACK: '/signup/beds24/callback',
    BEDS_24_CONFIG: '/signup/beds24/config',
    BEDS_24_START: '/sign-up',
    BEDS_24_V2_START: '/signup/beds24/start',
    BEDS_24_V2_AUTHORIZE: '/signup/beds24/authorize',
    BEDS_24_V2_CALLBACK: '/signup/beds24/callback',
    BOOKING_FACTORY_START: '/signup/bookingfactory/start',
    BOOKING_FACTORY_SETUP: '/signup/bookingfactory/setup',
    MEWS_START: '/signup/mews/start',
    MEWS_SETUP: '/signup/mews/setup',
    CLOUDBEDS_START: '/signup/cloudbeds/start',
    CLOUDBEDS_AUTHORIZE: '/signup/cloudbeds/authorize',
    CLOUDBEDS_CALLBACK: '/signup/cloudbeds/callback',
    GUESTY_START: '/signup/guesty/start',
    GUESTY_AUTHORIZE: '/signup/guesty/authorize',
    GUESTY_SETUP: '/signup/guesty/setup',
    HOST_START: '/signup/host/start',
    HOST_CALLBACK: '/signup/host/callback',
    HOST_AUTHORIZE: '/signup/host/authorize',
    GUESTLINE_START: '/signup/guestline/start',
    GUESTLINE_AUTHORIZE: '/signup/guestline/authorize',
    GUESTLINE_CALLBACK: '/signup/guestline/callback',
    ORACLE_START: '/signup/oracle/start',
    ORACLE_AUTHORIZE: '/signup/oracle/authorize',
    ORACLE_CALLBACK: '/signup/oracle/callback',
    BLUE_LAGOON_START: '/signup/bluelagoon/start',
    BLUE_LAGOON_AUTHORIZE: '/signup/bluelagoon/authorize',
    BLUE_LAGOON_CALLBACK: '/signup/bluelagoon/callback'
}

export const LOGIN = {
    INDEX: '/login',
    EMAIL_CALLBACK: '/login/email/callback',
    LOGOUT: '/logout',
    EMAIL_PASS: '/login/emailpass'
}

export const HOUSEKEEPING = {
    INDEX: '/housekeeping',
    ONBOARDING: '/housekeeping?onboarding',
    INDEX_NEW: '/housekeeping-new'
}

export const TASKBOARD = {
    INDEX: '/taskboard',
    APALEO: '/integrations/apaleo/taskboard'
}

export const ISSUES = {
    INDEX: '/issues'
}

export const SITE_URL =
    window.location.hostname === 'localhost' ? `${window.location.protocol}//${window.location.host}` : getBrand().firebase?.urls.webappSite

export const getCurrentEnv = () => {
    if (location.hostname === 'localhost') return 'local'
    if (getBrand().key === 'sweeply') return 'prod'
    return 'dev'
}

export const RENTLIO_GET_API_KEY = 'https://app.rentl.io/app/developers/api-keys'
export const GUESTY_API_KEY = 'https://app.guesty.com/integrations/partners/sweeply'
export const BEDS_24_GET_CODE = 'https://beds24.com/control3.php?pagetype=apiv2'

export const FOOTER = {
    RELEASE_NOTES: 'https://help.getsweeply.com/en/collections/3622150-release-notes',
    PRIVACY_POLICY: 'https://getsweeply.com/privacy-policy',
    TERMS: 'https://getsweeply.com/terms'
}

export const REPORTS = {
    INDEX: '/reports',
    ISSUES: '/reports/issues',
    HOUSEKEEPING: '/reports/housekeeping',
    OPT_IN: '/reports/optional',
    WORKLOAD: '/reports/workload'
}

export const HELP = 'https://help.getsweeply.com'

export const GUESTS = {
    LOGIN: {
        INDEX: '/guest/:orgKey/login',
        GO_TO: (orgKey: string) => `/guest/${orgKey}/login`
    },
    GUEST_DATA: '/guest/data',
    QR_MAGIC_LINK: '/guest/qr-magic-link',
    LOGIN_WITH_TOKEN: '/guest/login-with-token'
}

export const USERS = {
    LOGIN: 'acme://login'
}

export const APALEO = {
    AUTHORIZE: (redirectUrl: string) =>
        `https://identity.apaleo.com/connect/authorize?response_type=code&scope=${encodeURIComponent(
            `offline_access operations.change-room-state maintenances.read reservations.read setup.read integration:ui-integrations.manage`
        )}&client_id=UBLQ-AC-SWEEPLY&redirect_uri=${encodeURIComponent(redirectUrl)}`,
    CONNECTED_APPS: 'https://app.apaleo.com/apps/connected-apps'
}

export const FEATURES_QUERY_PARAMS = {
    OLD_APALEO: 'old-apaleo',
    PMS_RECONNECT: 'pms-reconnect'
}

export const PMS_RECONNECT_LINK = (pms: string) => `/signup/${pms}/reconnect`

import { Row } from 'react-flexbox-grid'
import { Image, View } from 'react-native-web'
import { iOSColors } from '@shared/react-native-typography'
import React from 'react'

const NoUnits = ({ isDataReady, areas }: { isDataReady: boolean; areas: any[] }) => {
    let text = 'Fetching units...'
    if (isDataReady && areas.length === 0) {
        text = 'No areas found'
    }
    const IMG_SCALE = 1.2
    if (areas.length > 0) {
        return <></>
    }
    return (
        <Row
            center="xs"
            middle="xs"
            style={{
                marginTop: 50
            }}>
            <View testID="no-areas" style={{ alignItems: 'center' }}>
                <Image
                    style={{ opacity: 0.8, width: 148 * IMG_SCALE, height: 121 * IMG_SCALE }}
                    source={require('@shared/img/no-areas-hotel-bed.svg')}
                />
                <span
                    style={{
                        marginTop: 6,
                        fontSize: 20,
                        fontWeight: '500',
                        color: iOSColors.gray
                    }}>
                    {text}
                </span>
                {isDataReady && (
                    <span
                        style={{
                            marginTop: 12,
                            fontSize: 15,
                            fontWeight: '400',
                            color: iOSColors.gray
                        }}>
                        Try a new search or
                    </span>
                )}
                {isDataReady && (
                    <span
                        style={{
                            marginTop: 9,
                            fontSize: 15,
                            fontWeight: '400',
                            color: iOSColors.gray
                        }}>
                        change the filters
                    </span>
                )}
            </View>
        </Row>
    )
}

export default NoUnits

import { useContext, useEffect, useState } from 'react'
import { clone } from 'ramda'
import firebase, { asFirebase } from '../../utils/firebase'
import { AreaStruct, AreaSummaryProjectionStruct, HousekeepingOverviewProjectionStruct, RuleStruct } from '@shared/firestore-structs'
import { AuthContext } from '../../modules/Auth/AuthContext'
import { useCollectionDataOnce, useDocumentData, useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { getAreasQuery2, getAreaSummaryQuery, getProjectionQuery, getRulesQuery } from '@shared/queries/data'
import moment, { Moment } from 'moment-timezone'
import { DashboardContext } from './DashboardContext'
import { filterAreas } from './helpers'
import { TaskScreenContext } from './details-modal/TaskScreenContext'
import { mapToAreaProjection } from '@shared/projections/v1/mapToAreaProjection'
import { areaOverview } from '@shared/projections/v1/areaOverview'

interface Floors {
    [floorKey: string]: {
        [areaKey: string]: Partial<AreaStruct>
    }
}

export const getBookingDates = (checkinDate: Moment, checkoutDate: Moment) => {
    const dates: string[] = []
    while (checkinDate <= checkoutDate) {
        dates.push(moment(checkinDate).valueOf().toString())
        checkinDate.add(1, 'days')
    }
    return dates
}

const flattenFloors = (floors: HousekeepingOverviewProjectionStruct, areasSnapshot: AreaStruct[]): Array<Partial<AreaStruct>> => {
    // replacing the projected area groups for the day with the current area groups and removing duplicate areas
    const result = Object.entries(floors).reduce(
        (acc, [floorKey, areasOnFloor]) =>
            Object.entries(areasOnFloor).reduce((acc, [areaKey, area]) => {
                const group = areasSnapshot.find(a => a.key === areaKey)?.group ?? 'unknown'
                if (acc[areaKey] && floorKey !== group) return acc
                return {
                    ...acc,
                    [areaKey]: {
                        ...clone((area as unknown) as AreaStruct),
                        group: group,
                        key: areaKey
                    }
                }
            }, acc as { [areaKey: string]: Partial<AreaStruct> }),
        {} as { [areaKey: string]: Partial<AreaStruct> }
    )

    return Object.values(result)
}
// const asNativeQuery = <T extends FirebaseFirestoreTypes.DocumentSnapshot>(firebase2: DocumentReference<T>) => {
//     return (firebase2 as unknown) as FirebaseFirestoreTypes.DocumentReference<T>
// }

// export function useTaskboardProjectionFirestore(selectedDateNumber: number) {
//     const [loading, setLoading] = useState(false)
//     const { currentOrganization, currentUser } = useContext(AuthContext)
//     const [_areas, _setAreas] = useState<Partial<AreaStruct>[]>([])
//
//     if (!currentUser) {
//         return { isLoading: false, areas: [], isDataReady: false, error: undefined, refresh: () => Promise.resolve() }
//     }
//     const [floors, loadingFloors, errorFloors] = useDocumentData<HousekeepingOverviewProjectionStruct>(
//         getProjectionQuery(asFirebase(firebase), currentOrganization!.key, selectedDateNumber) as any
//     )
//
//     const [isDataReady, setIsDataReady] = useState(false)
//
//     const DATA_READY = !!floors || !!currentOrganization
//     const LOADING = loadingFloors
//     const ERROR = errorFloors
//
//     useEffect(() => {
//         if (!LOADING) {
//             const areas = flattenFloors(floors || {}, _areas)
//
//             // const areasFiltered = filterAndSearchAreas(areas, '', CLEANING_STATUS_ALL, CLEANING_STATUS_ALL, null, currentUser, null)
//
//             _setAreas(areas)
//             // _setAreas(areasFiltered)
//             setIsDataReady(true)
//             setLoading(false)
//         }
//     }, [LOADING, DATA_READY, floors])
//
//     useEffect(() => {
//         if (ERROR) {
//             console.log('ERROR', ERROR)
//         }
//     }, [ERROR])
//
//     return { isLoading: LOADING, areas: _areas, isDataReady, error: ERROR }
// }
function useProjectionFirestore() {
    const {
        searchValue,
        cleaningStatusFilter,
        occupancyFilter,
        priorityFilter,
        areaGroupsFilter,
        assignModeFilter,
        setPriorityCount,
        selectedDateNumber,
        setAllAreaGroups,
        setLightAreaSummaries,
        setRules,
        setFullSetAreas,
        setAreaCount
    } = useContext(DashboardContext)
    const [loading, setLoading] = useState(false)
    const { currentOrganization, currentUser } = useContext(AuthContext)
    const [_areas, _setAreas] = useState<Partial<AreaStruct>[]>([])

    if (!currentUser) {
        return { isLoading: false, areasProjections: [], isDataReady: false, error: undefined, refresh: () => Promise.resolve() }
    }
    const [floors, loadingFloors, errorFloors] = useDocumentData<HousekeepingOverviewProjectionStruct>(
        getProjectionQuery(asFirebase(firebase), currentOrganization!.key, moment(selectedDateNumber).startOf('day').valueOf())
    )
    const [rules, loadingRules, errorRules] = useCollectionDataOnce<RuleStruct>(
        getRulesQuery(asFirebase(firebase), currentOrganization!.key)
    )
    const [areas, loadingAreas, errorAreas] = useDocumentDataOnce<{ areas: AreaStruct[] }>(
        getAreasQuery2(asFirebase(firebase), currentOrganization!.key)
    )

    const fullSetAreas = areas?.areas.filter(a => a.displayCleaningStatus)
    const [isDataReady, setIsDataReady] = useState(false)

    const DATA_READY = !!floors && !!currentOrganization && !!fullSetAreas && !!rules
    const LOADING = loadingFloors && loadingAreas && loadingRules
    const ERROR = errorFloors || errorAreas || errorRules

    const fullSetAreaKeys = fullSetAreas?.map(a => a.key) ?? []

    function mutateAreaData(areasFiltered: Partial<any>[]) {
        for (const area of areasFiltered) {
            if (area.extras && area.extras.length > 0) {
                const bookingExtras = area.extras.filter((x: any) => x.date === selectedDateNumber)
                area.extra = bookingExtras && bookingExtras.map((x: { quantity: any; name: any }) => `${x.quantity}x ${x.name}`).join(', ')
            }
            if (area.dailyComments && area.dailyComments.length > 0) {
                area.dailyComment = area.dailyComments[0].comment
            }
            if (area.currentTask) {
                area.task = area.currentTask
            }
            if (area.leadBooking) {
                area.leadBooking.bookingDates = getBookingDates(
                    moment(area.leadBooking.checkinDate).startOf('day'),
                    moment(area.leadBooking.checkoutDate).startOf('day')
                )
            }
            area.bookings = area.leadBooking ? [area.leadBooking] : []
        }
        return areasFiltered
    }

    useEffect(() => {
        const run = async () => {
            if (!LOADING) {
                const areas = flattenFloors(floors || {}, fullSetAreas!).filter(a => fullSetAreaKeys.includes(a.key!))
                setFullSetAreas(fullSetAreas)
                const liveProjections = fullSetAreas?.filter((a: AreaStruct) => !areas.find(x => x.key === a.key))
                const lightAreas =
                    liveProjections?.map(area => {
                        const summary = mapToAreaProjection({
                            area: clone(area),
                            startOfDayInTZ: selectedDateNumber,
                            org: currentOrganization!,
                            bookings: [],
                            activities: [],
                            rules: rules!,
                            currentTask: undefined,
                            dailyComments: [],
                            lastCleaningTask: null
                        })
                        return {
                            overview: areaOverview({
                                area,
                                leadBooking: null,
                                updatedArea: summary.updatedArea,
                                currentTask: undefined,
                                isPriority: false,
                                areaSummary: summary.result,
                                dailyComments: []
                            }),
                            summary: summary
                        }
                    }) ?? []

                const areasFiltered = filterAreas(
                    areas.concat(lightAreas.map(l => flattenFloors(l.overview, fullSetAreas!)[0])),
                    currentUser,
                    searchValue,
                    occupancyFilter,
                    assignModeFilter,
                    cleaningStatusFilter,
                    priorityFilter
                )

                const lightFloors = lightAreas.map(l => Object.keys(l.overview)[0])
                const ag = Object.keys(floors ?? {}).concat(lightFloors)

                const mutatedAreas = mutateAreaData(areasFiltered)

                setAllAreaGroups([...new Set(ag)])
                _setAreas(mutatedAreas)
                setLightAreaSummaries(lightAreas.map(l => l.summary.result))
                setRules(rules || [])
                setIsDataReady(true)
                setLoading(false)
            }
        }
        if (DATA_READY) {
            run()
        }
    }, [
        LOADING,
        DATA_READY,
        searchValue,
        cleaningStatusFilter,
        occupancyFilter,
        priorityFilter,
        areaGroupsFilter,
        selectedDateNumber,
        floors
    ])

    useEffect(() => {
        if (ERROR) {
            console.error('ERROR', ERROR)
        }
    }, [ERROR])

    return { isLoading: LOADING, areasProjections: _areas, isDataReady, error: ERROR }
}

export function useAreaSummaryFirestore(areaKey: string) {
    const { fullSetAreas, rules } = useContext(DashboardContext)
    const { currentOrganization } = useContext(AuthContext)
    const { selectedDateNumber } = useContext(TaskScreenContext)
    const [result, setResult] = useState<AreaSummaryProjectionStruct | undefined>(undefined)
    const [loaded, setLoaded] = useState(false)

    const [areaSummary, loading, error] = useDocumentData<AreaSummaryProjectionStruct>(
        getAreaSummaryQuery(asFirebase(firebase), currentOrganization!.key, selectedDateNumber, areaKey)
    )

    useEffect(() => {
        const run = () => {
            if (!loading && areaSummary) {
                setResult(areaSummary)
                setLoaded(true)
            } else if (!loading && !areaSummary) {
                const area = (fullSetAreas.filter((a: AreaStruct) => a.key === areaKey) as AreaStruct[]) ?? []
                if (area.length !== 1) {
                    setLoaded(true)
                    setResult(undefined)
                    return
                }
                const result = mapToAreaProjection({
                    area: clone(area[0]),
                    startOfDayInTZ: selectedDateNumber,
                    org: currentOrganization!,
                    bookings: [],
                    activities: [],
                    rules: rules!,
                    currentTask: undefined,
                    dailyComments: [],
                    lastCleaningTask: null
                })
                // debugger
                setResult(result.result)
                setLoaded(true)
            } else {
                setLoaded(false)
                setResult(undefined)
            }
        }
        run()
    }, [areaSummary, loading])

    return { isLoading: loaded, areaSummary: result }
}

export default useProjectionFirestore

import { db } from '../../../../../utils/firebase'
import { httpsCallable } from '../../../../../api'

export const getPlans = async brandName => {
    const plansQuery = await db.collection('plans').where('visible', '==', true).get()
    const plans = plansQuery.docs.map(item => {
        return { key: item.id, ...item.data() }
    })

    return plans
}

export const getEstimates = async (planItemIds, currencyCode, subscriptionId, quantity, prorate) => {
    const call = httpsCallable('getEstimates')
    return await call({
        planIds: planItemIds,
        currencyCode: currencyCode,
        subscriptionId: subscriptionId,
        quantity,
        prorate
    })
        .then(result => {
            return result.data.filter(element => {
                if (Object.keys(element).length !== 0) {
                    return true
                }
                return false
            })
        })
        .catch(error => {
            console.error('error', error)
        })
}

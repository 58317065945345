/* eslint-disable func-names */
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './components/tippy.css'
import { AuthProvider } from './modules/Auth/AuthContext'

import { Routes } from './routes'
import { MaterialThemeProvider, MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider } from '../_metronic/layout'
import { IntercomProvider } from 'react-use-intercom'
import { getBrand } from '@shared/brand'
import { RecoilRoot } from 'recoil'
import { useIsMobile } from './utils/hooks/useIsMobile'
import ContentBlocker from './components/ContentBlocker'
import { useAsyncStorage } from '@react-native-community/async-storage'

export function App() {
    const isMobile = useIsMobile()
    const { getItem, setItem } = useAsyncStorage('forceDesktop')
    const [forceDesktop, setForceDesktop] = React.useState(false)
    React.useEffect(() => {
        getItem().then(value => {
            setForceDesktop(value === 'true')
        })
    }, [])
    location.search.match(/forceDesktop=true/) && setItem('true')
    const isGuestsPage = location.pathname.includes('/guest')
    if (isMobile && !forceDesktop && !isGuestsPage) return <ContentBlocker />
    return (
        <>
            <RecoilRoot>
                <AuthProvider>
                    <MetronicLayoutProvider>
                        <MetronicSubheaderProvider>
                            <MetronicSplashScreenProvider>
                                <IntercomProvider appId={getBrand().intercomAppId}>
                                    <BrowserRouter>
                                        <MaterialThemeProvider>
                                            {/* <ErrorBoundary> */}
                                            <Routes />
                                            {/* </ErrorBoundary> */}
                                        </MaterialThemeProvider>
                                    </BrowserRouter>
                                </IntercomProvider>
                            </MetronicSplashScreenProvider>
                        </MetronicSubheaderProvider>
                    </MetronicLayoutProvider>
                </AuthProvider>
            </RecoilRoot>
        </>
    )
}

export default App

import { httpsCallable } from '../../api'

import { IntegrationParams, TaskboardContext } from '@shared/traces-types'
import {
    ApaleoSignupPayload,
    Beds24SignupPayload,
    BlueLagoonSignupPayload,
    BookingFactorySignupPayload,
    CloudbedsSignupPayload,
    GetUserOrgs,
    GuestlinePMSSignupPayload,
    GuestyPMSSignupPayload,
    HostPMSSignupPayload,
    MewsSignupPayload,
    OraclePMSSignupPayload,
    PMSSignUpStatus,
    RemovePMSRequest,
    RentlioPMSSignupPayload,
    SignupOrganizationPayload,
    SignupPMSRequest
} from '../../../../../cloud/functions/pms/pms-client-contracts'
export interface HttpsCallableResult<R = any> {
    readonly data: R
}

export interface HttpsCallable<I = any, O = any> {
    (data?: I): Promise<HttpsCallableResult<O>>
}

export type IntegrationsParamsRoute = 'getReservation' | 'getProperty'

export type IntegrationsParamsPayload<T extends IntegrationsParamsRoute> = T extends 'getReservation'
    ? { reservationId: string }
    : T extends 'getProperty'
    ? { propertyId: string }
    : never

export type GetIntegrationsParamsPayload = {
    route: IntegrationsParamsRoute
    payload: IntegrationsParamsPayload<IntegrationsParamsRoute>
}

export function signupOrganizationCall(): HttpsCallable<SignupOrganizationPayload> {
    return httpsCallable('signupOrganization')
}

export function beds24setup(): HttpsCallable<SignupPMSRequest<Beds24SignupPayload>, PMSSignUpStatus> {
    return httpsCallable('setupOrganizationPMSSync', true)
}

export function hostSetup(): HttpsCallable<SignupPMSRequest<HostPMSSignupPayload>, PMSSignUpStatus> {
    return httpsCallable('setupOrganizationPMSSync', true)
}

export function apaleoSetup(): HttpsCallable<SignupPMSRequest<ApaleoSignupPayload>, PMSSignUpStatus> {
    return httpsCallable('setupOrganizationPMSSync', true)
}

export function rentlioSetup(): HttpsCallable<SignupPMSRequest<RentlioPMSSignupPayload>, PMSSignUpStatus> {
    return httpsCallable('setupOrganizationPMSSync', true)
}

export function bookingFactorySetup(): HttpsCallable<SignupPMSRequest<BookingFactorySignupPayload>, PMSSignUpStatus> {
    return httpsCallable('setupOrganizationPMSSync', true)
}

export function mewsSetup(): HttpsCallable<SignupPMSRequest<MewsSignupPayload>, PMSSignUpStatus> {
    return httpsCallable('setupOrganizationPMSSync', true)
}

export function beds24remove(): HttpsCallable<RemovePMSRequest, void> {
    return httpsCallable('removeOrganizationPMSSync')
}

export function noPMSSetup(): HttpsCallable<SignupPMSRequest<void>, void> {
    return httpsCallable('setupOrganizationPMSSync', true)
}

export function cloudbedsSetup(): HttpsCallable<SignupPMSRequest<CloudbedsSignupPayload>, PMSSignUpStatus> {
    return httpsCallable('setupOrganizationPMSSync', true)
}

export function guestySetup(): HttpsCallable<SignupPMSRequest<GuestyPMSSignupPayload>, PMSSignUpStatus> {
    return httpsCallable('setupOrganizationPMSSync', true)
}

export function guestlineSetup(): HttpsCallable<SignupPMSRequest<GuestlinePMSSignupPayload>, PMSSignUpStatus> {
    return httpsCallable('setupOrganizationPMSSync', true)
}

export function oracleSetup(): HttpsCallable<SignupPMSRequest<OraclePMSSignupPayload>, PMSSignUpStatus> {
    return httpsCallable('setupOrganizationPMSSync', true)
}

export function bluelagoonSetup(): HttpsCallable<SignupPMSRequest<BlueLagoonSignupPayload>, PMSSignUpStatus> {
    return httpsCallable('setupOrganizationPMSSync', true)
}

export function getIntegrationParamsSetup<T extends TaskboardContext.RESERVATIONS | TaskboardContext.PROPERTY>(): HttpsCallable<
    GetIntegrationsParamsPayload,
    IntegrationParams<T>
> {
    return httpsCallable('integration')
}

export function reconnectOrganizationPMSSetup(): HttpsCallable<SignupPMSRequest<any>, PMSSignUpStatus> {
    return httpsCallable('reconnectOrganizationPMSSync')
}

export function getApaleoOrganizationSetup(): HttpsCallable<{ accountCode: string; propertyId?: string }, string> {
    return httpsCallable('getApaleoOrg')
}

export function changeOrganizationSetup(): HttpsCallable<{ organizationKey: string }, void> {
    return httpsCallable('userChangeOrganization')
}

export function getCurrentUserOrganizations(): HttpsCallable<
    { organizationKey: string[]; filter?: ('active' | 'suspended')[] },
    GetUserOrgs
> {
    return httpsCallable('getCurrentUserOrganizations')
}

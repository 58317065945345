import React from 'react'
import { ActivityIndicator, FlatList, Image, TextInput, View } from 'react-native-web'
import moment from 'moment-timezone'
import Modal from 'react-modal'
import Notifications, { notify } from 'react-notify-toast'

import * as styles from '../housekeeping/dashboard/styles'
import * as hhelpers from '@shared/hashtag-helpers'
import { iOSColors } from '@shared/react-native-typography'
import { onIssue } from '@shared/issue-data'
import UsersList from '../users/users-list'
import firebase, {
    DocumentData,
    DocumentReference,
    asFirebase,
    deleteIssue,
    getCommentivities,
    deleteItemFromIssue,
    tasksRepo
} from '../utils/firebase'
import * as taskData from '@shared/task-data'
import PhotoItemDetail from './photo-item-detail'
import CommentivityRow from './commentivity-row'

import * as helpers from '@shared/helpers'
import { getStatusImage } from '@shared/getStatusImage'
import * as c from '@shared/constants'
import * as uc from '../utils/constants'
import * as colors from '@shared/colors'
import * as brand from '@shared/brand'
import * as issueData from '@shared/issue-data'
import { Toast } from '../utils/toast'

import AssigneesView from '../components/assignees-view'

import { CommentInput } from './comment-input'
import { HoverableImageButton } from '../components/buttons/hoverable-image-button'
import { PhotoItem } from './photo-item'

import { ButtonDatePicker } from '../components/button-date-picker'
import { AreaBredCrumbs } from '../components/area-bread-crumbs'
import { CloseModalButton } from '../components/buttons/close-modal-button'

import IssueHashtagsSelect from '../components/hashtags-select'
import { DueDateButton } from '../components/buttons/DueDateButton'

import { FeatureLockedOverlay, SubscriptionCheck } from '../components/upgrade-signals'
import compressImages from './compress-images'
import Compress from 'compress.js'
import ImagesDropBox from '../components/images-dropbox'
import Spinner from '../components/Spinner'
import { toast } from 'react-toastify'
import { toastErrorStyle, toastSuccessStyle } from '../utils/styles'
import { CommentivityStruct, IssueItem, IssueStruct, OrgStruct, TaskStatus as TS, TaskStruct, UserStruct } from '@shared/firestore-structs'
import AlertPortal from 'app/components/alerts/AlertPortal'
import * as issueHelpers from './helpers'
import { getArea } from '@shared/area-data'

interface AIssueModalProps {
    issue?: IssueStruct
    respWidth: number
    respHeight: number
    issueHashtags?: string[]
    editable?: boolean
    currentUser: UserStruct | null
    currentOrganization: OrgStruct | null
    closeModal: () => void
    sharedIssueStyle?: {
        mobile: boolean
    }
    unassignDisabled?: boolean
    updateTaskStatus?: (task: string, status: TS) => void
}

type AIssueModalState = {
    alertOnConfirm: () => void
    alertText: string
    alertTitle: string
    commentInputValue: string
    dueDateNumber: number | null | undefined
    isImagesLoading: boolean | undefined
    isPhotoItemModalOpen: boolean
    isTextInputActive: boolean
    issue: IssueStruct
    issueHashtags: string[]
    issueName: string | null
    nameInputActive: boolean
    priority: boolean | null
    respHeight: number
    respWidth: number
    selectUserModalIsOpen: boolean
    showConfirmationAlert: boolean
    selectedItem: IssueItem | null
    status: TS | null
    task: Partial<TaskStruct> | null
    textInputValue: string
    commentivity: CommentivityStruct[] | null
    photoItems: IssueItem[]
}
export default class AIssueModal extends React.Component<AIssueModalProps, AIssueModalState> {
    myRef: React.RefObject<unknown>
    issueHashtags: string[] = helpers.getHashTags(this.props.issue ? this.props.issue.name : '')
    textInputValue: string = helpers.cleanHashTags(this.props.issue ? this.props.issue.name : '')
    oldIssueName: string | undefined
    IS_EDITABLE = false
    UNIT_HEADER_FONT_SIZE = 17
    state: AIssueModalState = {
        dueDateNumber: 0,
        selectedItem: null,
        alertOnConfirm: () => null,
        alertText: '',
        alertTitle: '',
        commentInputValue: '',
        commentivity: null,
        isImagesLoading: this.props.issue!.isImagesLoading,
        isPhotoItemModalOpen: false,
        isTextInputActive: false,
        issue: this.props.issue!,
        issueHashtags: this.issueHashtags,
        issueName: this.props.issue ? this.props.issue.name : null,
        nameInputActive: false,
        photoItems: [],
        priority: this.props.issue ? this.props.issue.priority : null,
        respHeight: this.props.respHeight,
        respWidth: this.props.respWidth,
        selectUserModalIsOpen: false,
        showConfirmationAlert: false,
        status: this.props.issue ? this.props.issue.status : null,
        task: null,
        textInputValue: ''
    }
    unsubscribe: (() => void) | null = null
    issueRef: DocumentReference<DocumentData> | null = null
    clearStatusTimout: number | null = null
    clearPriorityTimout: number | null = null
    clearTimoutRef = null
    showCommentToast?: boolean

    constructor(props: AIssueModalProps) {
        super(props)

        this.myRef = React.createRef()

        this.closeConfirmationAlert = this.closeConfirmationAlert.bind(this)
        this.onDeleteIssuePress = this.onDeleteIssuePress.bind(this)
        this.onDeleteItemPress = this.onDeleteItemPress.bind(this)
        this.onPriorityPress = this.onPriorityPress.bind(this)

        this.updatePriority = this.updatePriority.bind(this)
        this.submitIssueName = this.submitIssueName.bind(this)
        this.onSubmitComment = this.onSubmitComment.bind(this)
        this.closePhotoItemModal = this.closePhotoItemModal.bind(this)
        this.onIssueChange = this.onIssueChange.bind(this)
        this.setIssueItems = this.setIssueItems.bind(this)

        this.openPhotoItemModal = this.openPhotoItemModal.bind(this)

        this.renderActionBar = this.renderActionBar.bind(this)
        this.renderPriorityButton = this.renderPriorityButton.bind(this)
        this.renderStatusButton = this.renderStatusButton.bind(this)

        this.renderInfoHeader = this.renderInfoHeader.bind(this)
        this.renderCommentsInput = this.renderCommentsInput.bind(this)

        this.openSelectUserModal = this.openSelectUserModal.bind(this)
        this.closeSelectUserModal = this.closeSelectUserModal.bind(this)

        this.onUserListAssign = this.onUserListAssign.bind(this)

        this.onCompletePress = this.onCompletePress.bind(this)
        this.onReusePress = this.onReusePress.bind(this)

        this.onIssueHashtagSelectChange = this.onIssueHashtagSelectChange.bind(this)
        this.issueHashtags = helpers.getHashTags(this.props.issue ? this.props.issue.name : '')
        this.textInputValue = helpers.cleanHashTags(this.props.issue ? this.props.issue.name : '')

        this.renderDeveloperSection = this.renderDeveloperSection.bind(this)

        this.setDueDate = this.setDueDate.bind(this)
        this.deleteDueDate = this.deleteDueDate.bind(this)
        this.setImages = this.setImages.bind(this)
    }

    closePhotoItemModal() {
        this.setState({ isPhotoItemModalOpen: false })
    }

    componentDidMount() {
        this.oldIssueName = this.props.issue?.name

        this.IS_EDITABLE = this.props.editable !== false
        this.UNIT_HEADER_FONT_SIZE = 17

        if (this.state.issue) {
            const issue = this.state.issue

            if (this.IS_EDITABLE) {
                this.unsubscribe = onIssue(asFirebase(firebase), issue.key).onSnapshot(this.onIssueChange.bind(this))
            } else {
                this.setIssueItems(issue)
            }

            if (!issue.lastItemKey) {
                if (issue.items && issue.items.length === 0) {
                    const itemObject = {
                        key: 'no-file',
                        filename: 'nofile',
                        thumbUrl: null
                    }

                    // TODO: fix the missing values
                    this.setState({ photoItems: [(itemObject as unknown) as IssueItem] })
                }
            }
            if (issue.taskKey) {
                tasksRepo
                    .getTask(issue.taskKey)
                    .then(task => {
                        this.setState({ task: task, dueDateNumber: task!.startDate })
                    })
                    .catch(error => {
                        console.log('error', error)
                    })
            }
            if (issue.dueDate) {
                this.setState({ dueDateNumber: issue.dueDate })
            }
        }
    }

    setIssueItems(issueObject: IssueStruct) {
        let items = issueObject.items
        const commentivityArray = issueObject.commentivities
        if (issueObject) {
            items = items.filter(item => item.visible === undefined || item.visible)
            items.sort((a, b) => helpers.sortTimeStampDescending(b.updated, a.updated))

            commentivityArray.sort((a, b) => b.created - a.created)
        }

        this.setState({
            commentivity: commentivityArray,
            photoItems: items
        })
    }

    async onIssueChange(issue: IssueStruct) {
        if (issue) {
            issue.items = await issueData.getIssueItems(asFirebase(firebase), issue.key)

            const commentivityObjects = await getCommentivities(asFirebase(firebase), issue.key)
            issue.commentivities = Object.keys(commentivityObjects).map(commentivityKey => commentivityObjects[commentivityKey])
            this.setIssueItems(issue)
        }
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe()
        }
    }

    closeConfirmationAlert() {
        this.setState({ showConfirmationAlert: false })
    }

    onDeleteItemPress(item: { key: string }) {
        const alertOnConfirm = () => {
            deleteItemFromIssue(asFirebase(firebase), {
                issueKey: this.state.issue.key,
                itemKey: item.key,
                currentUser: this.props.currentUser!,
                isLastItem: this.state.photoItems.length === 1
            })

            this.closeConfirmationAlert()
        }

        this.setState({
            alertTitle: 'Deleting',
            alertText: 'Are you sure you want to delete this photo?',
            alertOnConfirm,
            showConfirmationAlert: true
        })
    }

    onDeleteIssuePress() {
        const alertOnConfirm = () => {
            deleteIssue(asFirebase(firebase), { issueKey: this.state.issue.key, currentUser: this.props.currentUser! })
            this.closeConfirmationAlert()
            this.props.closeModal()
        }

        this.setState({
            alertTitle: 'Deleting',
            alertText: 'Are you sure you want to delete this issue?',
            alertOnConfirm,
            showConfirmationAlert: true
        })
    }

    onReusePress() {
        let nextStatus: TS
        if (this.state.issue.assignedContacts && this.state.issue.assignedContacts.length > 0) {
            nextStatus = c.ISSUE_ASSIGNED
        } else {
            nextStatus = c.ISSUE_OPEN
        }
        this.setState({ status: nextStatus, issue: { ...this.state.issue, status: nextStatus } })

        issueData.uncompleteIssue(asFirebase(firebase), {
            issueKey: this.state.issue.key,
            currentUser: this.props.currentUser!,
            assignedContacts: this.state.issue.assignedContacts,
            taskKey: this.state.issue?.taskKey
        })
    }

    updatePriority() {
        this.clearTimoutRef = null

        issueData.setPriorityToIssue(asFirebase(firebase), {
            issueKey: this.state.issue.key,
            currentUser: this.props.currentUser!,
            priority: this.state.priority!
        })
    }

    onPriorityPress() {
        this.setState({ priority: !this.state.priority })

        if (this.clearPriorityTimout) {
            clearTimeout(this.clearPriorityTimout)
        }
        this.clearPriorityTimout = window.setTimeout(this.updatePriority, 1000)
    }

    openPhotoItemModal(item: IssueItem) {
        if (item.url) {
            Image.getSize(item.url, (width, height) => {
                const isMobile = this.props.sharedIssueStyle && this.props.sharedIssueStyle.mobile
                let itemViewHeight = height > this.state.respHeight ? this.state.respHeight : height
                itemViewHeight = isMobile ? itemViewHeight - 60 : itemViewHeight
                itemViewHeight = this.props.sharedIssueStyle ? itemViewHeight - 50 : itemViewHeight
                const heightRatio = height / itemViewHeight

                styles.photoItemModalStyle.content.height = String(itemViewHeight)
                styles.photoItemModalStyle.content.width = String(width / heightRatio + 20)

                this.setState({ selectedItem: item, isPhotoItemModalOpen: true })
            })
        }
    }

    openSelectUserModal() {
        this.setState({ selectUserModalIsOpen: true })
    }

    closeSelectUserModal() {
        this.setState({
            selectUserModalIsOpen: false
        })
    }

    async onUserListAssign(assignedUsers: UserStruct[]) {
        this.closeSelectUserModal()

        let assignedTo = ''
        const assignedContacts = assignedUsers.map(assignee => {
            assignedTo += assignee.name + ', '
            return {
                email: assignee.email ? assignee.email : '',
                key: assignee.key,
                name: assignee.name,
                initials: assignee.initials,
                phoneNumber: assignee.phoneNumber ? assignee.phoneNumber : '',
                isUser: true,
                appOS: assignee.appOS ? assignee.appOS : 'unknown'
            }
        })
        if (assignedTo !== '') {
            assignedTo = assignedTo.slice(0, -2)
        }

        try {
            const result = await issueData.assignIssue(asFirebase(firebase), {
                area: this.state.issue.area,
                currentUser: this.props.currentUser!,
                issueKey: this.state.issue.key,
                assignedTo: assignedContacts,
                dueDate: this.state.issue.dueDate,
                taskKey: this.state.task?.key
            })

            this.setState({
                issue: { ...this.state.issue, ...result.issue },
                task: { ...this.state.task, ...result.task },
                dueDateNumber: result.issue.dueDate
            })
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : 'Error assigning issue, please try again'
            toast.error(message, toastErrorStyle)
        }

        const assigneeNames = assignedContacts.map(assignee => assignee.name).join(', ')
        const successMessage = 'Issue assigned to ' + assigneeNames

        this.setState({
            status: c.ISSUE_ASSIGNED,
            issue: {
                ...this.state.issue,
                assignedTo,
                assignedContacts,
                status: c.ISSUE_ASSIGNED
            },
            task: { ...this.state.task, assignedTo: assignedUsers, assignedToKeys: assignedUsers.map(user => user.key) }
        })

        toast.success(successMessage, toastSuccessStyle)
    }

    render() {
        if (this.state.issue === null || this.state.photoItems === null) {
            return (
                <View
                    style={{
                        height: '100%',
                        backgroundColor: 'white',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <ActivityIndicator color={brand.getBrand().navBarColor} />
                </View>
            )
        }

        const side_margin = this.props.sharedIssueStyle ? 20 : 40

        const reuse_view_margin = 16

        const DEBUG_BORDER = 0

        const SECTION_MARGIN_VERTICAL = 24
        const SECTION_MARGIN_VERTICAL_SMALL = 24 - 8

        const defaultIssueHashtags = this.props.currentOrganization ? this.props.currentOrganization.issueHashtags : ['']

        return (
            <View
                style={{
                    height: '100%',
                    borderColor: 'red',
                    borderWidth: 0
                }}>
                {this.IS_EDITABLE && (
                    <SubscriptionCheck
                        featureId="issues"
                        onAvailable={() => null}
                        onNotAvailable={(notAccessibleFeature, availableUpgrades) => (
                            <FeatureLockedOverlay
                                topMargin={0}
                                gradientPower={'1%'}
                                notAccessibleFeature={notAccessibleFeature}
                                availableUpgrades={availableUpgrades}
                                onClose={this.props.closeModal}
                            />
                        )}
                    />
                )}

                <Notifications options={{ animationDuration: 850, zIndex: 99999, top: 0 }} />

                {this.state.showConfirmationAlert && (
                    <AlertPortal
                        title={this.state.alertTitle}
                        text={this.state.alertText}
                        onConfirm={this.state.alertOnConfirm}
                        onCancel={this.closeConfirmationAlert}
                    />
                )}

                <Modal
                    style={styles.photoItemModalStyle}
                    isOpen={this.state.isPhotoItemModalOpen}
                    onRequestClose={this.closePhotoItemModal}
                    contentLabel="Conctact">
                    <PhotoItemDetail
                        sharedIssueStyle={this.props.sharedIssueStyle}
                        editable={this.props.sharedIssueStyle === undefined}
                        onDeleteItemPress={this.onDeleteItemPress}
                        issueKey={this.state.issue.key}
                        currentUser={this.props.currentUser!}
                        item={this.state.selectedItem!}
                        closeModal={this.closePhotoItemModal}
                        respWidth={styles.photoItemModalStyle.content.width}
                        respHeight={styles.photoItemModalStyle.content.height}
                    />
                </Modal>

                <Modal
                    isOpen={this.state.selectUserModalIsOpen}
                    onRequestClose={this.closeSelectUserModal}
                    style={styles.userListStyle}
                    contentLabel="User">
                    <UsersList
                        task={this.state.task}
                        closeModal={this.closeSelectUserModal}
                        currentUser={this.props.currentUser}
                        onAssignPress={this.onUserListAssign}
                        areaGroups={[this.state.issue.area.group]}
                    />
                </Modal>
                <View
                    style={{
                        zIndex: 1000,
                        backgroundColor: colors.white,
                        position: 'sticky' as any,
                        top: 0,
                        marginTop: 0,
                        left: 0,
                        borderWidth: 0,
                        paddingVertical: uc.MODAL_TOP_TO_BUTTONS_BAR_VERTICAL_SPACE
                    }}>
                    {this.IS_EDITABLE && <CloseModalButton close={this.props.closeModal} />}
                    {this.renderActionBar(DEBUG_BORDER, side_margin, reuse_view_margin)}
                </View>

                {this.renderInfoHeader(DEBUG_BORDER, side_margin)}
                {this.IS_EDITABLE && (
                    <View
                        style={{
                            zIndex: 1,
                            marginHorizontal: side_margin,
                            borderWidth: 0,
                            marginTop: SECTION_MARGIN_VERTICAL_SMALL
                        }}>
                        <IssueHashtagsSelect
                            width={'100%'}
                            defaultHashtags={defaultIssueHashtags}
                            hashtags={this.state.issueHashtags}
                            isDisabled={false}
                            onChange={this.onIssueHashtagSelectChange}
                        />
                    </View>
                )}
                {this.IS_EDITABLE && this.state.issue.assignedContacts && (
                    <View style={{ marginTop: SECTION_MARGIN_VERTICAL }}>{this.renderAssignedTo(this.state.issue, side_margin)}</View>
                )}

                <View style={{ marginTop: SECTION_MARGIN_VERTICAL_SMALL }}>{this.renderPhotoItems(side_margin)}</View>

                <View style={{ marginTop: SECTION_MARGIN_VERTICAL_SMALL }}>
                    {this.state.commentivity && this.renderCommentivity(side_margin)}
                </View>
                {/* {this.props.currentUser && this.props.currentUser.authSuper && (
                    <View
                        style={{
                            borderColor: 'red',
                            borderWidth: DEBUG_BORDER,
                            flexDirection: 'row',
                            paddingHorizontal: uc.MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE,
                            marginBottom: uc.MODAL_SECTION_VERTICAL_SPACE
                        }}>
                        {this.renderDeveloperSection()}
                    </View>
                )} */}
                {this.IS_EDITABLE && (
                    <View
                        style={{
                            position: 'sticky' as any,
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'white',
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 0,
                            height: 48,
                            alignItems: 'flex-end'
                        }}>
                        {this.state.commentivity && this.renderCommentsInput(side_margin)}
                    </View>
                )}
            </View>
        )
    }

    onIssueHashtagSelectChange(issueHashtags: string[]) {
        this.textInputValue = helpers.cleanHashTags(this.textInputValue)
        this.issueHashtags = issueHashtags
        this.submitIssueName()
        this.setState({ issueHashtags })
    }

    renderActionBar(DEBUG_BORDER: number, side_margin: number, reuse_view_margin: number) {
        DEBUG_BORDER = 0

        const issue = this.state.issue
        const subscription = this.props.currentOrganization!.subscription

        if (issue === null || this.state.photoItems === null) {
            return null
        }

        const isIssueDeleted = issue.status === c.ISSUE_DELETED
        const isIssueCompleted = issue.status === c.ISSUE_COMPLETE
        const isSubscriptionBasic = subscription === 'basic'

        const disabled = isIssueDeleted || isIssueCompleted || isSubscriptionBasic
        const disabledOpacity = disabled ? 0.5 : 1

        return (
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderWidth: DEBUG_BORDER,
                    borderColor: 'blue',
                    height: 40,
                    alignItems: 'center',
                    marginHorizontal: side_margin
                }}>
                <View
                    style={{
                        width: '33%',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'green'
                    }}>
                    {this.renderPriorityButton(disabled, disabledOpacity)}
                    {this.IS_EDITABLE && (
                        <HoverableImageButton
                            dimension={uc.BUTTON_DIM}
                            hoverOpacity={uc.HOVER_OPACITY}
                            imageSource={require('../img/assign-task-button.svg')}
                            onPress={this.openSelectUserModal}
                            tooltipText={'Assign'}
                            disabledOpacity={disabledOpacity}
                            disabled={disabled}
                        />
                    )}
                    {this.IS_EDITABLE && (
                        <ButtonDatePicker
                            selectedDateNumber={this.state.dueDateNumber ? this.state.dueDateNumber : moment().startOf('day').valueOf()}
                            onDateChange={this.setDueDate}
                            disabledOpacity={disabledOpacity}
                            disabled={disabled}
                            tooltipDistance={-30}
                            placement={'bottom-start'}
                        />
                    )}
                    {this.IS_EDITABLE && this.renderStatusButton(disabled, disabledOpacity)}
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'red',
                        width: '30%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <View
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: reuse_view_margin,
                            width: uc.SMALL_BUTTON_DIM,
                            height: uc.SMALL_BUTTON_DIM,
                            borderWidth: DEBUG_BORDER,
                            borderColor: 'red'
                        }}
                    />

                    {this.IS_EDITABLE && (
                        <span style={{ fontWeight: '600', fontSize: 15, color: helpers.getIssueNumberColor(this.state.status!) }}>
                            {helpers.capitalizeFirstLetter(this.state.status!)}
                        </span>
                    )}

                    <View
                        style={{
                            marginLeft: reuse_view_margin,
                            width: uc.SMALL_BUTTON_DIM,
                            height: uc.SMALL_BUTTON_DIM,
                            borderWidth: DEBUG_BORDER
                        }}>
                        {this.IS_EDITABLE && this.renderReuseButton()}
                    </View>
                </View>
                <View
                    style={{
                        width: '33%',
                        flexDirection: 'row',
                        // justifyContent: 'space-between',
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'green'
                    }}>
                    <View
                        style={{
                            width: '70%',
                            borderWidth: DEBUG_BORDER,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-around'
                        }}>
                        {this.IS_EDITABLE && (
                            <HoverableImageButton
                                dimension={uc.BUTTON_DIM}
                                hoverOpacity={uc.HOVER_OPACITY}
                                imageSource={require('../img/trash.svg')}
                                onPress={this.onDeleteIssuePress}
                                tooltipText={'Delete'}
                                disabledOpacity={disabledOpacity}
                                disabled={disabled}
                            />
                        )}
                        {this.IS_EDITABLE && <View style={{ marginRight: 24 }} />}
                        {this.IS_EDITABLE && (
                            <HoverableImageButton
                                dimension={uc.BUTTON_DIM}
                                hoverOpacity={uc.HOVER_OPACITY}
                                imageSource={require('@shared/img/share-link-button.svg')}
                                onPress={() => this.openInNewTab(brand.getAssignIssueURL() + this.state.issue.key)}
                                tooltipText={'Share open link'}
                                disabledOpacity={disabledOpacity}
                                disabled={disabled}
                            />
                        )}
                    </View>
                </View>
            </View>
        )
    }

    async setDueDate(selectedDate: Date) {
        const dueDateNumber = moment(selectedDate).startOf('day').valueOf()
        const area = await getArea(asFirebase(firebase), this.state.issue.area.key)

        const result = await issueHelpers.setDueDate(dueDateNumber, this.state.issue, area, this.props.currentUser!)

        this.setState({ issue: { ...result!.issue }, task: result!.task, dueDateNumber })
    }

    renderPriorityButton(disabledParam: boolean, disabledOpacity?: number) {
        const disabled = disabledParam || !this.IS_EDITABLE
        let priorityImgName
        let dim

        const tooltipText = 'Set priority'
        if (this.state.priority) {
            priorityImgName = require('@shared/img/priority-high-red-bgr.svg')
            dim = uc.BUTTON_DIM - 2
        } else {
            priorityImgName = require('@shared/img/priority-low.svg')
            dim = uc.BUTTON_DIM
        }

        return (
            <HoverableImageButton
                dimension={dim}
                hoverOpacity={uc.HOVER_OPACITY}
                imageSource={priorityImgName}
                onPress={this.onPriorityPress}
                tooltipText={tooltipText}
                disabledOpacity={disabledOpacity}
                disabled={disabled}
            />
        )
    }

    renderStatusButton(disabled: boolean, disabledOpacity?: number) {
        let statusImgName
        if (this.state.status === c.ISSUE_ASSIGNED) {
            statusImgName = require('@shared/img/status-assigned.svg')
        } else {
            statusImgName = getStatusImage(this.state.status!)
        }

        return (
            <HoverableImageButton
                dimension={disabled ? uc.BUTTON_DIM - 2 : uc.BUTTON_DIM}
                hoverOpacity={uc.HOVER_OPACITY}
                imageSource={statusImgName}
                onPress={this.onCompletePress}
                tooltipText={'Complete issue'}
                disabledOpacity={disabledOpacity}
                disabled={disabled}
                tooltipDisabled={disabled}
            />
        )
    }

    renderDueDate(task: unknown, LINE_HEIGHT: number) {
        if (!this.state.dueDateNumber) {
            return
        }

        return (
            <View
                style={{
                    flexDirection: 'row',
                    borderWidth: 0,
                    borderColor: 'blue',
                    height: LINE_HEIGHT,
                    alignItems: 'center'
                }}>
                <DueDateButton dueDate={this.state.dueDateNumber} onClick={() => this.deleteDueDate()} fontSize={13} />
            </View>
        )
    }

    deleteDueDate() {
        const issue = this.state.issue
        const task = this.state.task

        issueData.removeDueDateFromIssue(asFirebase(firebase), {
            issueKey: issue.key,
            area: issue.area,
            currentUser: this.props.currentUser!,
            assignedTo: issue.assignedContacts,
            taskKey: task?.key
        })

        this.setState({ task: task, dueDateNumber: null })
    }

    onCompletePress() {
        const nextStatus = c.ISSUE_COMPLETE
        const taskKey = this.state.issue.taskKey

        if (this.props.updateTaskStatus) {
            this.props.updateTaskStatus(taskKey!, c.TASK_COMPLETE)
        }

        this.setState({ status: nextStatus, issue: { ...this.state.issue, status: nextStatus } })

        issueData.completeIssue(asFirebase(firebase), {
            issueKey: this.state.issue.key,
            currentUser: this.props.currentUser!,
            taskKey
        })
    }

    renderCloseButton() {
        return (
            <HoverableImageButton
                dimension={uc.SMALL_BUTTON_DIM}
                hoverOpacity={uc.HOVER_OPACITY}
                imageSource={require('@shared/img/close.svg')}
                onPress={this.props.closeModal}
                tooltipText={'Close window'}
            />
        )
    }

    renderReuseButton() {
        const status = this.state.status

        if (status === c.ISSUE_OPEN || status === c.ISSUE_ASSIGNED) {
            return null
        }

        return (
            <HoverableImageButton
                dimension={uc.SMALL_BUTTON_DIM}
                hoverOpacity={uc.HOVER_OPACITY}
                imageSource={require('../img/reuse-issue.svg')}
                onPress={this.onReusePress}
                tooltipText={status === c.ISSUE_COMPLETE ? 'Uncomplete issue' : 'Undelete issue'}
            />
        )
    }

    renderInfoHeader(DEBUG_BORDER = 0, side_margin: string | number | undefined) {
        const isMobile = this.props.sharedIssueStyle && this.props.sharedIssueStyle.mobile
        const LINE_HEIGHT = 30

        const issue = this.state.issue
        if (issue === null || this.state.photoItems === null) {
            return null
        }

        const title = this.state.issue ? helpers.cleanHashTags(this.state.issueName!) : 'Loading...'
        // const title = this.state.issue ? this.state.issueName : 'Loading...'

        const area = this.state.issue.area
        const task = this.state.task

        const lastUpdated = issue && issue.updated ? issue.updated : 0
        let lastUpdatedHuman = null
        if (lastUpdated !== 0) {
            lastUpdatedHuman = moment(issue.updated).format(c.DATE_FORMAT_WITH_WEEK_DAY)
        }
        const lastUpdatedTime = moment(issue.updated).format('HH:mm')

        const createdDay = moment(issue.created).format(c.DATE_FORMAT_WITH_WEEK_DAY)
        const createdTime = moment(issue.created).format('HH:mm')

        DEBUG_BORDER = 0

        const date_style = {
            color: iOSColors.midGray,
            fontSize: 13,
            fontWeight: '600'
        }

        return (
            <View
                style={{
                    justifyContent: 'center',
                    borderColor: 'red',
                    borderWidth: DEBUG_BORDER,
                    marginHorizontal: side_margin,
                    zIndex: 2
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'green',
                        justifyContent: 'center',
                        zIndex: 2
                    }}>
                    <View
                        style={{
                            width: '85%'
                        }}>
                        <AreaBredCrumbs
                            area={area}
                            task={task}
                            issue={issue}
                            fontSize={14}
                            fontWeight={'500'}
                            currentUser={this.props.currentUser}
                            isEditable
                        />
                    </View>
                    <View style={{ borderWidth: 0, flex: 1, flexDirection: 'column', alignItems: 'flex-end' }}>
                        <span
                            style={{
                                fontSize: 13,
                                color: helpers.getIssueNumberColor(this.state.status!)
                            }}>
                            {issue.issueNumber ? issue.issueNumber : ''}
                        </span>
                    </View>
                </View>
                <View style={{ borderWidth: 0 }}>
                    <div className="mt-5">
                        <span
                            className={`input font-size-h3 pr-20 ${this.state.nameInputActive ? 'form-control form-control-lg' : ''}`}
                            role="textbox"
                            contentEditable
                            suppressContentEditableWarning={true}
                            onFocus={() => {
                                this.setState({ nameInputActive: true })
                            }}
                            onKeyPress={e => {
                                if (e.charCode === 13) {
                                    e.preventDefault()
                                    e.currentTarget.blur()
                                }
                            }}
                            onBlur={e => {
                                this.textInputValue = e.currentTarget.innerText
                                this.setState({ nameInputActive: false, issueName: this.textInputValue })
                                this.submitIssueName()
                            }}
                            style={{ height: 'auto', minHeight: 40 }}>
                            {title}
                            {this.state.nameInputActive && (
                                <i
                                    contentEditable={false}
                                    className="float-right specta-checkmark text-success"
                                    style={{ position: 'absolute', right: 10, top: '45%', cursor: 'pointer', zIndex: 10 }}
                                    onClick={e => {
                                        // @ts-ignore
                                        e.target.parentElement.blur()
                                    }}
                                />
                            )}
                        </span>
                    </div>
                </View>

                {this.renderDueDate(this.state.task!, LINE_HEIGHT)}
                <View
                    style={{
                        marginTop: 4,
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: isMobile ? 'center' : 'space-between',
                        alignItems: isMobile ? 'flex-start' : 'center',
                        borderWidth: DEBUG_BORDER,
                        borderColor: 'purple'
                    }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',

                            justifyContent: 'center',

                            borderColor: 'green',
                            borderWidth: DEBUG_BORDER
                            // height: LINE_HEIGHT
                        }}>
                        <span style={date_style}>
                            Changed {lastUpdatedTime} {lastUpdatedHuman}
                        </span>
                    </View>

                    <View
                        style={{
                            borderWidth: 0,
                            borderColor: 'red',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexDirection: 'row'
                        }}>
                        <span style={date_style}>
                            Created {createdTime} {createdDay}
                        </span>
                    </View>
                </View>
            </View>
        )
    }

    openInNewTab(url: string) {
        window.open(url, '_blank')
    }

    renderAssignedTo(issue: IssueStruct, side_margin: number) {
        const assignedTo = issue.assignedContacts
        if (!assignedTo) return null

        const DEBUG_BORDER = 0

        const onUnassignPress = () => {
            const alertOnConfirm = () => {
                // REMOVE_unAssignIssue(asFirebase(firebase), this.props.currentUser!, this.state.issue)
                issueData.unassignIssue(asFirebase(firebase), {
                    issueKey: this.state.issue.key,
                    currentUser: this.props.currentUser!,
                    taskKey: this.state.task?.key as string
                })

                this.setState({
                    status: c.ISSUE_OPEN,
                    issue: {
                        ...this.state.issue,
                        assignedTo: null,
                        assignedContacts: null,
                        status: c.ISSUE_OPEN
                    },
                    task: { ...this.state.task, assignedTo: null, assignedToKeys: null }
                })

                this.closeConfirmationAlert()
            }

            this.setState({
                alertTitle: 'Unassigning',
                alertText: 'Are you sure you want to unassign this issue?',
                alertOnConfirm,
                showConfirmationAlert: true
            })
        }

        return (
            <View style={{ borderWidth: DEBUG_BORDER, width: '50%', marginLeft: side_margin }}>
                {assignedTo && assignedTo.length > 0 && (
                    <AssigneesView
                        maxCountToDisplay={6}
                        fontWeight={'400'}
                        unassignDisabled={this.props.unassignDisabled}
                        assignedTo={assignedTo}
                        currentUser={this.props.currentUser}
                        onUnassignPress={onUnassignPress}
                    />
                )}
            </View>
        )
    }

    submitIssueName() {
        const joinedObj = hhelpers.joinTextAndUniqueHashtags(this.textInputValue, this.issueHashtags)
        const newIssueName = joinedObj.textWithHashtags
        if (this.oldIssueName !== newIssueName) {
            this.oldIssueName = newIssueName
            const issue = { ...this.state.issue, name: newIssueName }
            this.setState({ issue: issue, issueHashtags: joinedObj.uniqueHashtags })

            setTimeout(() => {
                issueData.changeIssueName(asFirebase(firebase), {
                    issueKey: issue.key,
                    currentUser: this.props.currentUser!,
                    name: issue.name,
                    taskKey: this.state.task ? this.state.task.key : null
                })
            }, 750)
        }
    }

    onSubmitComment(text: string) {
        issueData.addCommentToIssue(asFirebase(firebase), {
            issueKey: this.state.issue.key,
            currentUser: this.props.currentUser!,
            text
        })

        this.setState({
            commentInputValue: ''
        })
        if (this.showCommentToast) {
            // @ts-ignore
            notify.show(<Toast message="Scroll down to see comments" />, 'custom', 3000, {})
        }
    }

    async uploadImages(newImages: File[]) {
        this.setState({ issue: { ...this.state.issue, isImagesLoading: true } })
        await issueData.addImageItemsToIssue(asFirebase(firebase), {
            currentUser: this.props.currentUser as UserStruct,
            issueKey: this.state.issue.key,
            images: newImages,
            currentItems: this.state.photoItems
        })
        this.setState({ issue: { ...this.state.issue, isImagesLoading: false } })
    }

    setImages(images: File[]) {
        this.uploadImages(images)
    }

    renderPhotoItems(side_margin: number) {
        let numberOfPhotos = 4
        const isMobile = this.props.sharedIssueStyle && this.props.sharedIssueStyle.mobile
        if (isMobile) {
            numberOfPhotos = 2
        }

        const data = [...this.state.photoItems].reverse()

        const ITEM_PADDING = 8
        const AVAILABLE_FOR_PHOTOS = window.innerWidth * 0.6 - 2 * side_margin

        const PADDING_SPACE = ITEM_PADDING * numberOfPhotos

        const photoDim = (AVAILABLE_FOR_PHOTOS - PADDING_SPACE) / numberOfPhotos

        const loadingCondition = this.state.issue.isImagesLoading && (
            <div style={{ width: photoDim, height: photoDim, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner />
            </div>
        )

        return (
            <View
                style={{
                    backgroundColor: colors.mainBackground,
                    borderColor: 'red',
                    borderWidth: 0,
                    marginHorizontal: side_margin,
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                {this.state.photoItems.length > 0 ? (
                    <FlatList
                        key={isMobile ? 'h' : 'v'}
                        showsVerticalScrollIndicator={false}
                        data={data}
                        contentContainerStyle={{
                            backgroundColor: colors.mainBackground,
                            flexDirection: 'column',
                            gap: ('15px' as unknown) as any,
                            borderColor: 'green',
                            borderWidth: 0
                        }}
                        numColumns={numberOfPhotos}
                        keyExtractor={(item, index) => index + item.key + this.state.respWidth}
                        renderItem={listItem => {
                            const { item, index } = listItem
                            const itemNumber = index + 1

                            return (
                                <PhotoItem
                                    // sharedIssueStyle={this.props.sharedIssueStyle}
                                    editable={this.IS_EDITABLE}
                                    currentUser={this.props.currentUser!}
                                    issueKey={this.state.issue.key}
                                    // sideMargin={side_margin}
                                    item={item}
                                    onDeleteItemPress={this.onDeleteItemPress}
                                    width={photoDim}
                                    padding={ITEM_PADDING}
                                    openPhotoItemModal={this.openPhotoItemModal}
                                    itemNumber={itemNumber}
                                    length={this.state.photoItems.length}
                                    setImages={this.setImages}
                                    isImagesLoading={this.state.issue.isImagesLoading}
                                />
                            )
                        }}
                    />
                ) : (
                    <div style={{ display: 'flex' }}>
                        <ImagesDropBox width={photoDim} setImages={this.setImages} />
                        {loadingCondition}
                    </div>
                )}
            </View>
        )
    }

    renderCommentivity(side_margin: string | number | undefined) {
        if (this.state.commentivity && this.state.commentivity.length > 5) {
            this.showCommentToast = true
        }
        const deleteComment = (comment: CommentivityStruct) => {
            const alertOnConfirm = () => {
                comment.visible = false
                issueData.deleteCommentivity(asFirebase(firebase), {
                    issueKey: this.state.issue.key,
                    currentUser: this.props.currentUser!,
                    commentivityKey: comment.key
                })
                this.closeConfirmationAlert()
            }
            this.setState({
                alertTitle: 'Delete comment',
                alertText: 'Are you sure you want to delete this comment?',
                alertOnConfirm,
                showConfirmationAlert: true
            })
        }

        if (this.state.commentivity && this.state.commentivity.length > 0) {
            return (
                <View style={{ marginHorizontal: side_margin }}>
                    <span
                        style={{
                            color: iOSColors.black,
                            fontSize: this.UNIT_HEADER_FONT_SIZE,
                            marginBottom: 12
                        }}
                        className="font-weight-bold">
                        Activity
                    </span>
                    <FlatList
                        data={this.state.commentivity}
                        style={{
                            marginBottom: this.props.sharedIssueStyle ? 0 : 48
                        }}
                        renderItem={({ item }) => (
                            <CommentivityRow item={item} currentUser={this.props.currentUser!} deleteComment={deleteComment} />
                        )}
                    />
                </View>
            )
        }
    }

    renderCommentsInput(side_margin: number) {
        const horizontalMargin = side_margin / 2
        return <CommentInput horizontalMargin={horizontalMargin} onSubmitComment={this.onSubmitComment} />
    }

    renderDeveloperSection() {
        const TITLE_FONT_SIZE = 13
        const TITLE_FONT_COLOR = iOSColors.gray

        return (
            <View style={{ width: '100%' }}>
                <span
                    style={{
                        fontWeight: '600',
                        color: iOSColors.black,
                        fontSize: this.UNIT_HEADER_FONT_SIZE,
                        // marginBottom: 12,
                        overflow: 'visible'
                    }}>
                    Developer section
                </span>

                <span
                    style={{
                        marginTop: 16,
                        fontWeight: '500',
                        color: TITLE_FONT_COLOR,
                        fontSize: TITLE_FONT_SIZE,
                        marginBottom: 6
                    }}>
                    Issue JSON
                </span>
                <TextInput
                    style={{
                        borderWidth: 1,
                        borderRadius: 6,
                        borderColor: iOSColors.lightGray,
                        padding: 16,

                        // @ts-ignore
                        outlineStyle: 'none',
                        width: '100%'
                    }}
                    value={JSON.stringify(this.state.issue)}
                />
                <span
                    style={{
                        marginTop: 8,
                        fontWeight: '500',
                        color: TITLE_FONT_COLOR,
                        fontSize: TITLE_FONT_SIZE,
                        marginBottom: 6
                    }}>
                    Task JSON
                </span>
                <TextInput
                    style={{
                        borderWidth: 1,
                        borderRadius: 6,
                        borderColor: iOSColors.lightGray,
                        padding: 16,

                        // @ts-ignore
                        outlineStyle: 'none',
                        width: '100%'
                    }}
                    value={JSON.stringify(this.state.task)}
                />
            </View>
        )
    }
}

export type Inputs = {
    [inputName: string]: {
        required: boolean
    }
}

export class InputsBuilder {
    inputs: Inputs = {}

    constructor() {}

    static inputs() {
        return new InputsBuilder()
    }

    input(name: string, required = true) {
        this.inputs[name] = { required }
        return this
    }

    build() {
        return this.inputs
    }
}

export const inputs = () => new InputsBuilder()

import React, { useEffect, useCallback } from 'react'
import SignUpLayout from '../../SignUpLayout/index'
import LoadingScreen from '../../LoadingScreen/index'
import { FEATURES_QUERY_PARAMS, SIGN_UP, SITE_URL } from '../../../../navigation/url-constants'
import { apaleoSetup } from '../../cloud-functions'
import { errorModalConfigs } from '../../helpers'
import { compose } from 'redux'
import withIntegrationWorkflow from '../../../../utils/hocs/withIntegrationWorkflow'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import { INTEGRATIONS } from '../../../../utils/constants'
import { useFeatureToggle } from '../../../../features'
import { reconnectOrganizationPMSSetup } from '../../cloud-functions'
import { getApi } from '../../frontend-api'
import firebase, { asFirebase } from '../../../../utils/firebase'

const ApaleoCallback = ({
    newOrgKey,
    catchError,
    signupComplete,
    workflowInstance,
    storeWorkflowInstance,
    setComplete,
    currentIntegrationKey,
    newUserKey,
    settings,
    message,
    setStopAuthListener,
    currentOrganization
}) => {
    console.log('ApaleoCallback')
    const { isFeatureOn } = useFeatureToggle()
    const useNewInitialImport = isFeatureOn('new-initial-import-procedure')
    const redirect = SITE_URL + SIGN_UP.APALEO_CALLBACK

    useEffect(() => {
        setStopAuthListener(true)
        setupApaleo()
    }, [])

    async function reconnectPMS() {
        console.log('reconnectPMS')
        const reconnectOrganizationPMS = reconnectOrganizationPMSSetup()

        const { data } = await reconnectOrganizationPMS({
            orgId: currentOrganization.key,
            integration: INTEGRATIONS.APALEO,
            version: currentOrganization.pmsVersion ?? 'stable',
            signupPayload: {
                code: settings.code,
                organizationID: currentOrganization.key,
                redirect,
                registerUI: !isFeatureOn(FEATURES_QUERY_PARAMS.OLD_APALEO)
            }
        })

        if (data.reason !== 'all good') {
            catchError(data.message, errorModalConfigs)
            return 'error'
        }

        if ('test' in workflowInstance.values) {
            data.reason = 'test'
        }

        return data
    }

    async function setupApaleoSignupOld() {
        console.info('Initial Apaleo PMS Sync: Using old method')
        console.log('setupApaleoSignup')
        const setupApaleoIntegration = apaleoSetup()

        const { data } = await setupApaleoIntegration({
            orgId: newOrgKey,
            integration: INTEGRATIONS.APALEO,
            version: workflowInstance.getInput('version'),
            signupPayload: {
                code: settings.code,
                organizationID: newOrgKey,
                redirect,
                registerUI: !isFeatureOn(FEATURES_QUERY_PARAMS.OLD_APALEO)
            }
        })

        if (data.reason !== 'all good') {
            catchError(data.message, errorModalConfigs)
            return 'error'
        }

        if ('test' in workflowInstance.values) {
            data.reason = 'test'
        }

        await signupComplete({ orgId: newOrgKey, userId: newUserKey, status: data })

        return data
    }

    async function setupApaleoNew() {
        console.info('Initial Apaleo PMS Sync: Using new server-side method')
        try {
            const api = await getApi(asFirebase(firebase))

            await api['setup-org'].$post({
                json: {
                    newAdminUserKey: newUserKey,
                    orgId: newOrgKey,
                    integration: INTEGRATIONS.APALEO,
                    version: workflowInstance.getInput('version'),
                    isTest: 'test' in workflowInstance.values,
                    signupPayload: {
                        code: settings.code,
                        organizationID: newOrgKey,
                        redirect,
                        registerUI: !isFeatureOn(FEATURES_QUERY_PARAMS.OLD_APALEO)
                    }
                }
            })

            // Replicates(?) the return value of the old method.
            // Which is kind of a dubious return value.
            const data = { reason: 'all good' }

            if ('test' in workflowInstance.values) {
                data.reason = 'test'
            }

            return data
        } catch (error) {
            catchError(error, errorModalConfigs)
            return 'error'
        }
    }

    async function setupApaleoSignup() {
        return await (useNewInitialImport ? setupApaleoNew() : setupApaleoSignupOld())
    }

    async function setupApaleo() {
        try {
            const data = workflowInstance.getInput('reconnect') ? await reconnectPMS() : await setupApaleoSignup()

            if (!data || data === 'error') {
                return
            }

            workflowInstance.completeStep(SIGN_UP.APALEO_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    return (
        <SignUpLayout withModal>
            <LoadingScreen message={message} />
        </SignUpLayout>
    )
}

export default compose(withIntegrationWorkflow, withErrorCatching)(ApaleoCallback)

// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from 'react'
import { RULE_TYPE_CHECKOUT, RULE_TYPE_CUSTOM, RULE_TYPE_DAILY } from '@shared/rule-resolver'

export function ActionsColumnFormatter(cellContent, row, rowIndex, { openEditRuleDialog, setDeleteRuleId }) {
    return (
        <>
            {[RULE_TYPE_CHECKOUT, RULE_TYPE_CUSTOM, RULE_TYPE_DAILY].includes(row.repeatType.toLowerCase()) && (
                <a
                    title="Edit rule"
                    className="btn btn-icon btn-circle btn-bg-specta-gray btn-hover-bg-specta-gray-light btn-hover-icon-primary btn-sm mx-3"
                    onClick={() => openEditRuleDialog(row.id)}>
                    <i className="specta-pencil2" />
                </a>
            )}

            <a
                title="Delete rule"
                className="btn btn-icon btn-circle btn-bg-specta-gray btn-hover-bg-specta-gray-light btn-hover-icon-danger btn-sm"
                onClick={() => setDeleteRuleId(row.id)}>
                <i className="specta-trash" />
            </a>
        </>
    )
}

import * as helpers from './helpers'
import * as c from './constants'

type Hashtag = { key: string; selected: boolean }
export function getDefaultIssueHashtagsList(issueHashtags: string[], includeAllItem = true, includeNoneItem = true) {
    const hashtags = issueHashtags

    let hashtagList: Hashtag[] = []

    if (hashtags) {
        for (let i = 0; i < hashtags.length; i++) {
            hashtagList.push({ key: hashtags[i], selected: true })
        }
    }
    hashtagList = hashtagList.sort((a, b) => helpers.sortByName(a.key, b.key))

    if (includeAllItem) {
        hashtagList.unshift({ key: c.HASHTAG_ALL, selected: true })
    }
    if (includeNoneItem) {
        hashtagList.push({ key: c.HASHTAG_WITHOUT_HASHTAGS, selected: true })
    }

    return hashtagList
}

export function hashtagsFilterToString(hashtags: Hashtag[], length = 22) {
    if (!hashtags || hashtags.length === 0) {
        return ''
    }
    let hashtagsField = ''
    if (hashtags[0].selected) {
        hashtagsField = 'All'
    } else {
        let isFirst = true
        for (let i = 1; i < hashtags.length; i++) {
            const tag = hashtags[i]
            if (tag.selected) {
                if (isFirst) {
                    isFirst = false
                    hashtagsField = tag.key
                } else {
                    hashtagsField += ', ' + tag.key
                }
            }
        }
    }

    return helpers.shortenLongName(hashtagsField, length)
}

export function convertHashtagsToFilteredList(issueHashtags: string[], hashtagFilter: Hashtag[]) {
    const hashtagList = getDefaultIssueHashtagsList(issueHashtags)
    if (hashtagList) {
        hashtagList.forEach(hashtag => {
            if (hashtag.key !== '') {
                const filterItem = hashtagFilter.find(t => t.key === hashtag.key)
                if (filterItem) {
                    hashtag.selected = filterItem.selected
                }
            }
        })
        return hashtagList
    }
    return null
}

export function hasSameHashtags(hashtags1: string[], hashtags2: string[]) {
    if (!hashtags1) {
        return false
    }

    if (!hashtags2) {
        return false
    }

    if (hashtags1.length !== hashtags2.length) {
        return false
    }
    for (let i = 0; i < hashtags1.length; i++) {
        if (hashtags1[i] !== hashtags2[i]) {
            return false
        }
    }

    return true
}

export function joinTextAndUniqueHashtags(text: string, hashtags: string[]) {
    const hashTagsFromText = helpers.getHashTags(text)
    const uniqueHashtags = joinUniqueHashtags(hashTagsFromText, hashtags)
    const cleanText = helpers.cleanHashTags(text)
    const textWithHashtags = cleanText + ' ' + helpers.hashTagsToString(uniqueHashtags)

    return { uniqueHashtags: uniqueHashtags, textWithHashtags: textWithHashtags }
}

export function joinUniqueHashtags(hastags1: string[], hashtags2: string[]) {
    let allHashtags = [...hastags1.filter(h => hashtags2.includes(h)), ...hashtags2]
    allHashtags = getUniqueStringArray(allHashtags)
    allHashtags.sort((a, b) => helpers.sortByName(a, b))
    return allHashtags
}

export function getUniqueStringArray(arr: string[]) {
    const result = []
    const map = new Map()
    for (const item of arr) {
        if (!map.has(item.toLowerCase())) {
            map.set(item.toLowerCase(), true)
            result.push(item)
        }
    }
    return result
}

export function convertStringToHashtag(string: string) {
    let hashtagStr = string

    if (hashtagStr.charAt(0) !== '#') {
        hashtagStr = '#' + hashtagStr
    }

    return hashtagStr.replace(/\s/g, '_')
}

import { selector } from 'recoil'
import { activitiesAtom, allTasksAtom, areasAtom, bookingsAtom, dailyCommentsAtom, fetchedTasksAtom, rulesAtom } from '../atoms'
import * as c from '@shared/txt-constants'
import { sortTimeStampAscending, sortTimeStampDescending } from '@shared/helpers'
import { calculateCleaningStatusSync } from '@shared/calculator/cleaning-calculator'
import moment from 'moment-timezone'
import firebase, { asFirebase } from '../../../utils/firebase'
import { ActivityStruct, AreaStruct, BookingStruct, DailyCommentStruct, TaskStruct, UserStruct } from '@shared/dataObjects'
import { selectedDateNumberSelector } from './selectedDateNumber'
import { currentOrganizationAtom, currentUserAtom } from 'app/modules/Auth/atoms'
import { userHasAccessToAreaGroup } from '@shared/area-groups-helpers'
import { structuredClone } from '@shared/polyfills'

export const allTasksSelector = selector<TaskStruct[]>({
    key: 'taskboard-allTasksSelector',
    get: ({ get }) => get(allTasksAtom),
    set: ({ set, get }, tasks) => {
        const selectedDateNumber = get(selectedDateNumberSelector)
        const areas = get(areasAtom)
        const activities = get(activitiesAtom)
        const bookings = get(bookingsAtom)
        const dailyComments = get(dailyCommentsAtom)
        const rules = get(rulesAtom)
        const currentOrganization = get(currentOrganizationAtom)
        const currentUser = get(currentUserAtom) as UserStruct

        if (!currentOrganization) {
            return
        }

        const connectArea = (task: TaskStruct) => {
            const area = (task?.area && areas.find((area: AreaStruct) => area.key === task.area.key)) ?? task?.area
            if (!area) return task
            return { ...task, area }
        }

        const generalTasks = structuredClone(tasks as TaskStruct[])
            .filter((task: TaskStruct) => task.type === c.TASK_TYPE_GENERAL)
            .map(connectArea)

        const issueTasks = structuredClone(tasks as TaskStruct[])
            .filter((task: TaskStruct) => task.type === c.TASK_TYPE_ISSUE)
            .map(connectArea)

        const housekeepingTasks = structuredClone(tasks as TaskStruct[]).filter(
            (task: TaskStruct) => task.type === c.TASK_TYPE_HOUSEKEEPING
        )
        const housekeepingTaskAreaKeys = housekeepingTasks.map((task: TaskStruct) => task.area.key)
        const housekeepingTaskAreas = areas.filter((area: AreaStruct) => housekeepingTaskAreaKeys.includes(area.key))
        const housekeepingTasksUpdated = housekeepingTasks.map((task: TaskStruct) => {
            const area = housekeepingTaskAreas.find((area: AreaStruct) => area.key === task.area.key)
            if (!area) return task

            const areaActivities = activities
                .filter((activity: ActivityStruct) => activity.areaKey === area.key && activity.date === selectedDateNumber)
                .sort((a: ActivityStruct, b: ActivityStruct) => sortTimeStampDescending(a.created, b.created))

            const areaBookings = bookings
                .filter(
                    (booking: BookingStruct) => booking.areaKey === area.key && booking.checkoutDate >= moment().startOf('day').valueOf()
                )
                .sort((a: BookingStruct, b: BookingStruct) => sortTimeStampAscending(a.checkinDate, b.checkinDate))

            const areaDailyComments = dailyComments.filter((comment: DailyCommentStruct) => comment.areaKey === area.key)

            const calculatedAreas = calculateCleaningStatusSync(
                structuredClone([area]),
                selectedDateNumber,
                currentOrganization,
                areaBookings,
                areaActivities,
                rules
            )

            const priorityArr = areaActivities.filter((activity: ActivityStruct) => activity.type === c.ACTIVITY_TYPE_CLEANING_PRIORITY)

            const calculatedArea = {
                tasks: [task],
                activities: areaActivities,
                bookings: areaBookings,
                dailyComments: areaDailyComments,
                priority: priorityArr.length > 0 ? priorityArr[0].change.after : false,
                ...calculatedAreas[0]
            }

            const initials = task.assignedTo ? task.assignedTo.map(user => user.initials).join(', ') : null

            return { ...task, area: calculatedArea, priority: calculatedArea.priority, initials, bookings: areaBookings }
        })

        const updatedAllTasks = [...generalTasks, ...housekeepingTasksUpdated, ...issueTasks].filter(task => {
            if (!task.area) {
                return true
            }
            return userHasAccessToAreaGroup(currentUser.areaGroups, task.area.group)
        })

        set(fetchedTasksAtom, updatedAllTasks as TaskStruct[])
        set(allTasksAtom, updatedAllTasks as TaskStruct[])
    }
})

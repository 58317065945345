import { atom } from 'recoil'

export const reportState = atom({
    key: 'reportData',
    default: []
})

export const reportTableDateHeaders = atom({
    key: 'reportTableDateHeaders',
    default: ['User', 'Units cleaned', 'Average time', 'Start', 'End', 'Paused', 'Active time', 'Idle time', 'Total time']
})

export const reportTablePeriodHeaders = atom({
    key: 'reportTablePeriodHeaders',
    default: ['User', 'Units cleaned', 'Average time', 'Active time']
})

export const reportTasksHeaders = atom({
    key: 'reportTasksHeaders',
    default: [
        { label: 'Unit', key: 'area' },
        { label: 'Date', key: 'date' },
        { label: 'Duration', key: 'duration' },
        { label: 'Start', key: 'start' },
        { label: 'End', key: 'end' },
        { label: 'Paused', key: 'paused' },
        { label: 'Tasks', key: 'task' }
    ]
})

export const reportSummaryByUserHeaders = atom({
    key: 'reportSummaryByUserHeaders',
    default: [
        { label: 'Type', key: 'type' },
        { label: 'Count', key: 'count' },
        { label: 'Average time', key: 'averageTime' }
    ]
})

export const reportTableCleaningSummaryHeaders = atom({
    key: 'reportTableCleaningSummaryHeaders',
    default: ['Type', 'Units Cleaned', 'Average time']
})

export const reportsData = atom({
    key: 'reportsData',
    default: []
})

export const tasksData = atom({
    key: 'tasksData',
    default: []
})

export const reportsTaskTypes = atom({
    key: 'reportsTaskTypes',
    default: []
})

export const dateFilterType = atom({
    key: 'reportDateFilterType',
    default: 'date'
})

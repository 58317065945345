import React, { Component } from 'react'
import { ActivityIndicator, Image, SectionList, View } from 'react-native-web'
import AsyncStorage from '@react-native-community/async-storage'
import Notifications, { notify } from 'react-notify-toast'
import moment from 'moment-timezone'
import Modal from 'react-modal'
import { Row } from 'react-flexbox-grid'

import { Toast } from '../../utils/toast'
import { iOSColors } from '@shared/react-native-typography'
import firebase from '../../utils/firebase'
import * as colors from '@shared/colors'
import * as helpers from '@shared/helpers'
import * as ahelpers from '@shared/area-groups-helpers'

import * as c from '@shared/constants'
import * as brand from '@shared/brand'
import * as taskData from '@shared/task-data'
import * as taskHelpers from '@shared/task-helpers'
import ButtonsBar from '../../issues/buttons-bar'

import HousekeepingDetail from '../housekeeping-modal'
import AssignActionBar from '../assign-action-bar'

import * as areaData from '@shared/area-data'

import DailyCommentsSummary from '../daily-comments-summary'

import HousekeepingBox from '../housekeeping-box/housekeeping-box'
import UsersList from '../../users/users-list'

import { calculateCleaningStatus } from '@shared/calculator/cleaning-calculator'
import * as chelpers from '@shared/cleaning-helpers'
import * as dataObjects from '@shared/dataObjects'
import * as styles from './styles'
import * as data from './data'
import * as getBookingsQuery from './getBookingsQuery'

import * as uc from '../../utils/constants'

import { Card, CardBody, CardHeader, CardHeaderCounter } from '../../../_metronic/_partials/controls'

import DashboardFilter from './dashboard-filter/DashboardFilter'

import { PermissionCheck, SubscriptionCheck, UpgradeMessageContent } from '../../components/upgrade-signals'
import { QuickGuide } from '../../components/quick-guide'
import { JoyrideTooltip } from '../../components/joyride-tooltip'

import Joyride from 'react-joyride'
import { HoverableImageButton } from '../../components/buttons/hoverable-image-button'

import { Workload } from '../../components/workload'
import { SubscriptionContext } from '../../modules/Subscriptions/SubscriptionContext'
import { UpgradeModal } from '../../modules/Subscriptions/UpgradeModal'
import { findLeadBooking } from '@shared/booking-service'
import { CLEANING_STATUS_NO_SERVICE } from '@shared/txt-constants'
import MainActionButton from '../../components/buttons/MainActionButton'
import { Wrapper, AreasContainer } from 'app/modules/HousekeepingDashboard/HousekeepingBody/GroupSection/style'
import Loader from 'app/components/loaders/Loader'
import { clone } from 'ramda'
import { userIsAllowed } from '@shared/roles/roles'

const CLEANING_ACTION_BAR_HEIGHT = 180
const PLEASE_SELECT_AREA_MESSAGE = 'Please select at least one area'
const ON_PRESS_TIMEOUT = 1000

export default class HousekeepingDashboard extends Component {
    constructor(props) {
        super(props)

        this.onAreasUpdate = this.onAreasUpdate.bind(this)
        this.onRulesUpdate = this.onRulesUpdate.bind(this)
        this.onBookingsUpdate = this.onBookingsUpdate.bind(this)
        this.onDailyCommentsUpdate = this.onDailyCommentsUpdate.bind(this)
        this.onTasksUpdate = this.onTasksUpdate.bind(this)
        this.initView = this.initView.bind(this)
        this.onSearch = this.onSearch.bind(this)
        this.onStatusPress = this.onStatusPress.bind(this)
        this.closeAreaModal = this.closeAreaModal.bind(this)
        this.filterAndDisplayAreas = this.filterAndDisplayAreas.bind(this)
        this.onDateChange = this.onDateChange.bind(this)
        this.getCountedAreaGroups = this.getCountedAreaGroups.bind(this)
        this.startListeners = this.startListeners.bind(this)
        this.stopListener = this.stopListener.bind(this)

        this.setShowActionBar = this.setShowActionBar.bind(this)
        this.onAssignActionBarPressArea = this.onAssignActionBarPressArea.bind(this)
        this.addSelectedArea = this.addSelectedArea.bind(this)
        this.removeSelectedArea = this.removeSelectedArea.bind(this)
        this.clearSelectedAreas = this.clearSelectedAreas.bind(this)
        this.pickAssignMode = this.pickAssignMode.bind(this)
        this.closeAssignMode = this.closeAssignMode.bind(this)
        this.onUnassignPress = this.onUnassignPress.bind(this)
        this.onAssignPress = this.onAssignPress.bind(this)
        this.openSelectUserModal = this.openSelectUserModal.bind(this)
        this.closeSelectUserModal = this.closeSelectUserModal.bind(this)

        this.onActivitiesUpdate = this.onActivitiesUpdate.bind(this)
        this.prepareAreaData = this.prepareAreaData.bind(this)
        this.addDailyCommentsAndNoteToAreas = this.addDailyCommentsAndNoteToAreas.bind(this)

        this.setCleaningTaskName = this.setCleaningTaskName.bind(this)
        this.setInputCleaningTaskName = this.setInputCleaningTaskName.bind(this)
        this.setOccupancyFilter = this.setOccupancyFilter.bind(this)
        this.getAreaToday = this.getAreaToday.bind(this)
        this.setAssignModeFilter = this.setAssignModeFilter.bind(this)
        this.selectAllAreas = this.selectAllAreas.bind(this)

        this.closeDailyCommentsSummary = this.closeDailyCommentsSummary.bind(this)
        this.openDailyCommentsSummary = this.openDailyCommentsSummary.bind(this)
        this.renderArea = this.renderArea.bind(this)
        this.onUserListAssign = this.onUserListAssign.bind(this)
        this.onHouskeepingModalUnassign = this.onHouskeepingModalUnassign.bind(this)

        this.startComponentUIControl = this.startComponentUIControl.bind(this)
        this.setCleaningStatusFilter = this.setCleaningStatusFilter.bind(this)

        this.onPriorityPress = this.onPriorityPress.bind(this)
        this.onPriorityPressTimeout = this.onPriorityPressTimeout.bind(this)

        this.openUpgradeMessage = this.openUpgradeMessage.bind(this)
        this.closeUpgradeMessage = this.closeUpgradeMessage.bind(this)

        this.closeQuickGuide = this.closeQuickGuide.bind(this)
        this.openQuickGuide = this.openQuickGuide.bind(this)

        this.handleResizing = this.handleResizing.bind(this)

        moment.updateLocale('en', {
            calendar: {
                lastDay: '[Yesterday]',
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                lastWeek: '[Last] dddd',
                nextWeek: '[Next] dddd',
                sameElse: 'L'
            }
        })

        let searchValue = ''
        AsyncStorage.getItem('cleaning-search-value', (err, result) => {
            if (result && !err) {
                searchValue = result
            }
        })

        let occupancyFilter = c.OCCUPANCY_ALL
        AsyncStorage.getItem('cleaning-occupancy-filter', (err, result) => {
            if (result && !err) {
                occupancyFilter = result
            }
        })
        this.occupancyFilter = occupancyFilter

        let cleaningTaskName
        AsyncStorage.getItem('cleaning-cleaning-task-name', (err, result) => {
            if (result && !err) {
                cleaningTaskName = result
            }
        })
        if (cleaningTaskName) {
            this.cleaningTaskName = cleaningTaskName
        } else {
            this.cleaningTaskName = 'Housekeeping'
        }

        let lastAssignedUsers = null
        AsyncStorage.getItem('cleaning-last-assigned-users', (err, result) => {
            if (result && !err) {
                lastAssignedUsers = JSON.parse(result)
            }
        })
        this.lastAssignedUsers = lastAssignedUsers
        const today = moment()

        this.selectedDateNumber = today.startOf('day').valueOf()
        this.selectedDate = moment()

        this.assignModeFilter = c.ASSIGN_MODE_FILTER_ALL

        const actionBarYposition = window.innerHeight - CLEANING_ACTION_BAR_HEIGHT - 50
        this.allAreas = []
        this.allAreasToday = []
        this.selectedAreas = []

        this.defaultCleaningStatusFilter = [
            {
                label: 'All',
                value: c.CLEANING_STATUS_ALL,
                isSelected: true,
                count: 0
            },
            {
                label: 'Waiting for checkout',
                value: c.CLEANING_STATUS_WAITING_FOR_CHECKOUT,
                isSelected: true,
                count: 0
            },
            { label: 'Unclean', value: c.CLEANING_STATUS_DIRTY, isSelected: true, count: 0 },
            { label: 'Preparing', value: c.CLEANING_STATUS_PREPARING, isSelected: true, count: 0 },
            { label: 'Clean', value: c.CLEANING_STATUS_CLEAN, isSelected: true, count: 0 },
            { label: 'Inspection', value: c.CLEANING_STATUS_INSPECTION, isSelected: true, count: 0 },

            { label: 'Do not disturb', value: c.CLEANING_STATUS_DO_NOT_DISTURB, isSelected: true, count: 0 },
            { label: 'Out of service', value: c.CLEANING_STATUS_OUT_OF_SERVICE, isSelected: true, count: 0 },
            { label: 'No service', value: c.CLEANING_STATUS_NO_SERVICE, isSelected: true, count: 0 },
            { label: 'Reset', value: 'reset' }
        ]

        AsyncStorage.getItem(uc.DASHBOARD_CLEANING_STATUS_FILTER, (err, result) => {
            if (result && !err) {
                this.cleaningStatusFilter = JSON.parse(result)
            }
        })
        this.cleaningStatusFilter = this.cleaningStatusFilter ? this.cleaningStatusFilter : this.defaultCleaningStatusFilter

        AsyncStorage.getItem(uc.DASHBOARD_PRIORITY_FILTER, (err, result) => {
            if (result && !err) {
                this.priorityFilter = JSON.parse(result)
            }
        })
        this.priorityFilter = this.priorityFilter ? this.priorityFilter : this.priorityFilter

        this.state = {
            areas: [],
            areaModalIsOpen: false,
            selectedArea: null,
            respWidth: null,
            searchValue,
            areaSectionData: [],
            showSections: true,
            selectedCleaningStatusFilters: [],
            countedAreaGroups: [],
            selectedDateNumber: this.selectedDateNumber,
            dataIsReady: false,
            actionBarYposition: actionBarYposition - 50,
            selectedAreas: this.selectedAreas,
            selectedTask: null,
            showActionBar: false,
            selectUserModalIsOpen: false,
            occupancyFilter: this.occupancyFilter,
            assignModeFilter: this.assignModeFilter,
            cleaningTaskName: this.cleaningTaskName,
            inputCleaningTaskName: '',
            showDailyCommentsSummary: false,
            cleaningStatusFilter: this.cleaningStatusFilter,
            priorityFilter: this.priorityFilter,
            priorityAreaCounter: 0,
            showUpgradeMessage: false,
            notAccessibleFeature: null,
            availableUpgrades: null,
            showJoyride: false,
            showQuickGuide: false,
            hasSubscriptionAccess: false,
            isShowNotAccessModal: false
        }

        this.areaModalStyle = styles.areaModalStyle
        this.mounted = false
        this.timerHandles = []
    }

    static contextType = SubscriptionContext
    static COUNT = 0

    static mongoLog() {
        HousekeepingDashboard.COUNT = HousekeepingDashboard.COUNT + 1
    }

    debounce(func) {
        var timer
        return function (event) {
            if (timer) clearTimeout(timer)
            timer = setTimeout(func, 100, event)
        }
    }

    checkSubscription() {
        const { checkSubscription } = this.context

        const onAvailable = () => {
            this.setState({ hasSubscriptionAccess: true })
        }

        const onNotAvailable = (notAccessibleFeature, availableUpgrades) => {
            this.setState({ hasSubscriptionAccess: false })
            this.setState({ notAccessibleFeature })
            this.setState({ availableUpgrades })
        }

        checkSubscription('unlimited-areas', onAvailable, onNotAvailable)
    }

    async componentDidMount() {
        this.checkSubscription()
        if (window.location.search === '?quick-guide') {
            this.openQuickGuide()
        }

        const that = this
        window.addEventListener(
            'resize',
            this.debounce(function () {
                that.handleResizing()
            })
        )
        await this.initView()
    }

    handleResizing() {
        this.filterAndDisplayAreas(this.state.searchValue, this.state.areas)
    }

    static COMPONENT_CONTROLS_DISPLAY = false

    async initView() {
        this.areasFetched = false
        this.bookingsFetched = false
        this.activitesFetched = false
        this.rulesFetched = false

        this.firstTimeAreaListener = true
        this.firstTimeActivitiesListener = true
        this.firstTimeBookingsListener = true
        this.firstTimeDailyCommentsListener = true
        this.firstTimeTasksListener = true

        const promises = [
            areaData.getHousekeepingDashboardAreas(firebase, this.props.currentUser.organizationKey),
            data.getBookings(firebase, this.props.currentUser.organizationKey, this.state.selectedDateNumber),
            data.getActivities(firebase, this.props.currentUser.organizationKey, this.state.selectedDateNumber),
            data.getDailyComments(firebase, this.props.currentUser.organizationKey, this.state.selectedDateNumber),
            taskData.getTasks(
                taskData.getHousekeepingTasksQuery(firebase, this.props.currentUser.organizationKey, this.state.selectedDateNumber)
            ),
            data.getRules(firebase, this.props.currentUser.organizationKey)
        ]

        await Promise.all(promises).then(values => {
            this.areasFetched = true
            this.bookingsFetched = true
            this.activitesFetched = true
            this.rulesFetched = true

            this.allAreas = values[0]
            this.bookings = values[1]
            this.activities = values[2]
            this.dailyComments = values[3]
            this.allTasks = values[4]
            this.rules = values[5]
        })
        await this.prepareAreaData()
        this.startListeners(this.state.selectedDateNumber)
    }

    startListeners(date) {
        this.stopListener()

        const areasQuery = data.getAreasQuery(firebase, this.props.currentUser.organizationKey)
        const activitiesQuery = data.getActivitiesQuery(firebase, this.props.currentUser.organizationKey, date)
        const bookingsQuery = getBookingsQuery.getBookingsQuery(firebase, this.props.currentUser.organizationKey, date)

        const dailyCommentsQuery = data.getDailyCommentsQuery(firebase, this.props.currentUser.organizationKey, date)
        const tasksQuery = taskData.getHousekeepingTasksQuery(firebase, this.props.currentUser.organizationKey, date)
        const rulesQuery = data.getRulesQuery(firebase, this.props.currentUser.organizationKey)

        this.unsubscribeAreas = areasQuery.onSnapshot(this.onAreasUpdate)
        this.unsubscribeActivities = activitiesQuery.onSnapshot(this.onActivitiesUpdate)
        this.unsubscribeBookings = bookingsQuery.onSnapshot(this.onBookingsUpdate)
        this.unsubscribeRules = rulesQuery.onSnapshot(this.onRulesUpdate)

        this.unsubscribeDailyComments = dailyCommentsQuery.onSnapshot(this.onDailyCommentsUpdate)
        this.unsubscribeTasks = tasksQuery.onSnapshot(this.onTasksUpdate)
    }

    stopListener() {
        HousekeepingDashboard.COMPONENT_CONTROLS_DISPLAY = false
        if (this.unsubscribeAreas) {
            this.unsubscribeAreas()
        }
        if (this.unsubscribeActivities) {
            this.unsubscribeActivities()
        }
        if (this.unsubscribeBookings) {
            this.unsubscribeBookings()
        }
        if (this.unsubscribeDailyComments) {
            this.unsubscribeDailyComments()
        }
        if (this.unsubscribeTasks) {
            this.unsubscribeTasks()
        }
        if (this.unsubscribeRules) {
            this.unsubscribeRules()
        }
    }

    onAreasUpdate(areasSnap) {
        if (!this.firstTimeAreaListener) {
            this.allAreas = areasSnap.docs.map(a => a.data())

            this.areasFetched = true
            if (!HousekeepingDashboard.COMPONENT_CONTROLS_DISPLAY) {
                this.prepareAreaData()
            }
        }
        this.firstTimeAreaListener = false
    }

    onRulesUpdate(rulesSnap) {
        if (!this.firstTimeActivitiesListener) {
            this.rules = rulesSnap.docs.map(a => a.data())
            this.rulesFetched = true
            if (!HousekeepingDashboard.COMPONENT_CONTROLS_DISPLAY) {
                this.prepareAreaData()
            }
        }
        this.firstTimeActivitiesListener = false
    }

    onActivitiesUpdate(activitiesSnap) {
        if (!this.firstTimeActivitiesListener) {
            this.activities = activitiesSnap.docs.map(a => a.data())
            this.activitesFetched = true
            if (!HousekeepingDashboard.COMPONENT_CONTROLS_DISPLAY) {
                this.prepareAreaData()
            }
        }
        this.firstTimeActivitiesListener = false
    }

    onBookingsUpdate(bookingsSnap) {
        if (!this.firstTimeBookingsListener) {
            this.bookings = bookingsSnap.docs.map(a => a.data())
            this.bookingsFetched = true
            if (!HousekeepingDashboard.COMPONENT_CONTROLS_DISPLAY) {
                this.prepareAreaData()
            }
        }
        this.firstTimeBookingsListener = false
    }

    onDailyCommentsUpdate(dailyCommentsSnap) {
        if (!this.firstTimeDailyCommentsListener) {
            this.dailyComments = dailyCommentsSnap.docs.map(a => a.data())
            this.addDailyCommentsAndNoteToAreas(this.dailyComments)
        }
        this.firstTimeDailyCommentsListener = false
    }

    onTasksUpdate(tasksSnap) {
        if (!this.firstTimeTasksListener) {
            this.allTasks = tasksSnap.docs.map(a => a.data())
            if (!HousekeepingDashboard.COMPONENT_CONTROLS_DISPLAY) {
                this.prepareAreaData()
            }
        }
        this.firstTimeTasksListener = false
    }

    async prepareAreaData() {
        let calcAreas
        if (this.areasFetched && this.activitesFetched && this.bookingsFetched) {
            calcAreas = await calculateCleaningStatus(
                firebase,
                this.allAreas,
                this.selectedDateNumber,
                this.props.currentOrganization,
                this.bookings,
                this.activities,
                this.rules
            )

            calcAreas.forEach(area => {
                let tasks = (this.allTasks ?? []).filter(t => t.areaKey === area.key)

                area.task = taskHelpers.findNewestTask(tasks)

                area.bookings = this.bookings
                    .filter(b => b.areaKey === area.key)
                    .sort((a, b) => helpers.sortTimeStampAscending(a.checkinDate, b.checkinDate))
                area.activities = this.activities
                    .filter(a => a.areaKey === area.key)
                    .sort((a, b) => helpers.sortTimeStampDescending(a.created, b.created))
                const priority = area.activities.filter(x => x.type === 'cleaning-priority')
                area.priority = priority && priority.length > 0 ? priority[0].change.after : false

                if (this.state.areaModalIsOpen && area.key === this.state.selectedArea.key) {
                    this.setState({ selectedArea: area })

                    if (this.state.selectedTask && this.state.selectedTask.startDate === this.selectedDateNumber) {
                        this.setState({ selectedTask: area.task })
                    }
                }
            })

            this.addDailyCommentsAndNoteToAreas(this.dailyComments)
            this.filterAndDisplayAreas(this.state.searchValue, calcAreas)
        }
    }

    componentWillUnmount() {
        this.stopListener()
        window.removeEventListener('resize', this.handleResizing)
    }

    addDailyCommentsAndNoteToAreas(dailyComments) {
        const areas = this.allAreas
        this.areasWithDailyComments = []
        this.areasWithNotes = []

        areas.forEach((a, index) => {
            const dailyComment = dailyComments?.find(dc => dc.areaKey === a.key)
            a.dailyComment = dailyComment ? dailyComment.comment : null

            if (a.dailyComment) {
                this.areasWithDailyComments.push(a)
            }

            const leadBooking = findLeadBooking(a, a.bookings, this.selectedDateNumber)
            areas[index].bookingNotes = leadBooking ? leadBooking.notes : null

            const bookingExtras = (a.bookings ?? [])
                .filter(x => x.extras && x.extras.length > 0)
                .map(x => x.extras)
                .flat()
                .filter(x => x.date === this.selectedDateNumber)
            const extraComment = bookingExtras && bookingExtras.map(x => `${x.quantity}x ${x.name}`).join(', ')
            if (extraComment) {
                areas[index].extra = extraComment
            }

            if (a.note && a.note !== '') {
                this.areasWithNotes.push(a)
            }
        })

        this.userAreas = ahelpers.filterByAreaGroupAccess(this.props.currentUser, areas)
        this.areasWithDailyComments = clone(helpers.sortAreas(this.areasWithDailyComments))
        this.areasWithNotes = clone(helpers.sortAreas(this.areasWithNotes))
    }

    onDateChange(selectedDate) {
        const date = moment(selectedDate).startOf('day')
        this.selectedDate = moment(selectedDate)
        this.selectedDateNumber = date.valueOf()
        this.setState({
            selectedDateNumber: this.selectedDateNumber,
            showDatePickerTodayButton: !chelpers.isToday(this.selectedDateNumber),
            showActivityIndicator: true
        })
        this.areasFetched = false
        this.bookingsFetched = false
        this.activitesFetched = false

        this.stopListener()
        setTimeout(() => {
            this.startListeners(this.selectedDateNumber)
            this.clearSelectedAreas()
        }, 250)
    }

    setAssignModeFilter(assignModeFilter) {
        this.assignModeFilter = assignModeFilter
        if (this.assignModeFilterTimeout) {
            clearTimeout(this.assignModeFilterTimeout)
        }

        this.setState({ showActivityIndicator: true })

        this.assignModeFilterTimeout = setTimeout(() => {
            this.filterAndDisplayAreas(this.state.searchValue, this.allAreas)
        }, ON_PRESS_TIMEOUT)
    }

    setCleaningStatusFilter(cleaningStatusFilter, shouldRender = false) {
        const cleaningStatusFilterString = JSON.stringify(cleaningStatusFilter)
        AsyncStorage.setItem(uc.DASHBOARD_CLEANING_STATUS_FILTER, cleaningStatusFilterString)
        if (shouldRender) {
            this.setState({
                cleaningStatusFilter,
                showActivityIndicator: true
            })
            if (this.cleaningStatusTimeout) {
                clearTimeout(this.cleaningStatusTimeout)
            }

            this.cleaningStatusTimeout = setTimeout(() => {
                this.filterAndDisplayAreas(this.state.searchValue, this.allAreas)
            }, ON_PRESS_TIMEOUT)
        }
    }

    filterByAssigned(areas, assignModeFilter) {
        if (assignModeFilter === c.ASSIGN_MODE_FILTER_ALL) {
            return areas
        }
        let filteredAreas = areas === null ? this.allAreas : areas

        filteredAreas = filteredAreas.filter(area => {
            if (area.task && assignModeFilter === c.ASSIGN_MODE_FILTER_ASSIGNED) {
                return true
            } else if (area.task && assignModeFilter === c.ASSIGN_MODE_FILTER_UN_ASSIGNED) {
                return false
            } else if (area.task === null && assignModeFilter === c.ASSIGN_MODE_FILTER_ASSIGNED) {
                return false
            } else return area.task === null && assignModeFilter === c.ASSIGN_MODE_FILTER_UN_ASSIGNED
        })
        return filteredAreas
    }

    getContainerWidth() {
        if (uc.INSIDE_IFRAME) return window.innerWidth - 50
        else if (window.innerWidth <= 500) {
            return window.innerWidth - 30
        } else if (window.innerWidth >= 505 && window.innerWidth < 540) {
            return window.innerWidth - (2.25 * 12 + 30)
        } else if (window.innerWidth >= 540 && window.innerWidth < 720) {
            return window.innerWidth - (2.25 * 12 + 30)
        } else if (window.innerWidth >= 720 && window.innerWidth < 768) {
            return window.innerWidth - (2.25 * 12 + 30)
        } else if (window.innerWidth >= 768 && window.innerWidth < 960) {
            const tilesPerRow = Math.floor((window.innerWidth - (2.25 * 12 + 30)) / 110) + 1
            return tilesPerRow * 110 + (2.25 * 12 + 30)
        } else if (window.innerWidth >= 960 && window.innerWidth < 1200) {
            return 960 - 100
        } else if (window.innerWidth >= 1200 && window.innerWidth < 1440) {
            return window.innerWidth - (2.25 * 12 + 30)
        } else {
            return window.innerWidth - 250
        }
    }

    filterAndDisplayAreas(searchValue, areas) {
        const tilesPerRow = this.getContainerWidth() / 110
        const tilesWidthTotal = 100 * Math.floor(tilesPerRow)
        const gutter = this.getContainerWidth() - tilesWidthTotal

        this.numCols = Math.floor(tilesPerRow)
        if (!uc.INSIDE_IFRAME) {
            this.numCols -= 2
        }
        this.tilesGutter = Math.floor(gutter / (this.numCols + 1) - 1)

        if (this.getContainerWidth() - (this.numCols * 100 + this.numCols * this.tilesGutter) < 0) {
            this.numCols = this.numCols - 1
            this.tilesGutter = this.tilesGutter + 4
        }

        let filteredAreas = ahelpers.filterByAreaGroupAccess(this.props.currentUser, areas)
        filteredAreas = helpers.search(searchValue, filteredAreas, helpers.areaSearchSet)
        filteredAreas = this.filterByOccupancy(filteredAreas, this.occupancyFilter)
        filteredAreas = this.filterByAssigned(filteredAreas, this.assignModeFilter)
        filteredAreas = this.filterByCleaningStatus(filteredAreas)
        filteredAreas = helpers.sortAreas(filteredAreas)
        const countedAreaGroups = this.getCountedAreaGroups(filteredAreas)

        const priorityAreas = filteredAreas.filter(area => area.priority)
        filteredAreas = this.priorityFilter ? priorityAreas : filteredAreas

        this.totalUnclean50TaskCount = filteredAreas.filter(
            area => area.occupancy === c.OCCUPANCY_STAYOVER && area.cleaningStatus !== CLEANING_STATUS_NO_SERVICE
        ).length
        this.totalUnclean80TaskCount = filteredAreas.filter(
            area => area.occupancy === c.OCCUPANCY_STAYOVER_80 && area.cleaningStatus !== CLEANING_STATUS_NO_SERVICE
        ).length
        const checkOutCount = filteredAreas.filter(area => area.occupancy === c.OCCUPANCY_CHECKOUT).length
        const turnoverCount = filteredAreas.filter(area => area.occupancy === c.OCCUPANCY_TURNOVER).length
        this.totalUnclean100TaskCount = checkOutCount + turnoverCount
        this.totalCheckinTaskCount = filteredAreas.filter(
            area => area.occupancy === c.OCCUPANCY_CHECKIN || area.occupancy === c.OCCUPANCY_TURNOVER
        ).length
        this.totalNoServiceTaskCount = filteredAreas.filter(area => area.cleaningStatus === c.CLEANING_STATUS_NO_SERVICE).length

        this.setState({
            searchValue,
            areas: filteredAreas,
            priorityFilter: this.priorityFilter,
            priorityAreaCounter: priorityAreas.length,
            countedAreaGroups,
            dataIsReady: true,
            showActivityIndicator: false
        })
    }

    closeAreaModal() {
        this.setState({ areaModalIsOpen: false })
    }

    filterByCleaningStatus(areas) {
        let filteredAreas = areas
        if (this.state.cleaningStatusFilter[0].isSelected) {
            return filteredAreas
        } else {
            filteredAreas = filteredAreas.filter(area =>
                this.state.cleaningStatusFilter.some(option => option.isSelected && option.value === area.cleaningStatus)
            )
            return filteredAreas
        }
    }

    formatAreasBySection(areas) {
        const areaSections = {}

        areas = areas.sort((a, b) => {
            a.group = a.group ? a.group : a.address
            b.group = b.group ? b.group : b.address
            return a.group.toLowerCase() > b.group.toLowerCase()
        })

        areas.forEach(area => {
            if (areaSections[area.group] === undefined || areaSections[area.group].length === 0) {
                areaSections[area.group] = []
            }
            areaSections[area.group].push(area)
        })

        return Object.keys(areaSections).map(key => {
            const sortedAreas = helpers.sortAreas(areaSections[key])
            const section = { title: key, data: sortedAreas }

            section.count = section.data.length
            section.unclean50TaskCount = section.data.filter(
                area => area.occupancy === c.OCCUPANCY_STAYOVER && area.cleaningStatus !== CLEANING_STATUS_NO_SERVICE
            ).length
            section.unclean80TaskCount = section.data.filter(
                area => area.occupancy === c.OCCUPANCY_STAYOVER_80 && area.cleaningStatus !== CLEANING_STATUS_NO_SERVICE
            ).length
            const checkOutCount = section.data.filter(area => area.occupancy === c.OCCUPANCY_CHECKOUT).length
            const turnoverCount = section.data.filter(area => area.occupancy === c.OCCUPANCY_TURNOVER).length
            section.unclean100TaskCount = checkOutCount + turnoverCount
            section.checkinTaskCount = section.data.filter(
                area => area.occupancy === c.OCCUPANCY_CHECKIN || area.occupancy === c.OCCUPANCY_TURNOVER
            ).length
            section.noServiceTaskCount = section.data.filter(area => area.cleaningStatus === c.CLEANING_STATUS_NO_SERVICE).length
            return section
        })
    }

    getCountedCleaningStatus(areas, cleaningStatusFilter) {
        const result = JSON.parse(JSON.stringify(cleaningStatusFilter))
        result.map(o => (o.count = 0))

        areas.forEach(area => {
            const index = result.findIndex(option => option.value === area.cleaningStatus)
            if (index !== -1) {
                result[index].count++
            }
        })
        let allCount = 0
        result.map(o => (allCount = allCount + o.count))
        result[0].count = allCount

        return result
    }

    setOccupancyFilter(nextOccupancy) {
        this.occupancyFilter = nextOccupancy
        AsyncStorage.setItem('cleaning-occupancy-filter', nextOccupancy)
        this.setState({ showActivityIndicator: true, occupancyFilter: nextOccupancy })

        this.filterAndDisplayAreas(this.state.searchValue, this.allAreas)
    }

    filterByOccupancy(areas, occupancyFilter) {
        let filteredAreas = areas !== null ? areas : this.allAreas
        filteredAreas = filteredAreas.filter(area => {
            if (occupancyFilter === c.OCCUPANCY_ALL) {
                return true
            }
            if (
                occupancyFilter === c.OCCUPANCY_CHECKIN &&
                (area.occupancy === c.OCCUPANCY_TURNOVER || area.occupancy === c.OCCUPANCY_CHECKIN)
            ) {
                return true
            }
            if (
                occupancyFilter === c.OCCUPANCY_CHECKOUT &&
                (area.occupancy === c.OCCUPANCY_TURNOVER || area.occupancy === c.OCCUPANCY_CHECKOUT)
            ) {
                return true
            }
            return occupancyFilter === area.occupancy
        })

        return filteredAreas
    }

    getCountedAreaGroups(areas) {
        let countedAreaGroups = []
        const hashWithCount = {}
        let longestAreaName = 0

        areas.forEach(area => {
            longestAreaName = area.name.length > longestAreaName ? area.name.length : longestAreaName
            hashWithCount[area.group] = hashWithCount[area.group] === undefined ? 1 : hashWithCount[area.group] + 1
        })

        Object.keys(hashWithCount).forEach(key => {
            countedAreaGroups.push({
                name: key,
                count: hashWithCount[key]
            })
        })

        countedAreaGroups = countedAreaGroups.sort((a, b) => b.count - a.count)

        return countedAreaGroups
    }

    onSearch(value) {
        this.filterAndDisplayAreas(value, this.allAreas)
        AsyncStorage.setItem('cleaning-search-value', value)
    }

    onStatusPress() {
        this.filterAndDisplayAreas(this.state.searchValue, this.allAreas)
    }

    renderNoAreasFound() {
        if (this.state.dataIsReady && this.state.areas.length === 0) {
            let text = 'Fetching areas...'
            if (this.state.dataIsReady) {
                text = 'No areas found'
            }
            const IMG_SCALE = 1.2
            return (
                <Row
                    center="xs"
                    middle="xs"
                    style={{
                        marginTop: 50
                    }}>
                    <View testID="no-areas" style={{ alignItems: 'center' }}>
                        <Image
                            style={{ opacity: 0.8, width: 148 * IMG_SCALE, height: 121 * IMG_SCALE }}
                            source={require('@shared/img/no-areas-hotel-bed.svg')}
                        />
                        <span
                            style={{
                                marginTop: 6,
                                fontSize: 20,
                                fontWeight: '500',
                                color: iOSColors.gray
                            }}>
                            {text}
                        </span>
                        {this.state.dataIsReady && (
                            <span
                                style={{
                                    marginTop: 12,
                                    fontSize: 15,
                                    fontWeight: '400',
                                    color: iOSColors.gray
                                }}>
                                Try a new search or
                            </span>
                        )}
                        {this.state.dataIsReady && (
                            <span
                                style={{
                                    marginTop: 9,
                                    fontSize: 15,
                                    fontWeight: '400',
                                    color: iOSColors.gray
                                }}>
                                change the filters
                            </span>
                        )}
                    </View>
                </Row>
            )
        }
        return null
    }

    onPriorityPressTimeout(nextPriority) {
        this.setState({
            showActivityIndicator: true
        })

        if (this.priorityPressTimeout) {
            clearTimeout(this.priorityPressTimeout)
        }
        this.priorityPressTimeout = setTimeout(() => this.onPriorityPress(nextPriority), 500)
    }

    onPriorityPress(nextPriority) {
        this.priorityPressTimeout = null
        this.priorityFilter = nextPriority
        this.setState({ showActivityIndicator: true })
        AsyncStorage.setItem(uc.DASHBOARD_PRIORITY_FILTER, nextPriority)

        this.filterAndDisplayAreas(this.state.searchValue, this.allAreas)
    }

    openSelectUserModal() {
        this.setState({ selectUserModalIsOpen: true })
    }

    getAreaToday() {
        return this.areaToday
    }

    closeDailyCommentsSummary() {
        this.setState({ showDailyCommentsSummary: false })
    }

    openDailyCommentsSummary() {
        this.setState({ showDailyCommentsSummary: true })
    }

    closeUpgradeMessage() {
        this.setState({ showUpgradeMessage: false })
    }

    closeQuickGuide() {
        this.setState({ showQuickGuide: false })
    }

    openQuickGuide() {
        this.setState({ showQuickGuide: true })
    }

    openUpgradeMessage(notAccessibleFeature, availableUpgrades) {
        this.setState({
            showUpgradeMessage: true,
            availableUpgrades: availableUpgrades || this.availableUpgrades,
            notAccessibleFeature: notAccessibleFeature || this.notAccessibleFeature
        })
    }

    render() {
        const steps = [
            {
                disableBeacon: true,
                target: '.joyride-1',
                title: 'The Housekeeping Quick Guide',
                content: 'explains colors and icons',
                placement: 'bottom',
                offset: -10
            }
        ]

        const DEBUG_BORDER = 0

        const workloadCounts = {
            unclean50Count: this.totalUnclean50TaskCount,
            unclean80Count: this.totalUnclean80TaskCount,
            unclean100Count: this.totalUnclean100TaskCount,
            checkInCount: this.totalCheckinTaskCount,
            noServiceCount: this.totalNoServiceTaskCount
        }

        const IS_ASSIGN_MODE =
            this.state.showActionBar &&
            userIsAllowed(
                'assign-housekeeping-assign',
                this.props.currentUser,
                this.props.currentOrganization,
                this.context.currentSubscription
            )

        return (
            <div>
                <Joyride
                    tooltipComponent={JoyrideTooltip}
                    steps={steps}
                    run={this.state.showJoyride}
                    styles={{
                        options: {
                            arrowColor: '#fff',
                            backgroundColor: '#fff',
                            primaryColor: brand.getBrand().navBarColor,
                            textColor: 'red',
                            width: undefined,
                            zIndex: 10000
                        }
                    }}
                />
                <Card className="card-no-shadow">
                    <CardHeader className="title-x-x" title="Housekeeping" count={this.state.areas.length}>
                        <CardHeaderCounter className="joyride-2">
                            <Workload
                                fontSize={15}
                                fontWeight={'500'}
                                color={iOSColors.black}
                                opacity={0.6}
                                counts={workloadCounts}
                                tooltipDisabled={true}
                            />
                        </CardHeaderCounter>

                        <div
                            style={{ marginLeft: 9, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            className="joyride-1">
                            {this.state.dataIsReady && (
                                <HoverableImageButton
                                    dimension={22}
                                    hoverOpacity={uc.HOVER_OPACITY}
                                    imageSource={require('../../img/quick-guide/quick-guide-button.svg')}
                                    imgStyle={{ marginTop: 4, height: 22, width: 22 }}
                                    opacity={0.4}
                                    onPress={() => {
                                        this.setState({ showJoyride: false })
                                        this.openQuickGuide()
                                    }}
                                    tooltipText={'Quick guide'}
                                />
                            )}
                        </div>
                        <div className="col d-flex justify-content-end align-items-center">
                            <SubscriptionCheck
                                featureId="assign-housekeeping"
                                permissionId="assign-housekeeping-assign"
                                onAvailable={() => (
                                    <MainActionButton type="primary" size="large" onClick={this.pickAssignMode}>
                                        Assign Staff
                                    </MainActionButton>
                                )}
                                onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                                    return (
                                        <MainActionButton
                                            type="primary"
                                            size="large"
                                            onClick={() => {
                                                this.openUpgradeMessage(notAccessibleFeature, availableUpgrades)
                                            }}>
                                            Assign Staff
                                        </MainActionButton>
                                    )
                                }}
                            />
                        </div>
                    </CardHeader>
                    {!this.state.areaModalIsOpen && <Notifications options={{ animationDuration: 1000, top: '10%' }} />}

                    <UpgradeModal show={this.state.isShowNotAccessModal} />

                    <Modal
                        isOpen={this.state.showQuickGuide}
                        style={styles.quickGuideStyle}
                        contentLabel="QuickGuide"
                        onRequestClose={this.closeQuickGuide}>
                        <QuickGuide close={this.closeQuickGuide} />
                    </Modal>
                    <Modal
                        isOpen={this.state.showUpgradeMessage}
                        style={styles.upgradeMessageStyle}
                        contentLabel="UpgradeMessages"
                        onRequestClose={this.closeUpgradeMessage}>
                        <UpgradeMessageContent
                            onClose={this.closeUpgradeMessage}
                            notAccessibleFeature={this.state.notAccessibleFeature}
                            availableUpgrades={this.state.availableUpgrades}
                            showCloseButton={true}
                        />
                    </Modal>
                    <Modal
                        isOpen={this.state.showDailyCommentsSummary}
                        style={this.areaModalStyle}
                        contentLabel="DailyCommentsSummary"
                        onRequestClose={this.closeDailyCommentsSummary}>
                        <DailyCommentsSummary
                            style={this.areaModalStyle}
                            ref={ref => (this.dailyCommentSummaryModal = ref)}
                            show={this.state.showDailyCommentsSummary}
                            closeModal={this.closeDailyCommentsSummary}
                            areasWithDailyComments={this.areasWithDailyComments}
                            areasWithNotes={this.areasWithNotes}
                            areas={ahelpers.filterByAreaGroupAccess(this.props.currentUser, this.allAreas)}
                            selectedDate={this.state.selectedDateNumber}
                        />
                    </Modal>
                    <Modal
                        isOpen={this.state.areaModalIsOpen}
                        style={this.areaModalStyle}
                        contentLabel="Area"
                        onRequestClose={this.closeAreaModal}>
                        <HousekeepingDetail
                            allBookings={this.bookings}
                            ref={ref => (this.HousekeepingDetail = ref)}
                            height={this.areaModalStyle.content.maxHeight}
                            style={this.areaModalStyle}
                            area={JSON.parse(JSON.stringify(this.state.selectedArea))}
                            task={this.state.selectedTask}
                            assignedTo={
                                this.state.selectedTask && this.state.selectedTask.assignedTo
                                    ? this.state.selectedTask.assignedTo
                                    : [{ name: 'empty' }]
                            }
                            findNewestTask={this.findNewestTask}
                            openSelectUserModal={this.openSelectUserModal}
                            getAreaToday={this.getAreaToday}
                            closeModal={this.closeAreaModal}
                            currentUser={this.props.currentUser}
                            currentOrganization={this.props.currentOrganization}
                            selectedDateNumber={this.state.selectedDateNumber}
                            onUnassignPress={this.onHouskeepingModalUnassign}
                            unassignDisabled={HousekeepingDashboard.COMPONENT_CONTROLS_DISPLAY}
                            defaultTaskName={this.cleaningTaskName}
                            onAssignPress={this.onUserListAssign}
                            setCurrentTask={task => this.setState({ selectedTask: task })}
                            cleaningTaskName={this.cleaningTaskName}
                            currentSubscription={this.context.currentSubscription}
                            removedDueDateBtn
                        />
                    </Modal>
                    <Modal
                        isOpen={this.state.selectUserModalIsOpen}
                        onRequestClose={this.closeSelectUserModal}
                        style={styles.userListStyle}
                        contentLabel="User">
                        <UsersList
                            areas={this.state.selectedAreas}
                            closeModal={this.closeSelectUserModal}
                            currentUser={this.props.currentUser}
                            onAssignPress={this.onUserListAssign}
                        />
                    </Modal>
                    <View style={{ zIndex: 100, borderWidth: DEBUG_BORDER }}>
                        {this.state.showActionBar && (
                            <PermissionCheck
                                permissionId="assign-housekeeping-assign"
                                onAllowed={() => (
                                    <AssignActionBar
                                        currentOrganization={this.props.currentOrganization}
                                        showActivityIndicator={this.state.showActivityIndicator}
                                        top={this.state.actionBarYposition}
                                        height={CLEANING_ACTION_BAR_HEIGHT}
                                        currentUser={this.props.currentUser}
                                        allAreas={this.allAreas}
                                        closeAssignMode={this.closeAssignMode}
                                        clearSelectedAreas={this.clearSelectedAreas}
                                        selectedAreas={this.state.selectedAreas}
                                        onUnassignPress={this.onUnassignPress}
                                        onAssignPress={this.onAssignPress}
                                        openSelectUserModal={this.openSelectUserModal}
                                        toastMessage={PLEASE_SELECT_AREA_MESSAGE}
                                        inputCleaningTaskName={this.state.inputCleaningTaskName}
                                        setInputCleaningTaskName={this.setInputCleaningTaskName}
                                        setCleaningTaskName={this.setCleaningTaskName}
                                        setAssignModeFilter={this.setAssignModeFilter}
                                        assignModeFilter={this.state.assignModeFilter}
                                        selectedDateNumber={this.state.selectedDateNumber}
                                        selectAllAreas={this.selectAllAreas}
                                        onAssignLastPress={() => {
                                            if (this.lastAssignedUsers) {
                                                this.onAssignPress(this.lastAssignedUsers)
                                            } else {
                                                notify.show(
                                                    <Toast
                                                        width={180}
                                                        backgroundColor={colors.green_spectaflow}
                                                        message="No last assignment found, first use Assign!"
                                                    />,
                                                    'custom',
                                                    3000,
                                                    {}
                                                )
                                            }
                                        }}
                                    />
                                )}
                            />
                        )}
                    </View>
                    <div>
                        {this.state.showActivityIndicator && (
                            <View
                                style={{
                                    position: 'absolute',
                                    top: '30vh',
                                    left: '40vw',
                                    right: 'auto',
                                    alignItems: 'center',
                                    marginTop: 110,
                                    marginBottom: 110,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    zIndex: 1000
                                }}>
                                <ActivityIndicator size="large" color={brand.getBrand().navBarColor} style={{}} />
                            </View>
                        )}
                    </div>
                    <CardBody className="p-xs-10">
                        <DashboardFilter
                            selectedDate={this.selectedDate}
                            selectedDateNumber={this.state.selectedDateNumber}
                            onDateChange={this.onDateChange}
                            showDatePickerTodayButton={this.state.showDatePickerTodayButton}
                            searchValue={this.state.searchValue}
                            onPriorityPressTimeout={this.onPriorityPressTimeout}
                            priorityFilter={this.state.priorityFilter}
                            priorityAreaCounter={this.state.priorityAreaCounter}
                            openDailyCommentsSummary={this.openDailyCommentsSummary}
                            areasWithDailyComments={this.areasWithDailyComments}
                            setCleaningStatusFilter={this.setCleaningStatusFilter}
                            defaultStatusFilter={this.defaultStatusFilter}
                            cleaningStatusFilter={this.state.cleaningStatusFilter}
                            setOccupancyFilter={this.setOccupancyFilter}
                            occupancyFilter={this.state.occupancyFilter}
                            showActionBar={this.state.showActionBar}
                            pickAssignMode={this.pickAssignMode}
                            currentOrganization={this.props.currentOrganization}
                            openUpgradeMessage={this.openUpgradeMessage}
                            onSearch={this.onSearch}
                            openQuickGuide={this.openQuickGuide}
                        />

                        {/* <View style={{ zIndex: 9999 }}>{this.renderSearchAndFiltersSection()}</View> */}
                        <div className="row row-paddingless" style={{ position: 'relative', zIndex: 1 }}>
                            <div className="col-xs-12">
                                <ButtonsBar
                                    type={'housekeeping-dashboard'}
                                    countedItems={this.state.countedAreaGroups}
                                    onClick={this.onSearch}
                                    respWidth={window.innerWidth * 0.8}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '24px' }} className="row row-paddingless">
                            {!this.state.dataIsReady && <Loader />}
                            {this.state.showSections &&
                                !this.state.showActivityIndicator &&
                                this.formatAreasBySection(this.state.areas).map((section, index) => {
                                    return (
                                        <Wrapper key={section.title + index}>
                                            <label
                                                onClick={() => {
                                                    if (IS_ASSIGN_MODE) {
                                                        const allSelectedInGroup = this.state.selectedAreas.filter(
                                                            area => section.data.filter(x => x.key === area.key).length > 0
                                                        )
                                                        section.data.forEach(area => {
                                                            if (allSelectedInGroup.length > 0) {
                                                                this.removeSelectedArea(area)
                                                            } else {
                                                                this.addSelectedArea(area)
                                                            }
                                                        })
                                                    }
                                                }}
                                                style={{
                                                    cursor: IS_ASSIGN_MODE ? 'pointer' : 'default'
                                                }}>
                                                <label
                                                    style={{
                                                        cursor: IS_ASSIGN_MODE ? 'pointer' : 'default'
                                                    }}>
                                                    {section.title}
                                                    <span>{` (${section.count})`}</span>
                                                </label>
                                                <Workload
                                                    fontSize={14}
                                                    fontWeight={'500'}
                                                    color={iOSColors.gray}
                                                    counts={{
                                                        unclean50Count: section.unclean50TaskCount,
                                                        unclean80Count: section.unclean80TaskCount,
                                                        unclean100Count: section.unclean100TaskCount,
                                                        checkInCount: section.checkinTaskCount,
                                                        noServiceCount: section.noServiceTaskCount
                                                    }}
                                                    tooltipDisabled={true}
                                                />
                                            </label>

                                            <AreasContainer>
                                                {section.data.map((area, index) =>
                                                    this.renderArea(area, index === section.data.length - 1)
                                                )}
                                            </AreasContainer>
                                        </Wrapper>
                                    )
                                })}
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }

    renderSectionHeader(
        title,
        count,
        unclean50TaskCount,
        unclean80TaskCount,
        unclean100TaskCount,
        checkinTaskCount,
        noServiceTaskCount,
        areas
    ) {
        const workloadCounts = {
            unclean50Count: unclean50TaskCount,
            unclean80Count: unclean80TaskCount,
            unclean100Count: unclean100TaskCount,
            checkInCount: checkinTaskCount,
            noServiceCount: noServiceTaskCount
        }

        const DEBUG_BORDER = 0
        const IS_ASSIGN_MODE = this.state.showActionBar

        return (
            <View
                style={{
                    flexDirection: 'row',
                    backgroundColor: 'white',

                    borderWidth: DEBUG_BORDER,
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    paddingTop: 12,
                    paddingBottom: 12,
                    marginBottom: 0,
                    cursor: IS_ASSIGN_MODE ? 'pointer' : 'default'
                }}
                onClick={() => {
                    if (IS_ASSIGN_MODE) {
                        const allSelectedInGroup = this.state.selectedAreas.filter(area => areas.filter(x => x.key === area.key).length > 0)
                        areas.forEach(area => {
                            if (allSelectedInGroup.length > 0) {
                                this.removeSelectedArea(area)
                            } else {
                                this.addSelectedArea(area)
                            }
                        })
                    }
                }}>
                <div style={{ flexDirection: 'row', borderWidth: DEBUG_BORDER }} className="d-flex align-items-baseline">
                    <span
                        style={{
                            borderWidth: DEBUG_BORDER,
                            color: iOSColors.gray
                        }}
                        className="font-size-h6 font-weight-bolder mr-1">
                        {title}
                    </span>
                    <span
                        style={{
                            borderWidth: DEBUG_BORDER,
                            marginTop: 0,
                            fontSize: 13,
                            color: iOSColors.gray
                        }}>
                        {' (' + count + ')'}
                    </span>
                </div>

                <View style={{ flexDirection: 'row', borderWidth: DEBUG_BORDER, marginLeft: 16 }}>
                    <Workload fontSize={14} fontWeight={'500'} color={iOSColors.gray} counts={workloadCounts} tooltipDisabled={true} />
                </View>
            </View>
        )
    }

    renderArea(area, isLast) {
        let selectedIndex
        if (this.state.areaModalIsOpen) {
            selectedIndex = -1
        } else {
            selectedIndex = this.state.selectedAreas.findIndex(a => a.key === area.key)
        }

        let selected
        selected = selectedIndex !== -1
        this.ITEM_HEIGHT = 100
        this.ITEM_WIDTH = 100

        let gutter = this.tilesGutter

        if (isLast) {
            gutter = 0
        }

        const onSinglePress = area => {
            if (this.state.hasSubscriptionAccess || area.synced) {
                this.areaToday = this.allAreas.find(a => a.key === area.key)
                this.setState({
                    areaModalIsOpen: true,
                    selectedArea: area,
                    selectedTask: area.task
                })
            } else {
                this.openUpgradeMessage(this.state.notAccessibleFeature, this.state.availableUpgrades)
            }
        }

        return (
            <HousekeepingBox
                context={c.CONTEXT_WEB_APP_CLEANING}
                key={area.key + area.updated}
                height={this.ITEM_HEIGHT}
                width={this.ITEM_WIDTH}
                selectedDateNumber={this.state.selectedDateNumber}
                assignMode={this.state.showActionBar}
                problems={(area.bookings?.length ?? 0) > 2 && this.props.currentUser.authSuper}
                area={area}
                tilesGutter={gutter}
                onSinglePress={onSinglePress}
                onSearchPress={this.onSearch}
                selected={selected}
                addSelectedArea={this.onAssignActionBarPressArea}
                removeSelectedArea={this.removeSelectedArea}
                hasSubscriptionAccess={this.state.hasSubscriptionAccess}
                onPressDisabled={area.dormant}
                withoutMargin={true}
            />
        )
    }

    closeSelectUserModal() {
        this.setState({
            selectUserModalIsOpen: false
        })
    }

    async pickAssignMode() {
        if (this.state.showActionBar) {
            this.closeAssignMode()
        } else {
            this.setShowActionBar(true)
        }
    }

    closeAssignMode() {
        this.clearSelectedAreas()

        this.setState({ showActionBar: false })
        if (this.assignModeFilter === c.ASSIGN_MODE_FILTER_ALL) {
            this.filterAndDisplayAreas(this.state.searchValue, this.allAreas)
        } else {
            this.setAssignModeFilter(c.ASSIGN_MODE_FILTER_ALL)
        }
    }

    setShowActionBar() {
        this.setState({ showActionBar: true })
    }

    onAssignActionBarPressArea(area) {
        this.addSelectedArea(area)
        const actionBarYposition = window.innerHeight - CLEANING_ACTION_BAR_HEIGHT

        this.setState({
            actionBarYposition
        })
    }

    addSelectedArea(area, updateDisplay = true) {
        this.selectedAreas.push(area)
        if (updateDisplay) {
            this.setState({ selectedAreas: this.selectedAreas })
        }
    }

    removeSelectedArea(area, updateDisplay = true) {
        this.selectedAreas = this.selectedAreas.filter(item => item.key !== area.key)
        if (updateDisplay) {
            this.setState({
                selectedAreas: this.selectedAreas
            })
        }
    }

    clearSelectedAreas(updateDisplay = true) {
        this.selectedAreas = []
        if (updateDisplay) {
            this.setState({ selectedAreas: [] })
        }
    }

    selectAllAreas() {
        this.selectAreas = [...this.state.areas]
        this.setState({ selectedAreas: [...this.state.areas] })
    }

    setCleaningTaskName(title) {
        this.cleaningTaskName = title
    }

    setInputCleaningTaskName(title) {
        this.setState({ inputCleaningTaskName: title })
    }

    startComponentUIControl() {
        HousekeepingDashboard.COMPONENT_CONTROLS_DISPLAY = true
        setTimeout(() => {
            HousekeepingDashboard.COMPONENT_CONTROLS_DISPLAY = false
            this.prepareAreaData()
        }, 4500)
    }

    onUserListAssign(assignedUsers, selectedDateNumber = this.selectedDateNumber) {
        this.lastAssignedUsers = assignedUsers
        if (this.state.areaModalIsOpen) {
            this.addSelectedArea(this.state.selectedArea)
        }
        AsyncStorage.setItem('cleaning-last-assigned-users', JSON.stringify(this.lastAssignedUsers))

        setTimeout(() => this.onAssignPress(assignedUsers, selectedDateNumber))
    }

    onHouskeepingModalUnassign() {
        if (this.state.areaModalIsOpen) {
            this.addSelectedArea(this.state.selectedArea)
        }

        setTimeout(() => this.onUnassignPress())
    }

    async onAssignPress(assignedUsers, selectedDateNumber = this.state.selectedDateNumber) {
        this.startComponentUIControl()
        this.closeSelectUserModal()
        AsyncStorage.setItem('cleaning-cleaning-task-name', this.cleaningTaskName)

        const selectedAreasCopy = JSON.parse(JSON.stringify(this.state.selectedAreas))

        const assignedToMiniObj = assignedUsers.map(a => dataObjects.getMiniUserObject(a))

        // For quicker UI update we loop twice
        this.state.selectedAreas.forEach(area => {
            if (area.task) {
                area.task.assignedTo = assignedToMiniObj
                area.task.areaKey = area.key
                area.task.initials = assignedUsers.map(u => u.initials).join(', ')
            } else {
                area.task = {
                    key: 'virtual-task',
                    assignedTo: assignedToMiniObj,
                    areaKey: area.key,
                    initials: assignedUsers.map(u => u.initials).join(', '),
                    startDate: selectedDateNumber
                }
            }
            if (this.state.areaModalIsOpen && area.key === this.state.selectedArea.key) {
                const updatedArea = JSON.parse(JSON.stringify(area))
                this.setState({ selectedArea: updatedArea, selectedTask: area.task })
            }
        })

        setTimeout(() => {
            selectedAreasCopy.map(async area => {
                const changeObj = {
                    before: 'assigned',
                    after: this.createNamesForActivity(assignedUsers)
                }
                await areaData.logActivity(firebase, this.props.currentUser, area.key, 'assignment', selectedDateNumber, changeObj)

                let cleaningTaskName = area.ruleName || this.cleaningTaskName

                const checklist =
                    area.activeRule && area.activeRule.checklistTasks
                        ? area.activeRule.checklistTasks.map((item, index) => {
                              return {
                                  name: item,
                                  checked: false
                              }
                          })
                        : null

                if (area.task && area.task.key !== 'virtual-task') {
                    const taskUpdateObject = {
                        assignedTo: assignedToMiniObj,
                        priority: area.task.priority,
                        name: cleaningTaskName,
                        startDate: selectedDateNumber
                    }
                    await taskData.setTaskUpdate(firebase, this.props.currentUser, area.task.key, taskUpdateObject)
                } else {
                    await taskData.createTask(
                        firebase,
                        area,
                        this.props.currentUser,
                        c.TASK_TYPE_HOUSEKEEPING,
                        {
                            assignedTo: assignedUsers,
                            startDate: selectedDateNumber,
                            name: cleaningTaskName
                        },
                        null,
                        null,
                        checklist
                    )
                }
            })
        }, 400)

        this.clearSelectedAreas(true)
        this.setInputCleaningTaskName('')
    }

    onUnassignPress() {
        const selectedAreasCopy = JSON.parse(JSON.stringify(this.state.selectedAreas))

        this.state.selectedAreas.forEach(async area => {
            if (this.state.areaModalIsOpen && area.key === this.state.selectedArea.key) {
                const updatedArea = JSON.parse(JSON.stringify(area))
                updatedArea.task = null
                this.setState({ selectedArea: updatedArea, selectedTask: null })
            }

            if (area.task) {
                area.task = null
            }
        })

        this.startComponentUIControl()

        selectedAreasCopy
            .filter(area => area.task)
            .forEach(async area => {
                const changeObj = {
                    before: 'unassigned'
                }
                await areaData.logActivity(
                    firebase,
                    this.props.currentUser,
                    area.key,
                    'assignment',
                    this.state.selectedDateNumber,
                    changeObj
                )
                await taskData.unassignTask(firebase, this.props.currentUser, area.task.key)
            })

        this.clearSelectedAreas()
    }

    createNamesForActivity(assignedUsers) {
        let result = ''
        assignedUsers.forEach((user, index) => {
            result += user.name
            if (index !== assignedUsers.length - 1) {
                result += ', '
            }
        })
        return result
    }
}

import React from 'react'
import Mouth from './components/ErrorMessage/Mouth'
import { ErrorImageWrap, ErrorMessageWrap } from './components/ErrorMessage/style'
import { ErrorMessage } from './error/ErrorMessage'

export function OrganizationSuspended() {
    const title = 'Subscription Expired'

    return (
        <div style={{ textAlign: 'center', justifyContent: 'center' }}>
            <ErrorMessage showBacklink={false} title={title} message="" notLoggedIn={false} />
            <ErrorMessageWrap>
                <ErrorImageWrap>
                    <div className="eye-1" />
                    <div className="eye-2" />
                    <Mouth />
                </ErrorImageWrap>
            </ErrorMessageWrap>
        </div>
    )
}

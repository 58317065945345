import { AreaStruct, BookingStruct, OrgStruct, UserStruct } from '@shared/firestore-structs'
import { currentOrganizationAtom, currentUserAtom } from 'app/modules/Auth/atoms'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { cleaningScheduleSectionsSelector, leadBookingSelector } from '../state/selectors/calculatedAreaData'
import { areaAtom, bookingsAtom } from '../state/atoms'
import { Description, PlanningInfoWrap, Wrap } from './style'
import { SectionTitleWrap } from '../style'
import { PlanningInfo } from 'app/modules/Guests/GuestHome/pages/CleaningSchedule/PlanningInfo'
import MonthSection from './MonthSection'
import { updateCleaningSchedule } from '../../api'
import { toast } from 'react-toastify'
import { toastErrorStyle } from 'app/utils/styles'
import AlertPortal from 'app/components/alerts/AlertPortal'
import { selectedDateNumberSelector } from '../state/selectors/selectedDateNumber'

function CleaningScheduleSection() {
    const currentOrganization = useRecoilValue(currentOrganizationAtom) as OrgStruct
    const currentUser = useRecoilValue(currentUserAtom) as UserStruct
    const area = useRecoilValue(areaAtom) as AreaStruct
    const selectedDateNumber = useRecoilValue(selectedDateNumberSelector)
    const booking = useRecoilValue(leadBookingSelector) as BookingStruct
    const allBookings = useRecoilValue(bookingsAtom)

    const [optInDates, setOptInDates] = useState(booking?.optInDates ?? [])
    const [optOutDates, setOptOutDates] = useState(booking?.optOutDates ?? [])
    const [showMassActionConfirmation, setShowMassActionConfirmation] = useState(false)

    const cleaningScheduleSections = useRecoilValue(cleaningScheduleSectionsSelector({ optInDates, optOutDates }))

    const setOptAction = currentOrganization.allowOptIn ? setOptInDates : setOptOutDates

    useEffect(() => {
        if (booking) {
            setOptInDates(booking?.optInDates ?? [])
            setOptOutDates(booking?.optOutDates ?? [])
        }
    }, [booking?.optInDates, booking?.optOutDates])

    const SHOW_CLEANING_SCHEDULE =
        (currentOrganization.allowOptIn || currentOrganization.allowOptOut) && area.synced && cleaningScheduleSections.length > 0

    function onDateCircleClick(date: number) {
        const dates = currentOrganization.allowOptIn ? [...optInDates] : [...optOutDates]
        const index = dates.indexOf(date.toString())

        const DATE_ALREADY_INCLUDED = index > -1
        const before = [...optInDates]
        const updatedDates = DATE_ALREADY_INCLUDED
            ? dates.filter(existingDate => existingDate !== date.toString())
            : [...dates, date.toString()]

        setOptAction(updatedDates)

        try {
            updateCleaningSchedule(booking.key, area.key, before, updatedDates, currentOrganization, currentUser)
        } catch (error) {
            setOptAction(before)
            toast.error(`Error updating cleaning schedule: ${error instanceof Error ? error.message : ''}`, toastErrorStyle)
        }
    }

    function onSelectAllClick() {
        const groupBookings = allBookings.filter(x => x._external?.groupID === booking._external?.groupID)
        if (booking._external && booking._external.groupID && groupBookings.length > 1) {
            setShowMassActionConfirmation(true)
            return
        }
        onConfirmToggleAll()
    }

    function onConfirmToggleAll() {
        const groupBookings = allBookings.filter(
            x =>
                (booking._external && booking._external.groupID && booking._external.groupID === x._external?.groupID) ||
                x.key === booking.key
        )
        setShowMassActionConfirmation(false)
        toggleAll(groupBookings)
    }

    async function toggleAll(bookings: BookingStruct[]) {
        const stayOverDays = [...booking.bookingDates].length - 2
        const selectedDates = currentOrganization.allowOptIn ? optInDates : optOutDates
        const optField = currentOrganization.allowOptIn ? 'optInDates' : 'optOutDates'
        const TOGGLE_FLAG = selectedDates.length < stayOverDays
        const filterBookingDates = (bookingDates: string[]) => bookingDates.filter(date => parseInt(date) >= selectedDateNumber)

        setOptAction(TOGGLE_FLAG ? filterBookingDates(booking.bookingDates) : [])

        try {
            await Promise.all(
                bookings.map(async booking => {
                    const before = TOGGLE_FLAG ? booking['optOutDates'] ?? [] : booking[optField] ?? []
                    const after = TOGGLE_FLAG ? filterBookingDates(bookings[0].bookingDates) : []

                    return await updateCleaningSchedule(booking.key, area.key, before, after, currentOrganization, currentUser)
                })
            )
        } catch (error: unknown) {
            toast.error(`Error updating cleaning schedule: ${error instanceof Error ? error.message : ''}`, toastErrorStyle)
        }
    }

    function onCancelToggleAll() {
        toggleAll([booking])
        setShowMassActionConfirmation(false)
    }

    if (!SHOW_CLEANING_SCHEDULE) return null
    return (
        <>
            {showMassActionConfirmation && (
                <AlertPortal
                    title={'Group reservation action'}
                    text={
                        'This booking is a part of a larger group reservation, do you want to apply this action to all reservations in the group?'
                    }
                    confirmBtnText={'Yes'}
                    cancelBtnText={'No, only this booking'}
                    onConfirm={onConfirmToggleAll}
                    onCancel={onCancelToggleAll}
                />
            )}
            <Wrap>
                <div>
                    <SectionTitleWrap fontSize="1.75rem">
                        {/* TODO - find SVG for this icon */}
                        <i className="specta-rules font-size-h3 line-height-xl text-dark mr-2" />
                        <h2>Housekeeping plan</h2>
                    </SectionTitleWrap>

                    <Description>{'Press a day to either clean or skip cleaning.'}</Description>

                    <PlanningInfoWrap>
                        <PlanningInfo />
                    </PlanningInfoWrap>

                    {cleaningScheduleSections.map(section => (
                        <MonthSection
                            key={section.month}
                            section={section}
                            selectedDateNumber={selectedDateNumber}
                            onDateCircleClick={onDateCircleClick}
                            onSelectAllClick={onSelectAllClick}
                        />
                    ))}
                </div>
            </Wrap>
        </>
    )
}

export default CleaningScheduleSection

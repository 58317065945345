import React, { useContext, useMemo, useState } from 'react'
import { AssignedUsersInitialsWrap, BottomIconsWrap, TopIconsWrap, Wrapper } from './style'
import { gray } from '@shared/colors'
import {
    pickCleaningStatusColor,
    pickCleaningActionIcon,
    pickCleaningStatusIcon,
    pickPeopleIcon,
    pickPauseIcon,
    pickInspectionIcon,
    pickAreaNoteIcon,
    pickCheckinIcon
} from '@shared/cleaning-helpers' // TODO: convert to ts
import { AreaStruct, TaskStruct } from '@shared/firestore-structs'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
    currentDateNumberAtom,
    selectedAreasKeysAtom,
    showAssignActionBarAtom,
    subscriptionParamsAtom,
    usersAtom
} from 'app/modules/HousekeepingDashboard/state/atoms'
import { areaPrioritySelector, calculatedAreaSelector } from 'app/modules/HousekeepingDashboard/state/selectors/calculatedArea'
import {
    activitiesSelector,
    bookingsSelector,
    cleaningTaskSelector,
    rulesSelector
} from 'app/modules/HousekeepingDashboard/state/selectors/areaRelatedData'
import { dailyCommentSelector } from 'app/modules/HousekeepingDashboard/state/selectors/commentsAndNotes'
import dailyCommentImg from '@shared/img/cleaning-status/daily-comment-white.svg'
import PrioritySign from '../../../../../components/PrioritySign'
import * as c from '@shared/constants'
import HousekeepingModal from 'app/modules/HousekeepingDashboard/HousekeepingModal'
import HousekeepingIcon from 'app/modules/HousekeepingDashboard/HousekeepingIcon'
import { SubscriptionContext } from 'app/modules/Subscriptions/SubscriptionContext'

interface Props {
    area: AreaStruct
    index: number
}

interface AssignedUsersInitialsProps {
    assignedTo: TaskStruct['assignedTo']
    boxColor: string
    transparent: boolean
}

function AssignedUsersInitials({ assignedTo, boxColor, transparent }: AssignedUsersInitialsProps) {
    if (!assignedTo || assignedTo.length === 0) return null
    const plus = assignedTo.length > 1 ? <span className="plus">{`+${assignedTo.length - 1}`}</span> : null

    return (
        <AssignedUsersInitialsWrap boxColor={boxColor} transparent={transparent}>
            <span>{assignedTo[0].initials}</span>
            {plus}
        </AssignedUsersInitialsWrap>
    )
}

function AreaBox({ area, index }: Props) {
    const { checkSubscription } = useContext(SubscriptionContext)
    const calculatedArea = useRecoilValue(calculatedAreaSelector(area))
    const {
        area: { key, name, dormant, synced, cleaningStatus, occupancy, cleaningFrequency, guestCheckedIn, note }
    } = calculatedArea
    const dailyComment = useRecoilValue(dailyCommentSelector(area))
    const rules = useRecoilValue(rulesSelector(key))
    const cleaningTask = useRecoilValue(cleaningTaskSelector(key))
    const priority = useRecoilValue(areaPrioritySelector(key))
    const showAssignActionBar = useRecoilValue(showAssignActionBarAtom)
    const currentDateNumber = useRecoilValue(currentDateNumberAtom)
    const bookings = useRecoilValue(bookingsSelector(key))
    const activities = useRecoilValue(activitiesSelector(key))
    const users = useRecoilValue(usersAtom)

    const [selectedAreasKeys, setSelectedAreasKeys] = useRecoilState(selectedAreasKeysAtom)

    const setSubscriptionParams = useSetRecoilState(subscriptionParamsAtom)

    const [housekeepingModalIsOpen, setHousekeepingModalIsOpen] = useState(false)

    const {
        cleaningActionIcon,
        cleaningStatusIcon,
        checkInIcon,
        guestsIcon,
        pauseIcon,
        inspectionIcon,
        areaNoteIcon,
        dailyCommentIcon
    } = useMemo(() => {
        const cleaningActionIcon = pickCleaningActionIcon(cleaningStatus, occupancy, cleaningFrequency, rules, false)
        const cleaningStatusIcon = pickCleaningStatusIcon(cleaningStatus, false)
        const checkInIcon = pickCheckinIcon(cleaningStatus, occupancy, false)
        const guestsIcon = pickPeopleIcon(cleaningStatus, occupancy, guestCheckedIn!, false)
        const pauseIcon = pickPauseIcon(cleaningStatus, false)
        const inspectionIcon = pickInspectionIcon(cleaningStatus, false)
        const areaNoteIcon = note && note.length > 0 ? ((pickAreaNoteIcon(null, null) as unknown) as string) : null
        const dailyCommentIcon = dailyComment && dailyComment.comment.length > 0 ? (dailyCommentImg as string) : null

        return {
            cleaningActionIcon,
            cleaningStatusIcon,
            checkInIcon,
            guestsIcon,
            pauseIcon,
            inspectionIcon,
            areaNoteIcon,
            dailyCommentIcon
        }
    }, [cleaningStatus, occupancy, cleaningFrequency, rules, guestCheckedIn, note, dailyComment, dailyCommentImg])

    const boxColor: string = synced && !dormant ? pickCleaningStatusColor(cleaningStatus, occupancy) : gray
    const selected = showAssignActionBar && selectedAreasKeys.has(key)

    function onClick() {
        if (showAssignActionBar) {
            setSelectedAreasKeys(prev => {
                const newSet = new Set(prev)
                newSet.has(key) ? newSet.delete(key) : newSet.add(key)
                return newSet
            })
            return
        }

        if (!synced || dormant) {
            checkSubscription(
                'unlimited-areas',
                () => {
                    setSubscriptionParams(prev => ({ ...prev, hasSubscriptionAccess: true }))
                },
                (notAccessibleFeature, availableUpgrades) =>
                    setSubscriptionParams({
                        hasSubscriptionAccess: false,
                        notAccessibleFeature,
                        availableUpgrades,
                        upgradeMessageIsOpen: true
                    })
            )
            return
        }

        setHousekeepingModalIsOpen(true)
    }

    return (
        <>
            {housekeepingModalIsOpen && (
                <HousekeepingModal
                    {...calculatedArea}
                    currentDateNumber={currentDateNumber}
                    activities={activities}
                    task={cleaningTask}
                    rules={rules}
                    dailyComment={dailyComment}
                    onClose={() => setHousekeepingModalIsOpen(false)}
                    users={users}
                    priority={priority}
                    bookings={bookings}
                />
            )}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Wrapper selected={selected} onClick={onClick} index={index} boxColor={boxColor}>
                <label>{name}</label>
                {priority && <PrioritySign color={'white'} size={28} />}
                <AssignedUsersInitials
                    assignedTo={cleaningTask?.assignedTo}
                    boxColor={boxColor}
                    transparent={cleaningStatus === c.CLEANING_STATUS_CLEAN}
                />

                <TopIconsWrap>
                    <HousekeepingIcon icon={{ source: dailyCommentIcon }} />
                    <HousekeepingIcon icon={cleaningActionIcon} />
                    <HousekeepingIcon icon={cleaningStatusIcon} />
                </TopIconsWrap>

                <BottomIconsWrap>
                    <HousekeepingIcon icon={pauseIcon} />
                    <HousekeepingIcon icon={checkInIcon} />
                    <HousekeepingIcon icon={{ source: areaNoteIcon }} />
                    <HousekeepingIcon icon={guestsIcon} />
                    <HousekeepingIcon icon={inspectionIcon} />
                </BottomIconsWrap>
            </Wrapper>
        </>
    )
}

export default AreaBox

import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const Content = styled.div`
    flex: 1;
    overflow-y: hidden;
    width: 100%;
    padding-left: 20px;
`

import React from 'react'
import { findNodeHandle, Image, TouchableOpacity, View } from 'react-native-web'
import Hoverable from '../utils/hoverable/hoverable'
import * as colors from '@shared/colors'
import * as c from '@shared/constants'
import { iOSColors } from '@shared/react-native-typography'
import * as chelpers from '@shared/cleaning-helpers'
import { OccupancyFilterButton } from './occupancy-filter-button'
import { fadeInDown } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import { occupancyToHuman } from '@shared/get-booking-display'

const animationStyles = {
    fadeIn: {
        animation: 'x 0.25s',
        animationName: Radium.keyframes(fadeInDown, 'fadeInDown')
    }
}

export default class OccupancyFilterPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = { open: false }

        this.handleClick = this.handleClick.bind(this)
        this.handleOutsideClick = this.handleOutsideClick.bind(this)
        this.renderOptions = this.renderOptions.bind(this)
    }

    handleClick() {
        if (!this.state.open) {
            document.addEventListener('click', this.handleOutsideClick, false)
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false)
        }

        this.setState(prevState => ({
            open: !prevState.open
        }))
    }

    handleOutsideClick(e) {
        // ignore clicks on the component itself
        const handle = findNodeHandle(this.node)
        if (handle && handle.contains && handle.contains(e.target)) {
            return
        }

        this.handleClick()
    }

    render() {
        return (
            <View
                style={{ alignItems: 'center', zIndex: 30 }}
                ref={node => {
                    this.node = node
                }}>
                <View>
                    <TouchableOpacity onPress={this.handleClick}>
                        {this.props.options.length > 0 && <OccupancyFilterButton occupancy={this.props.occupancy} />}
                    </TouchableOpacity>
                </View>
                {this.state.open && (
                    <View
                        style={{
                            minWidth: 220,
                            position: 'absolute',
                            top: '100%',
                            right: '-160%',
                            marginTop: 6,
                            elevation: 3,
                            backgroundColor: 'transparent',
                            overflowY: 'auto'
                        }}>
                        {this.renderOptions()}
                    </View>
                )}
            </View>
        )
    }

    renderOptions() {
        return (
            <StyleRoot>
                <div style={animationStyles.fadeIn}>
                    <View
                        style={{
                            borderWidth: 1,
                            borderColor: colors.gentle_gray_spectaflow,
                            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)',
                            borderRadius: 8,
                            backgroundColor: 'white',
                            paddingVertical: 6
                        }}>
                        {this.props.options.map((option, index) => (
                            <OptionItem
                                key={option + ' ' + index}
                                options={this.props.options}
                                option={option}
                                index={index}
                                toggleMenu={this.handleClick}
                                setOccupancyFilter={this.props.setOccupancyFilter}
                            />
                        ))}
                    </View>
                </div>
            </StyleRoot>
        )
    }
}

// eslint-disable-next-line react/no-multi-comp
class OptionItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            backgroundColor: 'white',

            fontColor: colors.webNavbarInactive
        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(action) {
        this.props.toggleMenu()
        this.props.setOccupancyFilter(action)
    }

    render() {
        const BUTTON_DIM = 26
        const isResetOption = this.props.option.occupancy === 'reset'

        let font_color = iOSColors.black

        let action = this.props.option.occupancy

        if (isResetOption) {
            font_color = iOSColors.red

            action = c.CLEANING_STATUS_ALL
        }

        return (
            <Hoverable
                onHoverIn={() => {
                    this.setState({ hover: true, backgroundColor: colors.gentle_gray_spectaflow, fontColor: iOSColors.black })
                }}
                onHoverOut={() => {
                    this.setState({ hover: false, backgroundColor: iOSColors.white, fontColor: colors.webNavbarInactive })
                }}>
                <View
                    style={{
                        backgroundColor: this.state.backgroundColor
                    }}
                    key={this.props.index}>
                    <TouchableOpacity
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                            padding: 10,
                            alignItems: 'center',
                            borderWidth: 0,
                            justifyContent: isResetOption ? 'center' : 'flex-start'
                        }}
                        onPress={() => this.handleClick(action)}>
                        {!isResetOption && (
                            <Image
                                source={chelpers.pickOccupancyButtonImage(this.props.option.occupancy)}
                                style={{ width: BUTTON_DIM, height: BUTTON_DIM }}
                            />
                        )}
                        <span
                            style={{
                                marginLeft: 8,
                                fontSize: 14,
                                fontWeight: isResetOption ? '500' : 400,
                                opacity: 1,
                                color: font_color,
                                borderWidth: 0
                            }}>
                            {isResetOption ? 'Reset' : occupancyToHuman(this.props.option.occupancy)}
                        </span>
                    </TouchableOpacity>
                </View>
            </Hoverable>
        )
    }
}

import { AuthPhoneNumberStruct, OrgStruct, UserRole, UserStruct } from './firestore-structs'
import { getUserObject } from './dataObjects'
import * as helpers from './helpers'
import { DocumentData, Firebase, FirebaseFirestore, Query, User as FirebaseUser, WriteBatch } from './firebase'
import { userHasAccessToAreaGroup } from './area-groups-helpers'

type User = Partial<
    UserStruct & {
        issueCounter: number
        useOrginalImage: boolean
        authRole: string
        organizations: OrgStruct[]
    }
>

export interface UserOption {
    value: string
    label: string
    initials: string
    data: UserStruct | Pick<UserStruct, 'initials' | 'key' | 'name'>
}

let currentUser: User | null = null

export function setCurrentUserGlobally(user: User) {
    currentUser = getUserObject(
        user.key as string,
        user.phoneNumber as string,
        user.name as string,
        user.initials as string,
        user.email as string,
        user.organizationKey as string,
        user.areaGroups as string[],
        user.issueHashtags as string[],
        user.assignIssueHashtags as boolean,
        user.showCleaningTaskNote === undefined ? true : !!user.showCleaningTaskNote,
        user.visible as boolean,
        user.authRole as UserRole
    )
    currentUser.issueCounter = user.issueCounter
    currentUser.useOrginalImage = user.useOrginalImage ? user.useOrginalImage : false
    currentUser.authRole = user.authRole
    currentUser.organizations = user.organizations
}

export function getCurrentUser() {
    return currentUser
}

export function getUsersQuery(firebase: Firebase | FirebaseFirestore, organizationKey: string): Query<DocumentData> {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return db.collection('users').where('organizationKey', '==', organizationKey).where('visible', '==', true)
}

export function constructUsersOptions(
    users: UserStruct[] | Pick<UserStruct, 'initials' | 'key' | 'name'>[],
    areaGroups?: string[]
): UserOption[] {
    const filteredUsers =
        areaGroups && (users[0] as UserStruct)?.areaGroups
            ? (users as UserStruct[]).filter(u => areaGroups.every(group => userHasAccessToAreaGroup(u.areaGroups, group)))
            : users

    return filteredUsers
        .map(u => {
            return {
                value: u.key,
                label: u.name,
                initials: u.initials,
                data: u
            }
        })
        .sort((a, b) => helpers.sortByName(a.label, b.label))
}

export function findSelectedUsersOptions(options: UserOption[], selectedUsersKeys: Set<string>) {
    return options.filter(o => selectedUsersKeys.has(o.value))
}

export function increaseIssueCounter(firebase: Firebase, currentUser: UserStruct, batch?: WriteBatch) {
    const userRef = firebase.firestore().collection('users').doc(currentUser.key)
    const issueCounter = currentUser.issueCounter ? currentUser.issueCounter + 1 : 1

    try {
        batch ? batch.update(userRef, { issueCounter }) : userRef.set({ issueCounter }, { merge: true })
    } catch (error: unknown) {
        console.error('(user-data increaseIssueCounter) ', error)
        throw new Error('Error while increasing user issue counter in ')
    }

    return issueCounter
}

export async function getUserToken(firebase: Firebase, tokenHash: string) {
    const db = firebase.firestore()
    const userTokenDoc = await db.collection('userTokens').doc(tokenHash).get()

    if (!userTokenDoc.exists) {
        throw new Error(`User with key ${tokenHash} not found`)
    }

    const userTokenSnap = userTokenDoc.data() as { token: string; userKey: string }

    return userTokenSnap.token
}

export async function deleteUser(firebase: Firebase, userKey: string) {
    const userRef = await firebase.firestore().collection('users').doc(userKey).get()
    const user = userRef.data() as UserStruct
    const manageUser = firebase.functions().httpsCallable('manageUser')

    await manageUser({
        userKey: user.key,
        userName: user.name,
        userEmail: user.email,
        userPhoneNumber: user.phoneNumber,
        userNewPhoneNumber: user.phoneNumber,
        userInitials: user.initials,
        userAreaGroups: user.areaGroups,
        userOrganizationKey: user.organizationKey,
        userVisible: false,
        userShowCleaningTaskNote: user.showCleaningTaskNote || false,
        showCleaningTaskNote: user.showCleaningTaskNote,
        currentUserKey: user.key
    }).catch((e: Error) => {
        console.log(e)
    })
}

export async function createUser(firebase: Firebase, data: any) {
    const manageUser = firebase.functions().httpsCallable('manageUser')
    const {
        key,
        userName,
        currentUserKey,
        userEmail,
        userNewPhoneNumber,
        userInitials,
        userAreaGroups,
        userOrganizationKey,
        userIssueHashtags,
        userShowCleaningTaskNote,
        userRole,
        userAssignIssueHashtags
    } = data

    const userId = await manageUser({
        userKey: key || null,
        userName,
        userEmail,
        userPhoneNumber: userNewPhoneNumber,
        userNewPhoneNumber,
        userInitials,
        userAreaGroups,
        userOrganizationKey,
        userVisible: true,
        userIssueHashtags,
        userAssignIssueHashtags: userAssignIssueHashtags || false,
        userRole: userRole || 'user',
        userShowCleaningTaskNote,
        showCleaningTaskNote: userShowCleaningTaskNote,
        currentUserKey
    }).catch((e: Error) => {
        throw new Error(e.message)
    })
    return userId.data
}

export function getCurrentActiveOrgForPhoneNumberQuery(db: FirebaseFirestore, authUser: Pick<FirebaseUser, 'phoneNumber'>) {
    return db.collection<AuthPhoneNumberStruct>('authPhoneNumbers').doc(authUser.phoneNumber!)
}

export async function getActiveUserByPhoneNumber(db: FirebaseFirestore, authUser: Pick<FirebaseUser, 'phoneNumber'>) {
    const activeUser = (await getCurrentActiveOrgForPhoneNumberQuery(db, authUser).get()).data()
    if (!activeUser) {
        throw new Error(`User with phone number ${authUser.phoneNumber} not found`)
    }
    const result = (await db.collection<UserStruct>('users').doc(activeUser.userKey).get()).data()
    if (!result) {
        throw new Error(`User with key ${activeUser.userKey} not found while looking up user by phone number ${authUser.phoneNumber}`)
    }
    return { ...result, showOnboarding: false, newVersionDisplay: false }
}
export const getUser = async (db: FirebaseFirestore, userKey: string) => {
    console.log(`Getting user with key: ${userKey}`)

    const userDoc = await db.collection<UserStruct>('users').doc(userKey).get()
    if (userDoc.exists) {
        const user = userDoc.data()!

        return {
            ...user,
            showOnboarding: false,
            newVersionDisplay: false
        }
    }
    throw new Error(`User with key ${userKey} not found`)
}

import { SignupWorkflow } from '../../utils/signup-flow-template/signup-workflow'
import { inputs } from '../../utils/signup-flow-template/inputs.builder'
import { HOUSEKEEPING, SIGN_UP } from '../../navigation/url-constants'

export const BEDS_24_WORKFLOW = new SignupWorkflow()
    .persistFeature('new-initial-import-procedure', true)
    .step({ url: '/signup/beds24/callback', inputs: inputs().input('propownerId').input('bus').build() })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({ url: '/signup/beds24/config' })
    .step({ url: '/signup/welcome' })

export const BEDS_24_V2_WORKFLOW = new SignupWorkflow()
    .step({ url: '/signup/beds24/start', inputs: inputs().input('bus').input('imageUrl').build() })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({ url: '/signup/beds24/authorize', inputs: inputs().input('apiKey').build() })
    .step({ url: '/signup/beds24/callback' })
    .step({ url: '/signup/welcome' })

export const APALEO_WORKFLOW = new SignupWorkflow()
    .persistFeature('new-initial-import-procedure', true)
    .step({ url: '/signup/apaleo/start', inputs: inputs().input('bus').input('imageUrl').build() })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({ url: '/signup/apaleo/authorize' })
    .step({ url: '/signup/apaleo/callback' })
    .step({ url: '/signup/welcome' })

export const APALEO_RECONNECT_WORKFLOW = new SignupWorkflow()
    .persistFeature('new-initial-import-procedure', true)
    .step({
        url: SIGN_UP.APALEO_RECONNECT,
        inputs: inputs().input('reconnect').input('bus').input('imageUrl').build()
    })
    .step({ url: SIGN_UP.APALEO_AUTHORIZE })
    .step({ url: SIGN_UP.APALEO_CALLBACK })
    .step({ url: HOUSEKEEPING.INDEX })

export const RENTLIO_WORKFLOW = new SignupWorkflow()
    .step({ url: '/signup/rentlio/start', inputs: inputs().input('bus').input('imageUrl').build() })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({ url: '/signup/rentlio/authorize', inputs: inputs().input('apiKey').build() })
    .step({ url: '/signup/rentlio/callback' })
    .step({ url: '/signup/welcome' })

export const GUESTLINE_WORKFLOW = new SignupWorkflow()
    .step({ url: '/signup/guestline/start', inputs: inputs().input('bus').input('imageUrl').build() })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({ url: '/signup/guestline/authorize', inputs: inputs().input('siteId').input('operatorCode').input('password').build() })
    .step({ url: '/signup/guestline/callback' })
    .step({ url: '/signup/welcome' })

export const BOOKING_FACTORY_WORKFLOW = new SignupWorkflow()
    .step({
        url: '/signup/bookingfactory/start',
        inputs: inputs().input('key1').input('key2').input('account').input('bus').input('imageUrl').build()
    })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({ url: '/signup/bookingfactory/setup' })
    .step({ url: '/signup/welcome' })

export const NON_PMS_WORKFLOW = new SignupWorkflow()
    .step({ url: '/signup/start' })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({ url: '/signup/callback' })
    .step({ url: '/' })

export const HOST_WORKFLOW = new SignupWorkflow()
    .step({ url: '/signup/host/start', inputs: inputs().input('bus').input('imageUrl').build() })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({ url: '/signup/host/authorize', inputs: inputs().input('apiKey').build() })
    .step({ url: '/signup/host/callback' })
    .step({ url: '/signup/welcome' })

export const MEWS_WORKFLOW = new SignupWorkflow()
    .step({
        url: '/signup/mews/start',
        inputs: inputs().input('enterpriseId').input('bus').input('imageUrl').build()
    })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({ url: '/signup/mews/setup' })
    .step({ url: '/signup/welcome' })

export const CLOUDBEDS_WORKFLOW = new SignupWorkflow()
    .step({ url: '/signup/cloudbeds/start', inputs: inputs().input('bus').input('imageUrl').build() })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({ url: '/signup/cloudbeds/authorize' })
    .step({ url: '/signup/cloudbeds/callback' })
    .step({ url: '/signup/welcome' })

export const GUESTY_WORKFLOW = new SignupWorkflow()
    .persistFeature('new-initial-import-procedure', true)
    .step({ url: '/signup/guesty/start', inputs: inputs().input('bus').input('imageUrl').build() })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({ url: '/signup/guesty/authorize', inputs: inputs().input('apiKey').build() })
    .step({ url: '/signup/guesty/setup' })
    .step({ url: '/signup/welcome' })

export const ORACLE_WORKFLOW = new SignupWorkflow()
    .step({ url: '/signup/oracle/start', inputs: inputs().input('bus').input('imageUrl').build() })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({
        url: '/signup/oracle/authorize',
        inputs: inputs()
            .input('clientId')
            .input('clientSecret')
            .input('password')
            .input('username')
            .input('hotelId')
            .input('appKey')
            .build()
    })
    .step({ url: '/signup/oracle/callback' })
    .step({ url: '/signup/welcome' })

export const BLUE_LAGOON_WORKFLOW = new SignupWorkflow()
    .step({ url: '/signup/bluelagoon/start', inputs: inputs().input('bus').input('imageUrl').build() })
    .step({ url: '/signup/org', inputs: inputs().input('orgKey').input('userKey').build() })
    .step({ url: '/signup/bluelagoon/authorize', inputs: inputs().input('apiKey').build() })
    .step({ url: '/signup/bluelagoon/callback' })
    .step({ url: '/signup/welcome' })

export const currentIntegrationKey = 'integration'
